export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://lch.global";
export const DEFAULT_DATE_TIME_FORMAT = "DD MMM YY - HH:mm";
export const DEFAULT_DATE_FORMAT = "DD MMM YY";

export const TRANSPORT_OPTIONS = [
  { key: 1, value: "SHIP", label: "Container" },
  { key: 1, value: "PLANE", label: "Air Cargo" },
];

export const ORDER_STATUS_LIST = [
  { key: 1, value: "DRAFT", label: "Draft" },
  {
    key: 2,
    value: "READY_FOR_CLIENT_REVIEW",
    label: "Ready for client review",
  },
  { key: 3, value: "APPROVED", label: "Item List Approved" },
  { key: 4, value: "MANUFACTURING", label: "Manufacturing" },
  { key: 5, value: "AWAITING_TRANSIT", label: "Awaiting transit" },
  { key: 6, value: "PARTIALLY_IN_TRANSIT", label: "Partially in transit" },
  { key: 7, value: "IN_TRANSIT", label: "All items in transit" },
  { key: 8, value: "PARTIALLY_DELIVERED", label: "Partially delivered" },
  { key: 9, value: "DELIVERED", label: "Fully Delivered" },
  { key: 10, value: "ARCHIVE", label: "Archived" },
];

export const getOrderStatusByValue = (val) =>
  ORDER_STATUS_LIST.find((option) => option.value === val);
