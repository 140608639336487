import React, { useContext } from "react";

import styles from "./Overlay.module.scss";
import { cl } from "../../../helpers";
import { OverlayContext } from "../../../context";

function Overlay() {
  const context = useContext(OverlayContext);

  if (!context.show) {
    return null;
  }

  return (
    <div className={cl(styles.container)}>
      <div className={cl(styles.content)}>{context.content}</div>
    </div>
  );
}

export default Overlay;
