import React, { useState, useContext, useEffect, useMemo } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuid } from "uuid";

import { Table } from "../../PageElements";
import {
  createContainerAccessory,
  deleteContainerAccessory,
  updateContainerAccessory,
} from "../../../graphql/mutations";
import { NotificationContext } from "../../../context";
import { listContainerAccessorys } from "../../../graphql/queries";

function EditableAccessoriesTable(props) {
  const notification = useContext(NotificationContext);

  const order = props.order;
  const readOnly = props.readOnly;
  const container = props.container;

  const defaultItem = useMemo(
    () => ({
      number: "",
      description: "",
      unit_name: "0",
      quantity: 0,
      unit_price: 0,
    }),
    []
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    { key: "number", label: "Number", type: "text" },
    { key: "description", label: "Description", type: "text" },
    { key: "quantity", label: "QTY", type: "number" },
    {
      key: "unit_name",
      label: "UNIT",
      type: "select",
      options: [
        { key: "0", label: "Select Type" },
        { key: "PAIRS", label: "Pairs" },
        { key: "SETS", label: "Sets" },
        { key: "PCS", label: "Pcs" },
      ],
    },
    { key: "unit_price", label: "Price", type: "price" },
    {
      key: "amount",
      label: "Amount",
      type: "price",
      formula: (_, row) => row.quantity * row.unit_price,
      readOnly: true,
    },
  ];

  const actions = [
    {
      type: "callback",
      callback: async (row) => {
        const action = row.createdAt
          ? updateContainerAccessory
          : createContainerAccessory;

        const { data } = await API.graphql(
          graphqlOperation(action, {
            input: {
              id: row.id,
              company_id: order.company.id,
              container_id: container.id,
              number: row.number,
              description: row.description,
              quantity: row.quantity,
              unit_name: row.unit_name,
              unit_price: row.unit_price,
            },
          })
        );
        const key = row.createdAt
          ? "updateContainerAccessory"
          : "createContainerAccessory";

        const response = data[key];
        setData((oldData) => {
          const newData = [...oldData];
          const idx = newData.findIndex((row) => row.id === response.id);
          if (idx > -1) {
            newData[idx] = response;
          } else {
            newData.push(response);
          }

          return newData;
        });

        notification.quickConfirm(
          `Accessory ${row.createdAt ? "Saved" : "Created"}!`
        );
      },
      renderer: ({ createdAt }) => {
        return createdAt ? "Save" : "Create";
      },
    },
    {
      type: "separator",
    },
    {
      type: "callback",
      callbackType: "delete",
      callback: async ({ createdAt, id }) => {
        setData((oldData) => {
          const newData = [...oldData];
          const rowToDelete = newData.findIndex((row) => row.id === id);
          if (rowToDelete > -1) {
            newData.splice(rowToDelete, 1);
          }

          return newData;
        });

        if (createdAt) {
          await API.graphql(
            graphqlOperation(deleteContainerAccessory, { input: { id } })
          );
        }

        return { success: true };
      },
      renderer: ({ createdAt }) => {
        return createdAt ? "Delete" : "Remove";
      },
    },
  ];

  const onDataChange = (id, field, value) => {
    setData((oldData) => {
      const newData = [...oldData];
      const rowToEdit = newData.find((row) => row.id === id);
      if (rowToEdit) {
        rowToEdit.hasAmendments = true;
        rowToEdit[field] = value;
      }

      return newData;
    });
  };

  const onAddHandler = () => {
    setData((oldData) => {
      const newData = [...oldData];
      newData.push({ id: uuid(), ...defaultItem });

      return newData;
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: {
          listContainerAccessorys: { items },
        },
      } = await API.graphql(
        graphqlOperation(listContainerAccessorys, {
          filter: {
            company_id: { eq: order.company.id },
            container_id: { eq: container.id },
          },
        })
      );
      setData(
        items.length ? items : readOnly ? [] : [{ id: uuid(), ...defaultItem }]
      );
      setLoading(false);
    })();
  }, [order, container, readOnly, defaultItem]);

  return (
    <Table
      loading={loading}
      editable={readOnly ? false : true}
      columns={columns}
      data={data}
      actions={readOnly ? [] : actions}
      mainColumn="number"
      onDataChange={onDataChange}
      onAdd={{ label: "Add Accessory", handler: onAddHandler }}
    />
  );
}

export default EditableAccessoriesTable;
