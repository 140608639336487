import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "../../../PageElements";
import {
  EditableOrderContainersTable,
  EditableOrderItemsTable,
} from "../../Tables";
import styles from "./OrderEditor.module.scss";

function OrderEditor(props) {
  const statusKey = props.status.key;

  return (
    <Tabs>
      <TabList>
        {statusKey > 3 && <Tab>Containers</Tab>}
        <Tab>Items</Tab>
      </TabList>
      {statusKey > 3 && (
        <TabPanel>
          <div className={styles.editTableContainer}>
            <EditableOrderContainersTable order={props.order} />
          </div>
        </TabPanel>
      )}
      <TabPanel>
        <div className={styles.editTableContainer}>
          <EditableOrderItemsTable
            order={props.order}
            items={props.order.items.items}
          />
        </div>
      </TabPanel>
    </Tabs>
  );
}

export default OrderEditor;
