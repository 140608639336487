import React from "react";
import PropTypes from "prop-types";

import { Label } from "../../../PageElements";
import formStyles from "../../FormElement.module.scss";
import { cl } from "../../../../helpers";

Upload.propTypes = {
  nested: PropTypes.bool.isRequired,
};

Upload.defaultProps = {
  nested: true,
};

function Upload(props) {
  const required = props.required ? "*" : "";
  const type = props.type;
  const filled =
    props.value !== null && props.value !== undefined && props.value !== ""
      ? true
      : false;
  const instruction = props.instruction ?? props.label;
  const hasErrors = props.errors && props.errors.length;
  const hasErrorClass =
    props.errors && props.errors.length ? formStyles.error : "";

  let labelValue = `${props.label}${required}`;

  if (!filled) {
    labelValue = `${required} ${instruction}`;
  }

  const errors = hasErrors
    ? props.errors.map((error, index) => {
        if (!error.error) {
          return null;
        }

        return (
          <span className={formStyles.error} key={`${error.field}_${index}`}>
            {error.error}
          </span>
        );
      })
    : null;

  return (
    <div
      className={cl(
        formStyles.wrapper,
        props.nested ? formStyles.nested : null,
        props.wrapperClassName
      )}
    >
      <div
        className={cl(
          formStyles.container,
          formStyles[type],
          props.branded ? formStyles.branded : null,
          props.disabled ? formStyles.disabled : null,
          props.readonly ? formStyles.readonly : null,
          props.containerClassName,
          hasErrorClass
        )}
      >
        <Label
          value={labelValue}
          containerClassName={cl(
            formStyles.labelContainer,
            filled ? formStyles.filled : null,
            formStyles.focused
          )}
        />
        <input
          type="file"
          multiple={true}
          className={cl(formStyles.input, props.className)}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          readOnly={props.readonly}
          onChange={props.onChange}
          tabIndex={props.readonly ? -1 : 1}
          autoComplete={props.autoComplete}
        />
        <div className={formStyles.underline}></div>
      </div>
      <div className={formStyles.errorsContainer}>{errors}</div>
    </div>
  );
}

export default Upload;
