import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import { getContent } from "../graphql/queries";
import { Heading, Section, Loader } from "../components/PageElements";
import { AppContext } from "../context";

function Page() {
  const history = useHistory();
  const { hasSession } = useContext(AppContext);
  const { page } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const query = hasSession
          ? graphqlOperation(getContent, { slug: page })
          : {
              query: getContent,
              authMode: "AWS_IAM",
              variables: {
                slug: page,
              },
            };
        const {
          data: { getContent: data },
        } = await API.graphql(query);
        if (!data) {
          history.replace("/404");
        }
        setData(data);
        setLoading(false);
      } catch (e) {
        setData({});
        setLoading(false);
      }
    },
    [hasSession, history]
  );

  useEffect(() => {
    if (page) {
      fetchData(page);
    }

    return () => {
      setData({});
    };
  }, [page, fetchData]);

  if (loading) {
    return (
      <Section type="screen">
        <Section type="centerX">
          <Heading tag="h1" label="Please, wait a moment" />
          <Loader size="140" label="Loading page content..." />
        </Section>
      </Section>
    );
  }

  return (
    <Section type="screen">
      <Section type="centerX">
        <Heading tag="h1" label={data.title} />
        {data.body.split("\n").map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })}
      </Section>
    </Section>
  );
}

export default Page;
