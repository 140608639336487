import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";

import { AppContext } from "../../../context";
import { FormBuilder } from "../../FormElements";

function ChangePasswordForm(props) {
  const { userAttributes } = props.user.challengeParam;

  const app = useContext(AppContext);
  const [errors, setErrors] = useState([]);

  const newPasswordFields = {
    given_name: {
      value: userAttributes.given_name || "",
      component: "text",
      props: {
        label: "First Name",
        instruction: "Enter First Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
    family_name: {
      value: userAttributes.family_name || "",
      component: "text",
      props: {
        label: "Last Name",
        instruction: "Enter Last Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
    password: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "New Password",
        instruction: "Enter New Password",
        autofill: "new_password",
      },
      validation: {
        required: true,
        password: true,
      },
    },
    password_confirmation: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "Password Confirmation",
        instruction: "Enter New Password (Again)",
      },
      validation: {
        required: true,
        same: "password",
      },
    },
  };

  const completeNewPasswordHandler = async (data) => {
    try {
      props.beforePasswordReset();
      await Auth.completeNewPassword(props.user, data.password, {
        given_name: data.given_name,
        family_name: data.family_name,
      });
      await app.reinit();
      props.afterPasswordReset(data);
    } catch (e) {
      setErrors([e.message]);
    }
  };

  return (
    <FormBuilder
      fields={newPasswordFields}
      submit={{
        handler: completeNewPasswordHandler,
        label: "Change password",
      }}
      generalErrors={errors}
    />
  );
}

export default ChangePasswordForm;
