import React, { useState, useContext } from "react";

import { OverlayContext } from "../context";
import { SigninTransition } from "../components/UI/Transitions";
import { SigninForm } from "../components/UI/Forms";
import { Heading, Link } from "../components/PageElements";

function Signin() {
  const [heading, setHeading] = useState("Sign in");
  const overlay = useContext(OverlayContext);

  return (
    <div style={{ width: "100%" }}>
      <Heading label={heading} tag="h1" />
      <SigninForm
        onModeChange={(mode) => setHeading(mode)}
        beforeSignin={() => {
          overlay.transition(<SigninTransition duration={10000} />);
          overlay.setShow(true);
        }}
        afterSignin={() => overlay.hide()}
      />
      <br />
      <Link
        to="/password-reset"
        label="Forgot Password?"
        labelUppercase={false}
      />
    </div>
  );
}

export default Signin;
