import React, { useCallback, useContext, useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useHistory, useParams } from "react-router";
import moment from "moment";

import {
  getCorrespondence,
  getCorrespondenceMessages,
} from "../graphql/queries";
import { onCreateMessage, onDeleteMessage } from "../graphql/subscriptions";
import {
  Section,
  Heading,
  Loader,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  InlineSeparator,
  Label,
} from "../components/PageElements";
import { Button } from "../components/FormElements";
import { InquiryReplyForm } from "../components/UI/Forms";
import { MessageCard } from "../components/UI/Widgets";
import {
  ModalContext,
  NotificationContext,
  AppContext,
  OrganizationContext,
} from "../context";
import { createNotification } from "../graphql/mutations";

function Inquiry() {
  const { replace } = useHistory();
  const { inquiry } = useParams();
  const modal = useContext(ModalContext);
  const notification = useContext(NotificationContext);
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [messages, setMessages] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { getCorrespondence: correspondence },
    } = await API.graphql(graphqlOperation(getCorrespondence, { id: inquiry }));
    if (!correspondence) {
      return replace("/404");
    }
    setData(correspondence);
    const {
      data: {
        getCorrespondenceMessages: { items },
      },
    } = await API.graphql(
      graphqlOperation(getCorrespondenceMessages, {
        correspondence_id: correspondence.id,
        sortDirection: "DESC",
      })
    );

    const messages = await Promise.all(
      items.map(async (_item) => {
        const vars = JSON.parse(_item.vars);
        if (vars && vars.hasFiles) {
          const files = await Storage.list(
            `/inquiries/${_item.correspondence_id}/${_item.id}`
          );
          return { ..._item, files };
        } else {
          return { ..._item };
        }
      })
    );

    setMessages(messages);
    setLoading(false);
  }, [replace, inquiry]);

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, [fetchData]);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateMessage)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteMessage)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteMessage: deleted },
        },
      }) => {
        setMessages((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (message) => message.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  });

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Inquiry" />
        <Loader size="140" label="Loading inquiry details..." />
      </Section>
    );
  }

  return (
    <>
      <Section type="first">
        <Heading tag="h1" label={data.title} />
        <div style={{ lineHeight: 2, marginBottom: "0.5rem" }}>
          <Button
            label="Reply"
            labelIcon="fas fa-angle-right"
            rounded={true}
            type="secondaryBranded"
            labelSize="small"
            callback={() => {
              let saveTracker;
              modal.showForm(
                <InquiryReplyForm
                  correspondence={data}
                  onSave={() => {
                    saveTracker = notification.startProgress(
                      "One moment, please. Sending your replay..."
                    );
                    modal.hide();
                  }}
                  afterSave={() => {
                    notification.updateProgress(
                      saveTracker,
                      100,
                      `Your reply was successfully send.`
                    );
                    fetchData();
                    if (currentUser.is_system) {
                      // Admin reply to client
                      API.graphql(
                        graphqlOperation(createNotification, {
                          input: {
                            receiver: data.group_id,
                            title: `New Reply to Your Inquiry`,
                            content: `${currentUser.fullname} just replied to your inquiry.`,
                            icon: `fas fa-comment`,
                            type: "COMMS",
                            to: `/inquiries/${inquiry}`,
                          },
                        })
                      );
                    } else {
                      API.graphql(
                        graphqlOperation(createNotification, {
                          input: {
                            receiver: organization.managing_company_id,
                            title: `New Reply to Your Inquiry`,
                            content: `${currentUser.fullname} just sent a new message regarding their inquiry.`,
                            icon: `fas fa-comment`,
                            type: "COMMS",
                            to: `/inquiries/${inquiry}`,
                          },
                        })
                      );
                    }
                  }}
                />,
                `Reply to Inquiry from ${data.sender.name}`
              );
            }}
          />
          <InlineSeparator className="not-mobile" />
          <br className="not-tablet not-desktop" />
          <span>
            Opened by: <Label value={data.sender.name} />
          </span>
          <InlineSeparator className="not-mobile" />
          <br className="not-tablet not-desktop" />
          <span>
            Created:{" "}
            <Label value={moment(data.createdAt).fromNow()} uppercase={false} />
          </span>
        </div>
      </Section>
      <Tabs>
        <TabList>
          <Tab>Correspondence</Tab>
        </TabList>
        <TabPanel>
          <Section type="first">
            {messages.length > 0 &&
              messages.map((message, key) => (
                <MessageCard key={key} data={message} />
              ))}
          </Section>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default Inquiry;
