import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";

import styles from "./SplitScreen.module.scss";
import { cl } from "../../../helpers";
import { AppContext, NavigatorContext } from "../../../context";
import { Navigator, Header, Footer } from "../../LayoutElements";
import Dashboard from "../../../screens/Dashboard";
import Companies from "../../../screens/Companies";
import Company from "../../../screens/Company";
import Ledger from "../../../screens/Ledger";
import Rates from "../../../screens/Rates";
import Rate from "../../../screens/Rate";
import LedgerEntry from "../../../screens/LedgerEntry";
import Inquiries from "../../../screens/Inquiries";
import Inquiry from "../../../screens/Inquiry";
import Orders from "../../../screens/Orders";
import Order from "../../../screens/Order";
import ContentManager from "../../../screens/ContentManager";
import ContentEditor from "../../../screens/ContentEditor";
import Settings from "../../../screens/Settings";
import Help from "../../../screens/Help";
import Page from "../../../screens/Page";
import PageNotFound from "../../../screens/PageNotFound";

function SplitScreen() {
  const navigatorContext = useContext(NavigatorContext);
  const { currentUser } = useContext(AppContext);
  const switches = {
    Root: (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/orders/draft" exact component={Orders} />
        <Route path="/orders/archive" exact component={Orders} />
        <Route path="/orders/:order" exact component={Order} />
        <Route path="/inquiries" exact component={Inquiries} />
        <Route path="/inquiries/:inquiry" exact component={Inquiry} />
        <Route path="/companies" exact component={Companies} />
        <Route path="/companies/edit/:company" exact component={Company} />
        <Route path="/ledger" exact component={Ledger} />
        <Route path="/ledger/edit/:ledger" exact component={LedgerEntry} />
        <Route path="/rates" exact component={Rates} />
        <Route path="/rates/edit/:date" exact component={Rate} />
        <Route path="/content" exact component={ContentManager} />
        <Route path="/content/edit/:content" exact component={ContentEditor} />
        <Route path="/profile" component={Company} />
        <Route path="/settings" component={Settings} />
        <Route path="/help" component={Help} />
        <Route path="/page/:page" component={Page} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    ),
    Admin: (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/orders/draft" exact component={Orders} />
        <Route path="/orders/archive" exact component={Orders} />
        <Route path="/orders/:order" exact component={Order} />
        <Route path="/inquiries" exact component={Inquiries} />
        <Route path="/inquiries/:inquiry" exact component={Inquiry} />
        <Route path="/companies" exact component={Companies} />
        <Route path="/companies/edit/:company" exact component={Company} />
        <Route path="/settings" component={Settings} />
        <Route path="/help" component={Help} />
        <Route path="/page/:page" component={Page} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    ),
    Cashier: (
      <Switch>
        <Route path="/" exact component={Ledger} />
        <Route path="/ledger/edit/:ledger" exact component={LedgerEntry} />
        <Route path="/rates" exact component={Rates} />
        <Route path="/rates/edit/:date" exact component={Rate} />
        <Route path="/settings" component={Settings} />
        <Route path="/help" component={Help} />
        <Route path="/page/:page" component={Page} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    ),
    CompanyAdmin: (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/orders/draft" exact component={Orders} />
        <Route path="/orders/archive" exact component={Orders} />
        <Route path="/orders/:order" exact component={Order} />
        <Route path="/inquiries" exact component={Inquiries} />
        <Route path="/inquiries/:inquiry" exact component={Inquiry} />
        <Route path="/profile" component={Company} />
        <Route path="/help" component={Help} />
        <Route path="/settings" component={Settings} />
        <Route path="/page/:page" component={Page} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    ),
    CompanyMember: (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/orders/draft" exact component={Orders} />
        <Route path="/orders/archive" exact component={Orders} />
        <Route path="/orders/:order" exact component={Order} />
        <Route path="/inquiries" exact component={Inquiries} />
        <Route path="/inquiries/:inquiry" exact component={Inquiry} />
        <Route path="/profile" component={Company} />
        <Route path="/help" component={Help} />
        <Route path="/settings" component={Settings} />
        <Route path="/page/:page" component={Page} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    ),
  };

  return (
    <>
      <Header />
      <section
        className={cl(
          styles.splitScreen,
          navigatorContext.isOpen ? styles.withNav : null
        )}
      >
        <section className={styles.nav}>
          <Navigator />
        </section>
        <section className={styles.main}>
          <div
            className={styles.navCloseTrigger}
            onClick={() => navigatorContext.close()}
          />
          {switches[currentUser.role.key] || null}
          <Footer />
        </section>
      </section>
    </>
  );
}

export default SplitScreen;
