import { fonts, borders } from "../DocumentGenerator";

async function generate(wb, ws, data) {
  ws.getColumn(3).width = 25;
  ws.getColumn(5).width = 25;

  // 1
  ws.getCell("A18").value = "DATE";
  ws.getCell("C18").value = "CONTAINER NO";
  ws.getCell("E18").value = "PAY FOR DOCUMENT";
  ws.getRow(18).font = fonts.table;
  ws.getRow(18).height = 30;
  ws.getRow(18).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "000000" },
  };
  ws.getRow(18).border = borders.black;

  // 2
  ws.getCell("A19").value = data.container.invoice_date;
  ws.getCell("C19").value = data.container.provider_id;
  ws.getCell("E19").value = data.container.document_fee;
  ws.getCell("E19").numFmt = '"US$"#,##0.00';
  ws.getRow(19).font = fonts.tableBody;
  ws.getRow(19).border = borders.black;

  // 3
  ws.getCell("A20").value = "TOTAL AMOUNT";
  ws.getCell("E20").value = {
    formula: "E19",
    result: data.container.document_fee,
  };
  ws.getCell("E20").numFmt = '"US$"#,##0.00';
  ws.getRow("20").font = fonts.tableBody;
  ws.getCell("A20").alignment = {
    vertical: "middle",
    horizontal: "right",
  };
  ws.getCell("E20").alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  // 1
  ws.mergeCells(`A18:B18`);
  ws.mergeCells(`C18:D18`);
  ws.mergeCells(`E18:F18`);
  // 2
  ws.mergeCells(`A19:B19`);
  ws.mergeCells(`C19:D19`);
  ws.mergeCells(`E19:F19`);
  // 3
  ws.mergeCells(`A20:D20`);
  ws.mergeCells(`E20:F20`);

  ws.getRow(18).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(19).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
}

export default generate;
