import React from "react";

import { cl } from "../../../helpers";
import styles from "./SignOutTransition.module.scss";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

function SignOutTransition(props) {
  return (
    <div className={styles.container}>
      <Logo
        width="80"
        className={cl(styles.logo)}
        style={{ animationDuration: `${props.duration}ms` }}
      />
    </div>
  );
}

export default SignOutTransition;
