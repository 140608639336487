import React from "react";
import { API, graphqlOperation } from "aws-amplify";

import { createCompany, createCompanyGroup } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";
import { AppContext } from "../../../context";
import { listCompanys } from "../../../graphql/queries";
import { Loader } from "../../PageElements";

const SYSTEM_COMPANY = "system";
const CLIENT_COMPANY = "client";

function NewCompanyForm(props) {
  const { currentUser } = React.useContext(AppContext);
  const [managingCompanies, setManagingCompanies] = React.useState([]);
  const [loadingCompanies, setLoadingCompanies] = React.useState(true);
  const data = props.data ?? [];
  const errors = [];

  React.useEffect(() => {
    if (currentUser.is_system) {
      API.graphql({
        query: listCompanys,
        variables: {
          filter: {
            is_system: { eq: true },
          },
        },
      }).then(
        ({
          data: {
            listCompanys: { items },
          },
        }) => {
          setManagingCompanies(
            items.map((_item) => ({ value: _item.id, label: _item.name }))
          );
          setLoadingCompanies(false);
        }
      );
    } else {
      setLoadingCompanies(false);
    }
  }, [currentUser]);

  const fields = {
    company: {
      value: data.company ?? "",
      component: "text",
      props: {
        label: "Company Name",
        instruction: "Enter Company Name",
      },
      validation: {
        required: true,
      },
    },
    company_number: {
      value: data.company_number ?? "",
      component: "text",
      props: {
        label: "Company Identifier",
        instruction: "Enter Company Identifier (Number)",
      },
      validation: {
        required: true,
      },
    },
    is_system: {
      value: data.is_system ?? CLIENT_COMPANY,
      component: "radio",
      props: {
        label: "Is System Company?",
        options: [
          { label: "Yes this is a system company", value: SYSTEM_COMPANY },
          { label: "No this is a client company", value: CLIENT_COMPANY },
        ],
      },
      validation: {
        required: true,
      },
    },
    managing_company_id: {
      value:
        managingCompanies.find(
          (_company) => _company.value === data.managing_company_id
        ) ?? "",
      component: "select",
      props: {
        label: "Managing Company",
        instruction: "Select Managing Company",
        options: managingCompanies,
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: CLIENT_COMPANY },
    },
    commission: {
      value: data.commission ?? "",
      component: "text",
      props: {
        type: "number",
        label: "Commission %",
        instruction: "Enter Commission %",
      },
      validation: {
        required: true,
        min: 0,
        max: 100,
      },
      condition: { when: "is_system", eq: CLIENT_COMPANY },
    },
    address_line_1: {
      value: data.address_line_1 ?? "",
      component: "text",
      props: {
        label: "Address Line 1",
        instruction: "Enter Address Line 1",
      },
      validation: {
        required: true,
      },
    },
    address_line_2: {
      value: data.address_line_2 ?? "",
      component: "text",
      props: {
        label: "Address Line 2",
        instruction: "Enter Address Line 2",
      },
    },
    phone: {
      value: data.phone ?? "",
      component: "text",
      props: {
        label: "Phone",
        instruction: "Enter Phone",
      },
      validation: {
        required: true,
      },
    },
    fax: {
      value: data.fax ?? "",
      component: "text",
      props: {
        label: "Fax",
        instruction: "Enter Fax",
      },
    },
    vat_id: {
      value: data.vat_id ?? "",
      component: "text",
      props: {
        label: "VAT NO.",
        instruction: "Enter VAT NO.",
      },
    },
    intermediary_bank: {
      value: data.intermediary_bank ?? "",
      component: "text",
      props: {
        label: "Intermediary Bank",
        instruction: "Enter Intermediary Bank Name",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
    intermediary_bank_bic: {
      value: data.intermediary_bank_bic ?? "",
      component: "text",
      props: {
        label: "Intermediary Bank BIC",
        instruction: "Enter Intermediary Bank BIC (Swift)",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
    beneficiary_bank: {
      value: data.beneficiary_bank ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank",
        instruction: "Enter Beneficiary's Bank Name",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
    beneficiary_bank_bic: {
      value: data.beneficiary_bank_bic ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank BIC",
        instruction: "Enter Beneficiary's Bank BIC (Swift)",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
    beneficiary_bank_address: {
      value: data.beneficiary_bank_address ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank Address",
        instruction: "Enter Beneficiary's Bank Address",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
    beneficiary_bank_number: {
      value: data.beneficiary_bank_number ?? "",
      component: "text",
      props: {
        label: "Account Number",
        instruction: "Enter Beneficiary's Bank Account Number",
      },
      validation: {
        required: true,
      },
      condition: { when: "is_system", eq: SYSTEM_COMPANY },
    },
  };

  const submitHandler = async (data) => {
    props.beforeSubmit();

    const {
      data: { createCompany: company },
    } = await API.graphql(
      graphqlOperation(createCompany, {
        input: {
          name: data.company,
          searchableName: data.company.toLowerCase(),
          is_system: data.is_system === SYSTEM_COMPANY,
          managing_company_id: data.managing_company_id,
          number: data.company_number,
          commission: data.commission ?? 0,
          address_line_1: data.address_line_1,
          address_line_2: data.address_line_2,
          phone: data.phone,
          fax: data.fax,
          vat_id: data.vat_id,
          intermediary_bank: data.intermediary_bank,
          intermediary_bank_bic: data.intermediary_bank_bic,
          beneficiary_bank: data.beneficiary_bank,
          beneficiary_bank_bic: data.beneficiary_bank_bic,
          beneficiary_bank_address: data.beneficiary_bank_address,
          beneficiary_bank_number: data.beneficiary_bank_number,
        },
      })
    );

    await API.graphql({
      query: createCompanyGroup,
      variables: {
        company: company.id,
      },
    });

    props.onStore(company);
  };

  if (loadingCompanies) {
    return <Loader size="140" label="Loading form..." />;
  }

  return (
    <FormBuilder
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Create",
      }}
      generalErrors={errors}
    />
  );
}

export default NewCompanyForm;
