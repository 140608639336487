import React, { useState } from "react";
import ReactSelect, { components } from "react-select";
import PropTypes from "prop-types";

import formStyles from "../../FormElement.module.scss";
import { cl } from "../../../../helpers";

const { ValueContainer, Placeholder } = components;

Select.propTypes = {
  nested: PropTypes.bool.isRequired,
};

Select.defaultProps = {
  nested: true,
};

function Select(props) {
  const [isFocused, setIsFocused] = useState(false);
  const required = props.required ? "*" : "";
  const label = props.label;
  const placeholder = props.placeholder ?? label;
  const instruction = props.instruction ?? label;
  const hasErrors = props.errors && props.errors.length;

  const errors = hasErrors
    ? props.errors.map((error, index) => {
        if (!error.error) {
          return null;
        }

        return (
          <span className={formStyles.error} key={`${error.field}_${index}`}>
            {error.error}
          </span>
        );
      })
    : null;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFilled={props.hasValue} isFocused={isFocused}>
          {isFocused
            ? instruction
            : props.hasValue
            ? `${label} ${required}`
            : `${required} ${placeholder}`}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const primaryColor = props.branded
    ? "var(--primary-color-branded)"
    : "var(--primary-color)";
  const customStyles = {
    container: (provided, { isFocused }) => ({
      ...provided,
      marginBottom: props.nested ? "0.5rem" : 0,
      borderBottom: `1px solid ${
        isFocused
          ? primaryColor
          : hasErrors
          ? "var(--error-color)"
          : "transparent"
      }`,
      opacity: props.disabled ? "0.6" : "1",
      outline: "none",
    }),
    control: (provided) => ({
      ...provided,
      border: 0,
      boxShadow: "none",
      borderRadius: 0,
      borderBottom: `1px solid ${
        hasErrors ? "var(--error-color)" : "transparent"
      }`,
      backgroundColor: "var(--surface-color)",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
    valueContainer: (provided) => ({ ...provided, padding: "2rem 1rem 1rem" }),
    indicatorSeparator: () => ({}),
    input: (provided) => ({ ...provided, fontSize: "1rem", color: "inherit" }),
    placeholder: (provided, { isFocused, isFilled }) => ({
      ...provided,
      fontWeight: "200",
      fontSize: isFocused || isFilled ? "0.8rem" : "1rem",
      textTransform: "uppercase",
      letterSpacing: "1.6px",
      color: "inherit",
      top: isFocused || isFilled ? "0.5rem" : "calc(50% - 0.75rem)",
      transform: "none",
      transition: "top 200ms cubic-bezier(0, 0, 0.2, 1)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "inherit",
      transform: "none",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--background-color)",
      border: "1px solid var(--outline-color)",
      zIndex: 2,
      color: "inherit",
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      padding: "1rem",
      backgroundColor: isSelected ? primaryColor : "var(--background-color)",
      "&:active": {
        backgroundColor: primaryColor,
      },
      "&:hover": {
        color: "inherit",
        backgroundColor: "var(--surface-color)",
      },
    }),
  };

  return (
    <div
      className={cl(
        formStyles.wrapper,
        props.nested ? formStyles.nested : null,
        props.wrapperClassName
      )}
    >
      <ReactSelect
        components={{ ValueContainer: CustomValueContainer }}
        isClearable={props.isClearable ?? true}
        styles={customStyles}
        value={props.value}
        placeholder={label}
        onChange={(option) => {
          setIsFocused(false);
          props.onChange(option);
        }}
        isDisabled={props.disabled}
        options={props.options}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />
      <div className={formStyles.errorsContainer}>{errors}</div>
    </div>
  );
}

export default Select;
