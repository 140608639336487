import React from "react";

import { Label } from "../../../PageElements";

function Checkbox(props) {
  return (
    <Label
      value={props.label}
      uppercase={false}
      htmlFor={`${props.name}_${props.value}`}
    >
      <input
        type="checkbox"
        name={props.name}
        id={`${props.name}_${props.value}`}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
    </Label>
  );
}

export default Checkbox;
