import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import styles from "./Options.module.scss";
import { cl } from "../../../helpers";
import { Label, Action, BlockSeparator } from "../";
import { useClickAway, useKeydown } from "../../../hooks";

function Options(props) {
  const { onClose, show, containerRef, history } = props;
  const escPressed = useKeydown("Escape", true, show);
  const clickedAway = useClickAway(containerRef, show);

  useEffect(() => {
    if (escPressed || clickedAway) {
      onClose();
    }
    history.listen(() => {
      onClose();
    });
  }, [escPressed, clickedAway, history, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className={cl(styles.container, "box")}>
      {props.title ? (
        <div className={cl(styles.header, props.headerClassName)}>
          <Label value={props.title} />
        </div>
      ) : null}
      {props.options ? (
        <div className={cl(styles.options, props.optionsClassName)}>
          {props.options.map((option, key) => {
            if (option.type === "separator") {
              return <BlockSeparator key={`options-separator-${key}`} />;
            }
            return (
              <Action
                key={`options-option-${key}`}
                containerClassName={styles.actionItem}
                to={option.to}
                callback={option.callback}
                image={option.image}
                imageType={option.imageType}
                icon={option.icon}
                label={option.label}
                labelProps={option.labelProps}
                descriptor={option.descriptor}
              />
            );
          })}
        </div>
      ) : null}
      {props.children ? (
        <div className={cl(styles.body, props.bodyClassName)}>
          {props.children}
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(Options);
