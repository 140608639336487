import React from "react";

import styles from "./SigninTransition.module.scss";
import { cl } from "../../../helpers";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

function SigninTransition(props) {
  return (
    <div className={styles.container}>
      <Logo
        width="80"
        className={cl(styles.logo)}
        style={{ animationDuration: `${props.duration}ms` }}
      />
    </div>
  );
}

export default SigninTransition;
