import React, { useEffect, useContext } from "react";

import { AppContext, ModalContext, NotificationContext } from "./context";
import { Modal, Notification, Overlay } from "./components/LayoutElements";
import { Loader } from "./components/PageElements";
import { Authentication, SplitScreen } from "./components/UI/Layouts";
import Themes from "./data/themes.json";

function App() {
  const { reinit: initApp, loading, hasSession } = useContext(AppContext);
  const modal = useContext(ModalContext);
  const { notifications } = useContext(NotificationContext);

  useEffect(() => initApp(), [initApp]);

  useEffect(() => {
    const ThemeName = process.env.REACT_APP_THEME || "dark";
    const appTheme = Themes[ThemeName];
    Object.entries(appTheme).forEach(function ([prop, val]) {
      document.body.style.setProperty(prop, val);
    });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader size="300" label="Loading your experience..." />
      </div>
    );
  }

  return (
    <>
      <Overlay />
      <Notification notifications={notifications} />
      <Modal
        show={modal.isShown}
        onClose={() => modal.hide()}
        title={modal.title}
        actions={modal.actions}
        keyboard={modal.shouldCloseOnEsc}
        backdrop={modal.shouldCloseOnBackdropClick}
        closable={modal.hasCloseButton}
        backdropClassName={modal.backdropClassName}
        containerClassName={modal.containerClassName}
        dialogClassName={modal.dialogClassName}
        size={modal.size}
        contentClassName={modal.contentClassName}
        headerClassName={modal.headerClassName}
        bodyClassName={modal.bodyClassName}
        actionsClassName={modal.actionsClassName}
        content={modal.content}
        body={modal.body}
      />
      {hasSession ? <SplitScreen /> : <Authentication />}
    </>
  );
}

export default App;
