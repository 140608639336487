import React from "react";

import styles from "./Label.module.scss";
import { cl } from "../../../helpers";
import { Icon } from "../../PageElements";

function Label(props) {
  if (!props.value && !props.children && !props.icon) {
    return null;
  }

  const uppercase = props.uppercase ?? true;

  return (
    <span
      className={cl(
        styles.labelContainer,
        props.containerClassName,
        props.icon ? styles.withIcon : null
      )}
    >
      {props.icon ? <Icon icon={props.icon} /> : null}
      {props.value || props.children ? (
        <label
          htmlFor={props.htmlFor}
          className={cl(
            styles.label,
            styles[props.size],
            uppercase ? styles.uppercase : null,
            props.className
          )}
        >
          {props.children}
          {props.value}
        </label>
      ) : null}
    </span>
  );
}

export default Label;
