import { fonts, borders, calcTotalAmount } from "../DocumentGenerator";

const INSURANCE_MULTIPLIER = 0.0015;

async function generate(wb, ws, data) {
  const totalAmount = calcTotalAmount(
    data.container.items.items,
    data.rates.usd_rate
  );

  ws.getColumn(4).width = 25;
  ws.getColumn(6).width = 40;

  // 1
  ws.getCell("A18").value = "DATE";
  ws.getCell("B18").value = "CONTAINER NO";
  ws.getCell("D18").value = "TOTAL VALUE";
  ws.getCell("F18").value = "TOTAL PERCENTAGE";
  ws.getRow(18).font = fonts.table;
  ws.getRow(18).height = 30;
  ws.getRow(18).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "000000" },
  };
  ws.getRow(18).border = borders.black;

  // 2
  ws.getCell("A19").value = data.container.invoice_date;
  ws.getCell("B19").value = data.container.provider_id;
  ws.getCell("D19").value = totalAmount;
  ws.getCell("D19").numFmt = '"US$"#,##0.00';
  ws.getCell("F19").value = {
    formula: `D19*${INSURANCE_MULTIPLIER}`,
    result: totalAmount * INSURANCE_MULTIPLIER,
  };
  ws.getCell("F19").numFmt = '"US$"#,##0.00';
  ws.getRow(19).font = fonts.tableBody;
  ws.getRow(19).border = borders.black;

  // 3
  ws.getCell("A20").value = "TOTAL AMOUNT";
  ws.getCell("A20").alignment = {
    vertical: "middle",
    horizontal: "right",
  };
  ws.getCell("F20").value = {
    formula: "F19",
    result: totalAmount * INSURANCE_MULTIPLIER,
  };
  ws.getCell("F20").numFmt = '"US$"#,##0.00';
  ws.getCell("F20").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getRow("20").font = fonts.tableBody;

  // 1
  ws.mergeCells(`B18:C18`);
  ws.mergeCells(`D18:E18`);
  // 2
  ws.mergeCells(`B19:C19`);
  ws.mergeCells(`D19:E19`);
  // 3
  ws.mergeCells(`A20:E20`);

  ws.getRow(18).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(19).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
}

export default generate;
