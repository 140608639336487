import React from "react";
import {
  Accordion as BaseAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./Accordion.scss";

function Accordion(props) {
  return (
    <BaseAccordion allowZeroExpanded={true} preExpanded={props.preExpanded}>
      {props.sections.map((section) => (
        <AccordionItem
          key={`accordion-section-${section.id}`}
          uuid={section.id}
        >
          <AccordionItemHeading>
            <AccordionItemButton>{section.heading}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {section.content.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </BaseAccordion>
  );
}

export default Accordion;
