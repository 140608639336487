import React, { useState, useCallback, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
  getCorrespondence,
  getCorrespondenceMessages,
} from "../../../../graphql/queries";
import { MessageCard } from "../";
import { Loader, Section, Heading, Paragraph } from "../../../PageElements";

function OrderChangeLog(props) {
  const { order_id } = props;
  const [loading, setLoading] = useState(true);
  const [correspondence, setCorrespondence] = useState(null);
  const [messages, setMessages] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { getCorrespondence: correspondenceData },
    } = await API.graphql(
      graphqlOperation(getCorrespondence, {
        id: order_id,
      })
    );
    setCorrespondence(correspondenceData);
    const {
      data: { getCorrespondenceMessages: messagesData },
    } = await API.graphql(
      graphqlOperation(getCorrespondenceMessages, {
        correspondence_id: correspondenceData.id,
        sortDirection: "DESC",
      })
    );
    setMessages(messagesData);
    setLoading(false);
  }, [order_id]);

  useEffect(() => {
    fetchData();
    return () => {
      setCorrespondence(null);
    };
  }, [fetchData]);

  if (loading) {
    return (
      <Section type="first">
        <Loader size="200" label="Loading Change Log..." />
      </Section>
    );
  }

  if (!correspondence) {
    return (
      <Section type="first">
        <Section type="center">
          <Heading tag="h1" label="No Change Log" />
          <Paragraph>
            There are no changes recorded for this order yet...
          </Paragraph>
        </Section>
      </Section>
    );
  }

  return (
    <>
      {messages.items.map((message, key) => (
        <MessageCard key={`message-${key}`} data={message} />
      ))}
    </>
  );
}

export default OrderChangeLog;
