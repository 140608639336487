import React, { useState, useContext } from "react";
import { Redirect } from "react-router";

import { ForgotPasswordForm, ResetPasswordForm } from "../components/UI/Forms";
import { Heading, Link } from "../components/PageElements";
import { NotificationContext } from "../context";

function ResetPassword() {
  const notification = useContext(NotificationContext);
  const [email, setEmail] = useState(null);
  const [done, setDone] = useState(false);

  if (done) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Heading label={email ? "Change Password" : "Forgot Password"} tag="h1" />
      {email ? (
        <ResetPasswordForm
          email={email}
          afterSubmit={() => {
            notification.quickConfirm(`Password changed, successfully.`);
            setDone(true);
          }}
        />
      ) : (
        <ForgotPasswordForm afterSubmit={({ email }) => setEmail(email)} />
      )}
      <br />
      <Link to="/" label="Want to signin?" labelUppercase={false} />
    </div>
  );
}

export default ResetPassword;
