import React, { useContext, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import styles from "./Footer.module.scss";
import { getContentByType } from "../../../graphql/queries";
import { InlineSeparator, Label, Link } from "../../PageElements";
import { AppContext } from "../../../context";

function Footer() {
  const app = useContext(AppContext);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const {
      data: {
        getContentByType: { items },
      },
    } = await API.graphql(
      graphqlOperation(getContentByType, {
        type: "PAGE",
        sortDirection: "DESC",
      })
    );
    setData(items);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  return (
    <footer className={styles.footer}>
      <Label
        uppercase={false}
        value={`© ${new Date().getFullYear()} LCH Ltd. ${app.version}`}
        size="small"
      />
      {data.length > 0 && (
        <div className={styles.links}>
          {data.map((item, key) => {
            return (
              <span key={`footer-nav-${key}`}>
                <Link
                  to={`/page/${item.slug}`}
                  label={item.title}
                  labelSize="small"
                  type="branded"
                  reverseAnimation={true}
                />
                {key + 1 < data.length && (
                  <InlineSeparator className={styles.separator} />
                )}
              </span>
            );
          })}
        </div>
      )}
    </footer>
  );
}

export default Footer;
