import React, { useState, useContext, useCallback, useEffect } from "react";
import { Storage } from "aws-amplify";

import { Table } from "../../../PageElements";
import { NotificationContext, AppContext } from "../../../../context";
import { downloadBlob } from "../../../../helpers";
import generateContainerDocument from "../../../../libs/DocumentGenerator/DocumentGenerator";

function ContainerFileWidget(props) {
  const { currentUser } = useContext(AppContext);
  const notification = useContext(NotificationContext);
  const { order, container } = props;
  const path = `files/${container.id}/`;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    {
      key: "key",
      label: "File Name",
      type: "renderer",
      renderer: (key) => key.replace(path, ""),
    },
  ];

  const actions = [];
  actions.push({
    label: "Download",
    callback: async ({ key, generator, container }) => {
      if (generator && typeof generator === "function") {
        notification.quickConfirm(`Generating file, please wait...`, {
          selfDestructTimerDuration: 4000,
        });
        const buffer = await generator(order, container, currentUser.is_system);
        downloadBlob(new Blob([buffer]), key);
      } else {
        notification.quickConfirm(`Downloading...`);
        const result = await Storage.get(key, { download: true });
        downloadBlob(result.Body, key.replace(path, ""));
      }
    },
  });
  if (currentUser.is_system) {
    actions.push(
      {
        type: "separator",
        shouldRender: ({ generator }) => !generator,
      },
      {
        label: "Delete",
        shouldRender: ({ generator }) => !generator,
        callback: ({ key }) => {
          Storage.remove(key).then(() => {
            notification.quickConfirm(`File delete successfully`);
            fetchData();
            props.onFileRemove && props.onFileRemove();
          });
        },
      }
    );
  }

  const fetchData = useCallback(async () => {
    setLoading(true);
    const invoices = [];
    if (container.shipping_date) {
      invoices.push({
        key: `${container.provider}-${container.provider_id}.xlsx`,
        container,
        generator: generateContainerDocument,
      });
    }
    const files = await Storage.list(path);
    setData([...invoices, ...files]);
    setLoading(false);
  }, [path, container]);

  useEffect(fetchData, [fetchData]);

  return (
    <Table
      loading={loading}
      columns={columns}
      mainColumn="key"
      actions={actions}
      data={data}
    />
  );
}

export default ContainerFileWidget;
