/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getManagingCompany = /* GraphQL */ `
  query GetManagingCompany($companyId: ID!) {
    getManagingCompany(companyId: $companyId)
  }
`;
export const getCompanyUsers = /* GraphQL */ `
  query GetCompanyUsers($companyId: ID!) {
    getCompanyUsers(companyId: $companyId) {
      id
      email
      full_name
      role
      enabled
      createdAt
    }
  }
`;
export const getCorrespondenceMessages = /* GraphQL */ `
  query GetCorrespondenceMessages(
    $correspondence_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCorrespondenceMessages(
      correspondence_id: $correspondence_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_id
        correspondence_id
        sender {
          id
          name
          company
        }
        text
        template
        vars
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        receiver
        title
        content
        type
        to
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsForReceiver = /* GraphQL */ `
  query NotificationsForReceiver(
    $receiver: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsForReceiver(
      receiver: $receiver
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receiver
        title
        content
        type
        to
        icon
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        role
        given_name
        family_name
        company_id
        company_name
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyInvitations = /* GraphQL */ `
  query CompanyInvitations(
    $company_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyInvitations(
      company_id: $company_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        role
        given_name
        family_name
        company_id
        company_name
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLedgerEntry = /* GraphQL */ `
  query GetLedgerEntry($id: ID!) {
    getLedgerEntry(id: $id) {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const listLedgerEntrys = /* GraphQL */ `
  query ListLedgerEntrys(
    $filter: ModelLedgerEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLedgerEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        ledgerEntryCompanyId
        debit
        credit
        createdAt
        updatedAt
        company {
          id
          name
          searchableName
          is_system
          managing_company_id
          number
          commission
          address_line_1
          address_line_2
          phone
          fax
          vat_id
          intermediary_bank
          intermediary_bank_bic
          beneficiary_bank
          beneficiary_bank_bic
          beneficiary_bank_address
          beneficiary_bank_number
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRate = /* GraphQL */ `
  query GetRate($date: AWSDate!) {
    getRate(date: $date) {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const listRates = /* GraphQL */ `
  query ListRates(
    $date: AWSDate
    $filter: ModelRateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRates(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        usd_rate
        euro_rate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCorrespondence = /* GraphQL */ `
  query GetCorrespondence($id: ID!) {
    getCorrespondence(id: $id) {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const listCorrespondences = /* GraphQL */ `
  query ListCorrespondences(
    $filter: ModelCorrespondenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorrespondences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author_id
        sender {
          id
          name
          company
        }
        group_id
        type
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCorrespondencesByAuthor = /* GraphQL */ `
  query GetCorrespondencesByAuthor(
    $author_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorrespondenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCorrespondencesByAuthor(
      author_id: $author_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author_id
        sender {
          id
          name
          company
        }
        group_id
        type
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCorrespondencesByType = /* GraphQL */ `
  query GetCorrespondencesByType(
    $type: CorrespondenceTypeEnum
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorrespondenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCorrespondencesByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author_id
        sender {
          id
          name
          company
        }
        group_id
        type
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        orderCompanyId
        transport
        items {
          items {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        type
        createdAt
        updatedAt
        company {
          id
          name
          searchableName
          is_system
          managing_company_id
          number
          commission
          address_line_1
          address_line_2
          phone
          fax
          vat_id
          intermediary_bank
          intermediary_bank_bic
          beneficiary_bank
          beneficiary_bank_bic
          beneficiary_bank_address
          beneficiary_bank_number
          createdAt
          updatedAt
        }
        containers {
          items {
            id
            company_id
            order_id
            number
            provider
            provider_id
            invoice_number
            invoice_date
            shipping_date
            delivery_time
            end_destination
            document_fee
            freight_fee
            telex_dhl_fee
            delivered_at
            createdAt
            updatedAt
            items {
              items {
                id
                company_id
                container_id
                item {
                  id
                  shop_id
                  order_id
                  number
                  description
                  material
                  quantity
                  unit_name
                  unit_price
                  total_amount
                  quantity_carton
                  cartons
                  length
                  height
                  width
                  cbm
                  containers
                  weight
                  createdAt
                  updatedAt
                }
                cbm_in
                createdAt
                updatedAt
              }
              nextToken
            }
            accessories {
              items {
                id
                company_id
                container_id
                number
                description
                quantity
                unit_name
                unit_price
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const orderByNumber = /* GraphQL */ `
  query OrderByNumber(
    $number: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        orderCompanyId
        transport
        items {
          items {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        type
        createdAt
        updatedAt
        company {
          id
          name
          searchableName
          is_system
          managing_company_id
          number
          commission
          address_line_1
          address_line_2
          phone
          fax
          vat_id
          intermediary_bank
          intermediary_bank_bic
          beneficiary_bank
          beneficiary_bank_bic
          beneficiary_bank_address
          beneficiary_bank_number
          createdAt
          updatedAt
        }
        containers {
          items {
            id
            company_id
            order_id
            number
            provider
            provider_id
            invoice_number
            invoice_date
            shipping_date
            delivery_time
            end_destination
            document_fee
            freight_fee
            telex_dhl_fee
            delivered_at
            createdAt
            updatedAt
            items {
              items {
                id
                company_id
                container_id
                item {
                  id
                  shop_id
                  order_id
                  number
                  description
                  material
                  quantity
                  unit_name
                  unit_price
                  total_amount
                  quantity_carton
                  cartons
                  length
                  height
                  width
                  cbm
                  containers
                  weight
                  createdAt
                  updatedAt
                }
                cbm_in
                createdAt
                updatedAt
              }
              nextToken
            }
            accessories {
              items {
                id
                company_id
                container_id
                number
                description
                quantity
                unit_name
                unit_price
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ordersByDate = /* GraphQL */ `
  query OrdersByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        orderCompanyId
        transport
        items {
          items {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        type
        createdAt
        updatedAt
        company {
          id
          name
          searchableName
          is_system
          managing_company_id
          number
          commission
          address_line_1
          address_line_2
          phone
          fax
          vat_id
          intermediary_bank
          intermediary_bank_bic
          beneficiary_bank
          beneficiary_bank_bic
          beneficiary_bank_address
          beneficiary_bank_number
          createdAt
          updatedAt
        }
        containers {
          items {
            id
            company_id
            order_id
            number
            provider
            provider_id
            invoice_number
            invoice_date
            shipping_date
            delivery_time
            end_destination
            document_fee
            freight_fee
            telex_dhl_fee
            delivered_at
            createdAt
            updatedAt
            items {
              items {
                id
                company_id
                container_id
                item {
                  id
                  shop_id
                  order_id
                  number
                  description
                  material
                  quantity
                  unit_name
                  unit_price
                  total_amount
                  quantity_carton
                  cartons
                  length
                  height
                  width
                  cbm
                  containers
                  weight
                  createdAt
                  updatedAt
                }
                cbm_in
                createdAt
                updatedAt
              }
              nextToken
            }
            accessories {
              items {
                id
                company_id
                container_id
                number
                description
                quantity
                unit_name
                unit_price
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrderContainer = /* GraphQL */ `
  query GetOrderContainer($id: ID!) {
    getOrderContainer(id: $id) {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOrderContainers = /* GraphQL */ `
  query ListOrderContainers(
    $filter: ModelOrderContainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderContainers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_id
        order_id
        number
        provider
        provider_id
        invoice_number
        invoice_date
        shipping_date
        delivery_time
        end_destination
        document_fee
        freight_fee
        telex_dhl_fee
        delivered_at
        createdAt
        updatedAt
        items {
          items {
            id
            company_id
            container_id
            item {
              id
              shop_id
              order_id
              number
              description
              material
              quantity
              unit_name
              unit_price
              total_amount
              quantity_carton
              cartons
              length
              height
              width
              cbm
              containers
              weight
              createdAt
              updatedAt
            }
            cbm_in
            createdAt
            updatedAt
          }
          nextToken
        }
        accessories {
          items {
            id
            company_id
            container_id
            number
            description
            quantity
            unit_name
            unit_price
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContainersByOrder = /* GraphQL */ `
  query GetContainersByOrder(
    $order_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderContainerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContainersByOrder(
      order_id: $order_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company_id
        order_id
        number
        provider
        provider_id
        invoice_number
        invoice_date
        shipping_date
        delivery_time
        end_destination
        document_fee
        freight_fee
        telex_dhl_fee
        delivered_at
        createdAt
        updatedAt
        items {
          items {
            id
            company_id
            container_id
            item {
              id
              shop_id
              order_id
              number
              description
              material
              quantity
              unit_name
              unit_price
              total_amount
              quantity_carton
              cartons
              length
              height
              width
              cbm
              containers
              weight
              createdAt
              updatedAt
            }
            cbm_in
            createdAt
            updatedAt
          }
          nextToken
        }
        accessories {
          items {
            id
            company_id
            container_id
            number
            description
            quantity
            unit_name
            unit_price
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContainerAccessory = /* GraphQL */ `
  query GetContainerAccessory($id: ID!) {
    getContainerAccessory(id: $id) {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const listContainerAccessorys = /* GraphQL */ `
  query ListContainerAccessorys(
    $filter: ModelContainerAccessoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContainerAccessorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company_id
        container_id
        number
        description
        quantity
        unit_name
        unit_price
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($slug: String!) {
    getContent(slug: $slug) {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $slug: String
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContents(
      slug: $slug
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        slug
        type
        title
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContentByType = /* GraphQL */ `
  query GetContentByType(
    $type: ContentTypeEnum
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContentByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        slug
        type
        title
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
