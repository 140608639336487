import React, { useState } from "react";
import { v4 as uuid } from "uuid";

export const NotificationContext = React.createContext();

function NotificationContextProvider(props) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (notification) => {
    setNotifications((currentNotifications) => {
      return [notification, ...currentNotifications];
    });
  };

  const destruct = (notification) => {
    setNotifications((current) => {
      const updated = [...current];
      const toDel = updated.findIndex((item) => item.id === notification.id);
      if (toDel > -1) {
        updated.splice(toDel, 1);
      }
      return updated;
    });
  };

  const markForDestruction = (notification) => {
    setNotifications((current) => {
      const updated = [...current];
      const toDel = updated.findIndex((item) => item.id === notification.id);
      if (toDel > -1) {
        updated[toDel].destroy = true;
      }
      return updated;
    });

    setTimeout(() => {
      destruct(notification);
    }, notification.destroyDuration - 50);
  };

  const selfDestruct = (notification) => {
    if (notification.selfDestructTimerDuration) {
      setTimeout(() => {
        markForDestruction(notification);
      }, notification.selfDestructTimerDuration);
    }
  };

  const close = (notification) => {
    markForDestruction(notification);
  };

  const info = (msg, title) => {
    const notification = {
      id: uuid(),
      title: title ?? "Update",
      content: msg,
      type: "info",
      selfDestructTimerDuration: 5000,
      closable: true,
      destroyDuration: 300,
    };
    addNotification(notification);
    selfDestruct(notification);
  };

  const success = (msg) => {
    const notification = {
      id: uuid(),
      title: "Success",
      content: msg,
      type: "success",
      selfDestructTimerDuration: 5000,
      closable: true,
      destroyDuration: 300,
    };
    addNotification(notification);
    selfDestruct(notification);
  };

  const quickConfirm = (msg, options = {}) => {
    const notification = {
      id: uuid(),
      title: "Status",
      content: msg,
      type: "confirm",
      closable: true,
      selfDestructTimerDuration: options.selfDestructTimerDuration || 2500,
      destroyDuration: 300,
    };
    addNotification(notification);
    selfDestruct(notification);
  };

  const startProgress = (msg) => {
    const trackerId = uuid();
    const notification = {
      id: trackerId,
      title: "In Progress...",
      content: msg,
      type: "progress",
      destroyDuration: 300,
    };
    addNotification(notification);

    return trackerId;
  };

  const updateProgress = (trackerId, progress, doneMsg = "Done!") => {
    setNotifications((currentNotifications) => {
      const updatedNotifications = [...currentNotifications];
      const updateKey = updatedNotifications.findIndex(
        (notification) => notification.id === trackerId
      );
      const updatedNotification = { ...updatedNotifications[updateKey] };
      if (progress < 100) {
        updatedNotification.content = `Done: ${progress}%`;
      } else {
        updatedNotification.title = `Success`;
        updatedNotification.type = `success`;
        updatedNotification.content = doneMsg;
        updatedNotification.closable = true;
        updatedNotification.selfDestructTimerDuration = 5000;
        selfDestruct(updatedNotification);
      }
      updatedNotifications[updateKey] = updatedNotification;

      return updatedNotifications;
    });
  };

  const cancelProgress = (trackerId) => {
    setNotifications((currentNotifications) => {
      const updatedNotifications = [...currentNotifications];
      const updateKey = updatedNotifications.findIndex(
        (notification) => notification.id === trackerId
      );
      const updatedNotification = { ...updatedNotifications[updateKey] };
      updatedNotification.selfDestructTimerDuration = 1000;
      selfDestruct(updatedNotification);
      updatedNotifications[updateKey] = updatedNotification;

      return updatedNotifications;
    });
  };

  const error = (msg) => {
    addNotification({
      id: uuid(),
      title: "Whoops, something went wrong!",
      content: msg,
      type: "error",
      closable: true,
      destroyDuration: 300,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        close,
        info,
        success,
        quickConfirm,
        startProgress,
        updateProgress,
        cancelProgress,
        error,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider;
