import React from "react";

import { Section, Heading, Paragraph, Icon, Link } from "../../PageElements";
import styles from "./UnassignedUserOverlay.module.scss";
import { cl } from "../../../helpers";

function UnassignedUserOverlay() {
  return (
    <div className={cl(styles.container)}>
      <Section>
        <center>
          <Icon icon="fa fa-user" size="x10" />
        </center>
        <br />
        <Heading label="No Company" tag="h1" />
        <Paragraph>
          Hmm, it looks like your user is not assigned to any company.
          <br />
          Please, try refreshing or contact{" "}
          <Link to="/support" external={true} label="support" />.
        </Paragraph>
      </Section>
    </div>
  );
}

export default UnassignedUserOverlay;
