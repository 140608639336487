import React, { useState, useEffect, useContext, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";

import { ORDER_STATUS_LIST, TRANSPORT_OPTIONS } from "../config";
import { ordersByDate, getOrder, listCompanys } from "../graphql/queries";
import { deleteOrder, deleteOrderItem } from "../graphql/mutations";
import { onDeleteOrder } from "../graphql/subscriptions";
import { AppContext } from "../context";
import { Section, Heading, Table } from "../components/PageElements";
import { Select } from "../components/FormElements";

function Orders() {
  const { pathname } = useLocation();
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, selectCompany] = useState(null);

  const getPageHeading = () => {
    switch (pathname) {
      case "/orders/draft":
        return "Draft Orders";
      case "/orders":
        return "Active Orders";
      case "/orders/archive":
        return "Archived Orders";
      default:
        return "Order List";
    }
  };

  const fetchData = useCallback(
    async (next = null, company = null) => {
      let filter = { filter: { and: [] } };
      switch (pathname) {
        case "/orders/draft":
          filter.filter.and.push({ status: { eq: "DRAFT" } });
          break;
        case "/orders":
          filter.filter.and.push({ status: { ne: "DRAFT" } });
          filter.filter.and.push({ status: { ne: "ARCHIVE" } });
          break;
        case "/orders/archive":
          filter.filter.and.push({ status: { eq: "ARCHIVE" } });
          break;
        default:
          break;
      }

      if (company) {
        filter.filter.and.push({ orderCompanyId: { eq: company } });
      }

      setLoading(true);
      const {
        data: {
          ordersByDate: { items, nextToken },
        },
      } = await API.graphql(
        graphqlOperation(ordersByDate, {
          type: "ORDER",
          sortDirection: "DESC",
          limit: 25,
          nextToken: next,
          ...filter,
        })
      );
      setData((currentItems) => {
        const newItems = [...currentItems];
        newItems.push(...items);
        return newItems;
      });
      setNext(nextToken);
      setLoading(false);
    },
    [pathname]
  );

  const getCompanies = async () => {
    const {
      data: {
        listCompanys: { items },
      },
    } = await API.graphql(graphqlOperation(listCompanys));
    setCompanies([{ id: null, name: "All Companies" }, ...items]);
  };

  useEffect(() => {
    getCompanies();
    return () => {
      setCompanies([]);
    };
  }, []);

  useEffect(() => {
    fetchData(null, selectedCompany);

    return () => {
      setData([]);
    };
  }, [fetchData, selectedCompany]);

  useEffect(() => {
    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteOrder)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteOrder: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (order) => order.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      deletionSubscription.unsubscribe();
    };
  }, []);

  const columns = [
    { key: "number", label: "Number", isSortable: true, type: "label" },
    {
      key: "status",
      label: "Status",
      type: "label",
      isSortable: true,
      formula: (val) => {
        const orderStatus = ORDER_STATUS_LIST.find(
          (status) => status.value === val
        );

        if (!orderStatus) {
          return val;
        }

        return orderStatus.label;
      },
    },
    { key: "createdAt", label: "Created", type: "date", isSortable: true },
    {
      key: "transport",
      label: "Transport",
      type: "label",
      isSortable: true,
      formula: (val) => {
        const orderTransport = TRANSPORT_OPTIONS.find((t) => t.value === val);
        if (!orderTransport) {
          return val;
        }

        return orderTransport.label;
      },
    },
  ];

  if (currentUser.is_system) {
    columns.splice(0, 0, {
      key: "company.name",
      label: "Client",
      type: "label",
      isSortable: true,
    });
  }

  const actions = [
    { label: "Open", to: ({ id }) => `/orders/${id}` },
    { type: "separator" },
    // { label: "Copy", callback: () => {} },
    // { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ id }) => {
        const {
          data: { getOrder: data },
        } = await API.graphql(
          graphqlOperation(getOrder, {
            id,
          })
        );
        if (data.items.items.length) {
          await Promise.all(
            data.items.items.map(async (item) => {
              await API.graphql(
                graphqlOperation(deleteOrderItem, { input: { id: item.id } })
              );
            })
          );
        }
        await API.graphql(graphqlOperation(deleteOrder, { input: { id } }));

        return { success: true };
      },
    },
  ];

  return (
    <Section type="screen">
      <Heading tag="h1" label={getPageHeading()} />
      {currentUser.is_system && (
        <Select
          label="Company Filter"
          instruction="Filter list by company"
          isClearable={false}
          options={companies
            .filter((company) => !company.is_system)
            .map((company) => ({
              value: company.id,
              label: company.name,
            }))}
          onChange={(company) => selectCompany(company ? company.value : null)}
        />
      )}
      <Table
        loading={loading}
        hasNext={!!next}
        onNext={{
          label: "Load More Orders...",
          handler: () => fetchData(next, selectedCompany),
        }}
        columns={columns}
        mainColumn="number"
        data={data}
        actions={actions}
      />
    </Section>
  );
}

export default Orders;
