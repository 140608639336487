import React, { useContext } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";

import { OrganizationContext, NotificationContext } from "../../../context";
import { createInvite, sendInvite } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";

const isEmailUsed = async (email) => {
  try {
    await Auth.signIn(email.toLowerCase(), "123");
    return true;
  } catch (error) {
    const { code } = error;
    switch (code) {
      case "UserNotFoundException":
        return false;
      case "NotAuthorizedException":
        return true;
      case "PasswordResetRequiredException":
        return true;
      case "UserNotConfirmedException":
        return true;
      default:
        return false;
    }
  }
};

function InviteUserForm(props) {
  const { organization } = useContext(OrganizationContext);
  const notification = useContext(NotificationContext);
  const data = props.data ?? [];
  const errors = [];
  const roles = data.company.is_system
    ? [
        { label: "Root", value: "ROOT" },
        { label: "Admin", value: "ADMIN" },
        { label: "Cashier", value: "CASHIER" },
      ]
    : [
        { label: "Manager", value: "COMPANY_ADMIN" },
        { label: "Employee", value: "COMPANY_MEMBER" },
      ];

  const fields = {};

  if (organization.is_system) {
    fields.company = {
      value:
        `${data.company.name} / #${data.company.number} [${
          data.company.is_system ? "System Company" : "Client Company"
        }]` ?? "",
      component: "text",
      props: {
        label: "Company Name",
        instruction: "Enter Company Name",
        readonly: true,
      },
      validation: {
        required: true,
      },
    };
  }

  fields.email = {
    value: data.email ?? "",
    component: "text",
    props: {
      type: "email",
      label: "Email",
      instruction: "Enter Email",
    },
    validation: {
      required: true,
      email: true,
    },
  };
  fields.role = {
    value: data.role ?? "",
    component: "select",
    props: {
      label: "Role",
      instruction: "Select Role",
      options: roles,
    },
    validation: {
      required: true,
    },
  };
  fields.firstName = {
    value: data.firstName ?? "",
    component: "text",
    props: {
      label: "First Name",
      instruction: "Enter First Name",
    },
  };
  fields.lastName = {
    value: data.lastName ?? "",
    component: "text",
    props: {
      label: "Last Name",
      instruction: "Enter Last Name",
    },
  };
  fields.note = {
    value: data.note ?? "",
    component: "textarea",
    props: {
      label: "Note",
      instruction: "Enter note to the user",
    },
  };

  const submitHandler = async (data) => {
    if (await isEmailUsed(data.email)) {
      notification.error(
        `User with email '${data.email}' is already registered in the system.`
      );
      return;
    }
    props.beforeSubmit();
    const {
      data: { createInvite: invite },
    } = await API.graphql(
      graphqlOperation(createInvite, {
        input: {
          email: data.email,
          role: data.role,
          given_name: data.firstName,
          family_name: data.lastName,
          company_id: organization.is_system
            ? props.data.company.id
            : organization.id,
          company_name: organization.is_system
            ? props.data.company.name
            : organization.name,
          note: data.note,
        },
      })
    );
    API.graphql({
      query: sendInvite,
      variables: {
        id: invite.id,
      },
    });

    props.onStore(data);
  };

  return (
    <FormBuilder
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Invite",
      }}
      disclaimer="User is going to receive an invitation via email when you press 'Invite'."
      generalErrors={errors}
    />
  );
}

export default InviteUserForm;
