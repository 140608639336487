import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

import { AppContext } from "../../../context";
import { FormBuilder } from "../../FormElements";
import { createNotification } from "../../../graphql/mutations";

function SignupForm(props) {
  const app = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { data: invitationData } = props;

  const fields = {
    given_name: {
      value: invitationData.given_name || "",
      component: "text",
      props: {
        label: "First Name",
        instruction: "Enter First Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
    family_name: {
      value: invitationData.family_name || "",
      component: "text",
      props: {
        label: "Last Name",
        instruction: "Enter Last Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
    email: {
      value: invitationData.email || "",
      component: "text",
      props: {
        type: "email",
        label: "Your Email",
        instruction: "Enter Email",
        disabled: "disabled",
      },
      validation: {
        required: true,
        email: true,
      },
    },
    password: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "Password",
        instruction: "Choose Password",
        autoComplete: "new_password",
      },
      validation: {
        required: true,
        password: true,
      },
    },
    password_confirmation: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "Password Confirmation",
        instruction: "Repeat Password",
        autoComplete: "new_password",
      },
      validation: {
        required: true,
        same: "password",
      },
    },
  };

  const signupHandler = async (data) => {
    props.beforeSignup();
    setErrors([]);
    setLoading(true);
    try {
      await Auth.signUp({
        username: invitationData.email,
        password: data.password,
        attributes: {
          given_name: data.given_name,
          family_name: data.family_name,
          "custom:company_id": invitationData.company_id,
        },
        clientMetadata: {
          invitation_id: invitationData.id,
        },
      });
      await Auth.signIn(invitationData.email, data.password);
      await app.reinit();

      API.graphql(
        graphqlOperation(createNotification, {
          input: {
            receiver: invitationData.company_id,
            title: `New Company Member`,
            content: `${data.given_name} ${data.family_name} just joined your company.`,
            icon: `fas fa-user-plus`,
            type: "CONFIRM",
          },
        })
      );

      props.afterSignup(data);
      setLoading(false);
    } catch (e) {
      props.onError();
      setLoading(false);
      setErrors([e.message]);
    }
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: signupHandler,
        label: "Sign up",
      }}
      generalErrors={errors}
    />
  );
}

export default SignupForm;
