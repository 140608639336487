import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import slugify from "slugify";

import { createContent } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";

function ContentEntryForm(props) {
  const [loading, setLoading] = useState(false);
  const data = props.data || {};

  const fields = {
    title: {
      value: data.title ?? "",
      component: "text",
      props: {
        label: "Title",
        instruction: "Enter Title",
      },
      validation: {
        required: true,
      },
    },
    type: {
      value: data.type ?? "",
      component: "select",
      props: {
        label: "Category",
        instruction: "Select Category",
        options: [
          { value: "PAGE", label: "Page" },
          { value: "HELP", label: "Help" },
        ],
      },
      validation: {
        required: true,
      },
    },
    body: {
      value: data.body ?? "",
      component: "textarea",
      props: {
        label: "Content",
        instruction: "Enter Content",
        rows: 5,
      },
      validation: {
        required: true,
      },
    },
  };

  const submitHandler = async (input) => {
    setLoading(true);
    props.onSave && props.onSave();
    const {
      data: { createContent: response },
    } = await API.graphql(
      graphqlOperation(createContent, {
        input: {
          slug: data.slug ? data.slug : slugify(input.title, { lower: true }),
          type: input.type,
          title: input.title,
          body: input.body,
        },
      })
    );
    props.afterSave && props.afterSave(response);
    setLoading(false);
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: submitHandler,
        label: "Save",
      }}
    />
  );
}

export default ContentEntryForm;
