import React from "react";

import styles from "./Heading.module.scss";
import { cl } from "../../../helpers";

function Heading(props) {
  return React.createElement(
    props.tag,
    { className: cl(styles.heading, styles[props.type]) },
    props.label,
  );
}

export default Heading;
