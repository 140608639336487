import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import { Heading, Section, Loader, Label } from "../components/PageElements";

import { getContent } from "../graphql/queries";
import { NotificationContext } from "../context";
import { ContentEntryForm } from "../components/UI/Forms";

function ContentEditor() {
  const history = useHistory();
  let { content } = useParams();
  const notification = useContext(NotificationContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { getContent: data },
    } = await API.graphql(graphqlOperation(getContent, { slug: content }));
    setData(data);
    setLoading(false);
  }, [content]);

  useEffect(() => fetchData(), [fetchData]);

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Content Update" />
        <Section>
          <Loader size="140" label="Loading content entry..." />
        </Section>
      </Section>
    );
  }

  return (
    <Section type="screen">
      <Heading tag="h1" label="Page" />
      <Section type="form">
        <ContentEntryForm
          data={data}
          onSave={() => {
            history.push("/content");
            notification.quickConfirm("Saving...");
          }}
          afterSave={({ title, type }) => {
            notification.success(
              <>
                You successfully updated{" "}
                <Label value={`${title} ${type}`} uppercase={false} />.
              </>
            );
          }}
        />
      </Section>
    </Section>
  );
}

export default ContentEditor;
