import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { FormBuilder } from "../../FormElements";

function ResetPasswordForm(props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const fields = {
    code: {
      value: "",
      component: "text",
      props: {
        label: "Reset Code",
        instruction: "Enter Code (from email)",
      },
      validation: {
        required: true,
      },
    },
    email: {
      value: props.email,
      component: "text",
      props: {
        type: "email",
        label: "Email",
        instruction: "Enter Email",
      },
      validation: {
        required: true,
        email: true,
      },
    },
    password: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "New Password",
        instruction: "Choose Password",
        autoComplete: "new_password",
      },
      validation: {
        required: true,
        password: true,
      },
    },
    password_confirmation: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "Password Confirmation",
        instruction: "Repeat Password",
        autoComplete: "new_password",
      },
      validation: {
        required: true,
        same: "password",
      },
    },
  };

  const submitHandler = async (data) => {
    setErrors([]);
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
      props.afterSubmit(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors([e.message]);
      props.afterSubmit(data);
    }
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: submitHandler,
        label: "Reset Password",
      }}
      generalErrors={errors}
    />
  );
}

export default ResetPasswordForm;
