import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

import { ORDER_STATUS_LIST, TRANSPORT_OPTIONS } from "../config";
import { getOrder } from "../graphql/queries";
import { onUpdateOrder } from "../graphql/subscriptions";
import {
  Heading,
  Label,
  InlineSeparator,
  Section,
  Loader,
} from "../components/PageElements";
import { Button } from "../components/FormElements";
import { OrderEditor, OrderChangeLog } from "../components/UI/Widgets";
import { ChangeOrderStatusForm } from "../components/UI/Forms";
import {
  AppContext,
  ModalContext,
  OrganizationContext,
  NotificationContext,
} from "../context";

function Order() {
  const { replace } = useHistory();
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const modal = useContext(ModalContext);
  const notification = useContext(NotificationContext);
  const { order } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const changeStatus = (newStatus) =>
    modal.showForm(
      <ChangeOrderStatusForm
        order={data}
        newStatus={newStatus}
        onSave={() => {
          modal.hide();
          notification.quickConfirm(`Updating status...`);
        }}
        afterSave={() => notification.quickConfirm("Status updated")}
      />,
      `Change order #${data.number} status`
    );

  const openChangeLog = () => {
    modal.setSize("large");
    modal.setContent(<OrderChangeLog order_id={data.id} />);
    modal.show(`Order #${data.number} change log`);
  };

  const actionsMatrix = {
    DRAFT: {
      system_user: [
        {
          label: "Ready for client review",
          type: "button",
          callback: () => changeStatus("READY_FOR_CLIENT_REVIEW"),
        },
      ],
      client_user: [
        {
          label: "Item List Approved",
          type: "button",
          callback: () => changeStatus("APPROVED"),
        },
      ],
    },
    READY_FOR_CLIENT_REVIEW: {
      system_user: [
        {
          label: "Item List Approved",
          type: "button",
          callback: () => changeStatus("APPROVED"),
        },
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Item List Approved",
          type: "button",
          callback: () => changeStatus("APPROVED"),
        },
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    APPROVED: {
      system_user: [
        {
          label: "Manufacturing",
          type: "button",
          callback: () => changeStatus("MANUFACTURING"),
        },
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    MANUFACTURING: {
      system_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    AWAITING_TRANSIT: {
      system_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    PARTIALLY_IN_TRANSIT: {
      system_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    IN_TRANSIT: {
      system_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    PARTIALLY_DELIVERED: {
      system_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
    DELIVERED: {
      system_user: [
        {
          label: "Archive",
          type: "button",
          callback: () => changeStatus("ARCHIVE"),
        },
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
      client_user: [
        {
          label: "Archive",
          type: "button",
          callback: () => changeStatus("ARCHIVE"),
        },
        {
          label: "Change Log",
          type: "link",
          callback: () => openChangeLog(),
        },
      ],
    },
  };
  const userType = currentUser.is_system ? "system_user" : "client_user";
  const actions =
    actionsMatrix[data.status] && actionsMatrix[data.status][userType]
      ? actionsMatrix[data.status][userType]
      : null;

  const fetchData = useCallback(
    async (id) => {
      setLoading(true);
      const {
        data: { getOrder: data },
      } = await API.graphql(
        graphqlOperation(getOrder, {
          id,
        })
      );
      if (!data) {
        replace("/404");
      }
      setData(data);
      setLoading(false);
    },
    [replace]
  );

  useEffect(() => {
    if (order) fetchData(order);
  }, [order, fetchData]);

  useEffect(() => {
    const updateSubscription = API.graphql(
      graphqlOperation(onUpdateOrder)
    ).subscribe({
      next: ({
        value: {
          data: { onUpdateOrder: updated },
        },
      }) => {
        setData(updated);
      },
    });

    return () => {
      updateSubscription.unsubscribe();
    };
  }, [fetchData]);

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Update Order" />
        <Section>
          <Loader size="140" label="Loading order details..." />
        </Section>
      </Section>
    );
  }

  const orderStatus = ORDER_STATUS_LIST.find(
    (status) => status.value === data.status
  );

  return (
    <>
      <Section type="first">
        <Heading tag="h1" label={`Order #${data.number}`} />
        <div style={{ lineHeight: 2, marginBottom: "0.5rem" }}>
          {organization.is_system && (
            <>
              <span>
                Company: <Label value={data.company.name} uppercase={false} />
              </span>
              <InlineSeparator className="not-mobile" />
              <br className="not-tablet not-desktop" />
            </>
          )}
          {orderStatus && (
            <span>
              Status: <Label value={orderStatus.label} />
              <InlineSeparator className="not-mobile" />
              <br className="not-tablet not-desktop" />
            </span>
          )}
          <span>
            Created:{" "}
            <Label value={moment(data.createdAt).fromNow()} uppercase={false} />
          </span>
          <InlineSeparator className="not-mobile" />
          <br className="not-tablet not-desktop" />
          <span>
            Deliver via:{" "}
            <Label
              value={(() => {
                const orderTransport = TRANSPORT_OPTIONS.find(
                  (t) => t.value === data.transport
                );
                if (!orderTransport) {
                  return data.transport;
                }

                return orderTransport.label;
              })()}
            />
          </span>
        </div>
        {actions && (
          <div style={{ lineHeight: 2, marginBottom: "0.5rem" }}>
            {actions.map((action, key) => {
              const hidden =
                typeof action.condition !== "undefined" &&
                action.condition === false;

              if (hidden) {
                return null;
              }

              let nextKey = key + 1;
              let next = undefined;
              let hasNext = false;
              while (actions[nextKey] !== undefined) {
                next = actions[nextKey];
                hasNext =
                  next &&
                  (typeof next.condition === "undefined" || next.condition);

                if (hasNext) {
                  break;
                }

                nextKey++;
              }

              if (action.type === "link") {
                return (
                  <span key={`action-${key}`}>
                    <Button
                      label={action.label}
                      type="brandedLink"
                      labelSize="small"
                      callback={action.callback}
                    />
                    {hasNext && <InlineSeparator />}
                  </span>
                );
              }
              if (action.type === "button") {
                return (
                  <span key={`action-${key}`}>
                    <Button
                      label={action.label}
                      labelIcon="fas fa-angle-right"
                      rounded={true}
                      type="secondaryBranded"
                      labelSize="small"
                      callback={action.callback}
                    />
                    {hasNext && (
                      <>
                        <InlineSeparator className="not-mobile" />
                        <br className="not-tablet not-desktop" />
                      </>
                    )}
                  </span>
                );
              }

              return null;
            })}
          </div>
        )}
      </Section>
      <OrderEditor order={data} status={orderStatus} />
    </>
  );
}

export default Order;
