import React from "react";

import styles from "./Button.module.scss";
import { cl } from "../../../helpers";
import { Label, Icon } from "../../PageElements";

function Button(props) {
  const rounded = props.rounded ? styles.rounded : null;
  const animatedHover = !props.disableAnimation ? styles.animated : null;
  const reverseAnimation = props.reverseAnimation
    ? styles.reverseAnimation
    : null;

  return (
    <button
      className={cl(
        styles.btn,
        styles[props.type],
        rounded,
        animatedHover,
        reverseAnimation,
        props.className,
        props.fullWidth ? styles.fullWidth : null,
        props.disabled ? styles.disabled : null
      )}
      disabled={props.disabled}
      onClick={props.callback}
      style={props.style}
      title={props.title}
    >
      {props.label || props.labelIcon ? (
        <Label
          value={props.label}
          size={props.labelSize}
          type={props.labelType}
          icon={props.labelIcon}
          className={props.labelClassName}
          {...props.labelProps}
        />
      ) : null}
      {props.children}
      {props.icon ? (
        <Icon icon={props.icon} counter={props.iconCounter} size="lg" />
      ) : null}
    </button>
  );
}

export default Button;
