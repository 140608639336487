import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import navigationConfig from "../data/navigation.json";

export const NavigatorContext = React.createContext();

function NavigatorContextProvider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [level, setLevel] = useState(undefined);

  const init = (level) => setLevel(level);
  const getConfig = () => {
    if (!level) {
      console.warn(`Navigator is not configured.`);
      return navigationConfig._schema;
    }

    if (!navigationConfig[level]) {
      console.warn(
        `Navigator is not configured properly There is no ${level} level in config file.`
      );
      return navigationConfig._schema;
    }

    return navigationConfig[level];
  };
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen((isOpen) => !isOpen);

  const history = useHistory();
  useEffect(() => {
    history.listen(() => {
      if (isOpen) {
        close();
      }
    });
  }, [history, isOpen]);

  return (
    <NavigatorContext.Provider
      value={{ init, getConfig, isOpen, open, close, toggle }}
    >
      {props.children}
    </NavigatorContext.Provider>
  );
}

export default NavigatorContextProvider;
