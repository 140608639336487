import React, { useState, useCallback } from "react";

export const ModalContext = React.createContext();

function ModalContextProvider(props) {
  const [isShown, setIsShown] = useState(false);
  const [title, setTitle] = useState(null);
  const [actions, setActions] = useState(null);
  const [content, setContent] = useState(null);
  const [body, setBody] = useState(null);
  const [shouldCloseOnEsc, setShouldCloseOnEsc] = useState(true);
  const [shouldCloseOnBackdropClick, setCloseOnBackdropClick] = useState(true);
  const [hasCloseButton, setHasCloseButton] = useState(true);
  const [backdropClassName, setBackdropClassName] = useState(null);
  const [containerClassName, setContainerClassName] = useState(null);
  const [dialogClassName, setDialogClassName] = useState(null);
  const [size, setSize] = useState(null);
  const [contentClassName, setContentClassName] = useState(null);
  const [headerClassName, setHeaderClassName] = useState(null);
  const [bodyClassName, setBodyClassName] = useState(null);
  const [actionsClassName, setActionsClassName] = useState(null);

  const reset = useCallback(() => {
    setTitle(null);
    setActions(null);
    setContent(null);
    setBody(null);
    setShouldCloseOnEsc(true);
    setCloseOnBackdropClick(true);
    setHasCloseButton(true);
    setBackdropClassName(null);
    setContainerClassName(null);
    setDialogClassName(null);
    setSize(null);
    setContentClassName(null);
    setHeaderClassName(null);
    setBodyClassName(null);
    setActionsClassName(null);
  }, []);

  const show = useCallback((title = null) => {
    setIsShown(true);
    setTitle(title);
  }, []);

  const showForm = useCallback((form, title = null) => {
    setIsShown(true);
    setTitle(title);
    setShouldCloseOnEsc(false);
    setCloseOnBackdropClick(false);
    setContent(form);
  }, []);

  const hide = useCallback(() => {
    setIsShown(false);
    reset();
  }, [reset]);

  const addAction = useCallback((action) => {
    setActions((currentActions) => {
      if (!currentActions) {
        currentActions = [];
      }

      return [...currentActions, action];
    });
  }, []);

  const confirm = useCallback(
    (callerOptions = {}) => {
      const defaultOptions = {
        title: null,
        onConfirm: () => {},
        confirmLabel: "Yes",
        confirmLabelIcon: null,
        confirmIcon: null,
        closeAfterConfirmation: true,
        onCancel: () => {},
        cancelLabel: "Cancel",
        cancelLabelIcon: null,
        cancelIcon: null,
      };
      const options = { ...defaultOptions, ...callerOptions };

      show(options.title);
      setShouldCloseOnEsc(false);
      setCloseOnBackdropClick(false);
      setHasCloseButton(false);
      setSize("small");
      setActions([
        {
          label: options.cancelLabel,
          labelIcon: options.cancelLabelIcon,
          icon: options.cancelIcon,
          buttonType: "secondaryBranded",
          callback: () => {
            options.onCancel();
            hide();
          },
        },
        {
          label: options.confirmLabel,
          labelIcon: options.confirmLabelIcon,
          icon: options.confirmIcon,
          callback: async () => {
            await options.onConfirm();
            if (options.closeAfterConfirmation) {
              hide();
            }
          },
        },
      ]);
      setBackdropClassName("confirmBackdrop");
    },
    [
      show,
      hide,
      setShouldCloseOnEsc,
      setCloseOnBackdropClick,
      setHasCloseButton,
      setSize,
      setActions,
    ]
  );

  return (
    <ModalContext.Provider
      value={{
        isShown,
        show,
        showForm,
        hide,
        reset,
        confirm,
        setTitle,
        title,
        actions,
        addAction,
        setActions,
        content,
        setContent,
        body,
        setBody,
        shouldCloseOnEsc,
        setShouldCloseOnEsc,
        shouldCloseOnBackdropClick,
        setCloseOnBackdropClick,
        hasCloseButton,
        setHasCloseButton,
        backdropClassName,
        setBackdropClassName,
        containerClassName,
        setContainerClassName,
        dialogClassName,
        setDialogClassName,
        size,
        setSize,
        contentClassName,
        setContentClassName,
        headerClassName,
        setHeaderClassName,
        bodyClassName,
        setBodyClassName,
        actionsClassName,
        setActionsClassName,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalContextProvider;
