import React, { useEffect, useState, useCallback } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";

import { getCompany } from "../../../graphql/queries";
import { Table } from "../../PageElements";

function SystemUserCompaniesForm(props) {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);

  async function listGroupsForUser(username) {
    let apiName = "AdminQueries";
    let path = `/listGroupsForUser`;
    let myInit = {
      queryStringParameters: {
        username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.get(apiName, path, myInit);
  }

  const fetchCompany = useCallback(async (id) => {
    const data = await API.graphql(graphqlOperation(getCompany, { id }));
    return data.data.getCompany;
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { Groups } = await listGroupsForUser(user.id);
      const companies = Groups.map((group) => group.GroupName).filter(
        (userGroup) =>
          userGroup !== "Root" &&
          userGroup !== "Admin" &&
          userGroup !== "Cashier" &&
          userGroup !== "CompanyAdmin" &&
          userGroup !== "CompanyMember"
      );
      const data = [];
      await Promise.all(
        companies.map(async (id) => {
          const company = await fetchCompany(id);
          if (company && !company.is_system) {
            data.push(company);
          }
        })
      );
      setUserCompanies(data);
      setLoading(false);
    })();
  }, [user, fetchCompany]);

  const columns = [
    {
      key: "number",
      label: "ID",
      type: "label",
      isSortable: false,
    },
    { key: "name", label: "Name", type: "label", isSortable: false },
  ];

  const actions = [
    {
      label: "Detach",
      callback: (row) => props.onDetach(row),
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      mainColumn="number"
      data={userCompanies}
      actions={actions}
    />
  );
}

export default SystemUserCompaniesForm;
