import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
  getCorrespondencesByType,
  getCorrespondenceMessages,
} from "../graphql/queries";
import { deleteCorrespondence, deleteMessage } from "../graphql/mutations";
import { OrganizationContext } from "../context";
import { Heading, Section, Table } from "../components/PageElements";
import {
  onCreateCorrespondence,
  onDeleteCorrespondence,
} from "../graphql/subscriptions";

function Inquiries() {
  const organization = useContext(OrganizationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);

  const fetchData = async (next = null) => {
    setLoading(true);
    if (!next) {
      setData([]);
    }
    const {
      data: {
        getCorrespondencesByType: { items, nextToken },
      },
    } = await API.graphql(
      graphqlOperation(getCorrespondencesByType, {
        type: "INQUIRY",
        sortDirection: "DESC",
        limit: 25,
        nextToken: next,
      })
    );
    setData((currentItems) => {
      const newItems = [...currentItems];
      newItems.push(...items);
      return newItems;
    });
    setNext(nextToken);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateCorrespondence)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteCorrespondence)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteCorrespondence: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (correspondence) => correspondence.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, []);

  const columns = [
    { key: "title", label: "Title", type: "text" },
    { key: "sender.name", label: "Sender", type: "label" },
    { key: "createdAt", label: "Created", type: "date", isSortable: true },
  ];

  if (organization.is_system) {
    columns.push({
      key: "client",
      label: "Client",
      type: "label",
      isSortable: true,
    });
  }
  const actions = [
    { label: "Open", to: ({ id }) => `/inquiries/${id}` },
    { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ id }) => {
        const {
          data: { getCorrespondenceMessages: messages },
        } = await API.graphql(
          graphqlOperation(getCorrespondenceMessages, {
            correspondence_id: id,
          })
        );

        await Promise.all(
          messages.items.map(async (message) => {
            await API.graphql(
              graphqlOperation(deleteMessage, { input: { id: message.id } })
            );
          })
        );

        await API.graphql(
          graphqlOperation(deleteCorrespondence, { input: { id } })
        );

        return { success: true };
      },
    },
  ];

  return (
    <Section type="screen">
      <Heading tag="h1" label="Inquiries" />
      <Table
        loading={loading}
        hasNext={!!next}
        onNext={{
          label: "Load More Inquiries...",
          handler: () => fetchData(next),
        }}
        columns={columns}
        mainColumn="title"
        data={data}
        actions={actions}
      />
    </Section>
  );
}

export default Inquiries;
