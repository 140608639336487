import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";

import { AppContext, NotificationContext } from "../../../context";
import { FormBuilder } from "../../FormElements";

function SettingsForm() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { currentUser, reinit } = useContext(AppContext);
  const notification = useContext(NotificationContext);
  const fields = {
    given_name: {
      value: currentUser.given_name || "",
      component: "text",
      props: {
        label: "First Name",
        instruction: "Enter First Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
    family_name: {
      value: currentUser.family_name || "",
      component: "text",
      props: {
        label: "Last Name",
        instruction: "Enter Last Name",
      },
      validation: {
        required: true,
        disallowSpecialChars: true,
      },
    },
  };

  const submitHandler = async (data) => {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      Auth.updateUserAttributes(user, data).then(() => {
        notification.success("Profile details updated.");
        reinit();
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      setErrors([e.message]);
    }
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: submitHandler,
        label: "Save",
      }}
      generalErrors={errors}
    />
  );
}

export default SettingsForm;
