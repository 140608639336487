import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { DEFAULT_DATE_FORMAT } from "../../../config";
import { notificationsForReceiver } from "../../../graphql/queries";
import {
  AppContext,
  NavigatorContext,
  ModalContext,
  OrganizationContext,
  OverlayContext,
  NotificationContext,
} from "../../../context";
import styles from "./Header.module.scss";
import { cl } from "../../../helpers";
import { Button } from "../../FormElements";
import {
  InviteUserForm,
  CreateOrderForm,
  AddToCompanyLedgerForm,
  NewCompanyForm,
  NewInquiryForm,
  ExchangeRate,
  ContentEntryForm,
} from "../../UI/Forms";
import { CompanyFinderWidget } from "../../UI/Widgets";
import { Action, Options, InlineSeparator, Label } from "../../PageElements";
import { SignOutTransition } from "../../UI/Transitions";

function Header() {
  const history = useHistory();
  const optionsRef = useRef();
  const navigator = useContext(NavigatorContext);
  const nav = navigator.getConfig();
  const { reinit, currentUser } = useContext(AppContext);
  const modal = useContext(ModalContext);
  const overlay = useContext(OverlayContext);
  const { organization } = useContext(OrganizationContext);
  const orgId = organization.id;
  const notification = useContext(NotificationContext);
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [optionsTitle, setOptionsTitle] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [countUnreadNotifications, setCountUnreadNotifications] = useState(0);
  const currentUserRole = currentUser.role.key.toUpperCase();

  React.useEffect(() => {
    const checkerInterval = setInterval(() => {
      API.graphql(
        graphqlOperation(notificationsForReceiver, {
          receiver: orgId,
          createdAt: { ge: localStorage.getItem("ltrn") },
          sortDirection: "DESC",
        })
      ).then(({ data: { notificationsForReceiver: data } }) => {
        const unreadNotifications = data.items.length;
        setCountUnreadNotifications(unreadNotifications);
        if (unreadNotifications > localStorage.getItem("unc")) {
          notification.info(
            `You have ${unreadNotifications} unread notifications.`,
            `New Notification`
          );
        }
        localStorage.setItem("unc", unreadNotifications);
      });
    }, 30 * 1000);

    return () => {
      clearInterval(checkerInterval);
    };
  }, [orgId, notification]);

  const toggleOptions = useCallback(
    (optionsKey) => {
      setOptionsOpened((currentlyOpened) => {
        if (currentlyOpened === optionsKey) {
          return null;
        }

        return optionsKey;
      });
    },
    [setOptionsOpened]
  );

  const optionsWidgets = useMemo(() => {
    return {
      notifications: () => {
        toggleOptions("notifications");
        setOptionsTitle("Notifications");
        setOptionsList([
          {
            label: "Loading notifications...",
            labelProps: { uppercase: false },
            icon: "fas fa-spinner",
          },
        ]);
        if (optionsOpened !== "notifications") {
          API.graphql(
            graphqlOperation(notificationsForReceiver, {
              receiver: organization.id,
              sortDirection: "DESC",
            })
          ).then(({ data: { notificationsForReceiver: data } }) => {
            localStorage.setItem("ltrn", moment().utc().format());
            setCountUnreadNotifications(0);
            localStorage.setItem("unc", 0);
            if (!data.items || !data.items.length) {
              setOptionsList([
                {
                  label: "No notifications yet!",
                  labelProps: { uppercase: false },
                  icon: "fas fa-envelope-open",
                },
              ]);
              return;
            }

            const notifications = [];
            data.items.forEach((item) => {
              notifications.push({
                label: item.title,
                descriptor: (
                  <>
                    {moment(item.createdAt).fromNow()}
                    <InlineSeparator />
                    {item.content}
                  </>
                ),
                icon: item.icon,
                to: item.to,
              });
            });
            setOptionsList(notifications);
          });
        }
      },
    };
  }, [
    toggleOptions,
    setOptionsTitle,
    setOptionsList,
    optionsOpened,
    organization,
  ]);

  const actionWidgets = useMemo(
    () => ({
      logout: () => ({
        label: "Logout",
        icon: "fas fa-sign-out-alt",
        callback: async () => {
          history.push("/");
          const transitionDuration = 1000;
          overlay.transition(
            <SignOutTransition duration={transitionDuration} />,
            transitionDuration
          );
          await Auth.signOut();
          reinit();
        },
      }),
      profile: () => {
        return {
          label: "Company",
          descriptor: "Manage your company profile",
          icon: "fas fa-person-booth",
          to: "/profile",
        };
      },
    }),
    [overlay, history, reinit]
  );

  const actionModals = useMemo(
    () => ({
      newUser: () => {
        modal.setBody(
          <CompanyFinderWidget
            isSystem={currentUserRole !== "ROOT" ? false : undefined}
            onSelect={(company) => {
              modal.hide();
              modal.showForm(
                <InviteUserForm
                  data={{ company }}
                  beforeSubmit={() => modal.hide()}
                  onStore={(data) => {
                    notification.success(
                      <>
                        You successfully invited{" "}
                        <Label value={data.email} uppercase={false} /> to{" "}
                        <Label value={company.name} uppercase={false} />{" "}
                        company.
                      </>
                    );
                  }}
                />,
                "Invite User"
              );
            }}
          />
        );
        modal.setHasCloseButton(false);
        modal.show();
      },
      newCompanyUser: () => {
        modal.showForm(
          <InviteUserForm
            data={{ company: organization }}
            beforeSubmit={() => modal.hide()}
            onStore={(data) => {
              notification.success(
                <>
                  You successfully invited{" "}
                  <Label value={data.email} uppercase={false} /> to the company.
                </>
              );
            }}
          />,
          "Invite User"
        );
      },
      newLedgerEntry: () => {
        modal.setBody(
          <CompanyFinderWidget
            onSelect={(company) => {
              modal.hide();
              modal.setSize("large");
              modal.setShouldCloseOnEsc(false);
              modal.setCloseOnBackdropClick(false);
              modal.setBody(
                <AddToCompanyLedgerForm
                  company={company}
                  onSuccess={() => {
                    modal.hide();
                    notification.success(
                      `You successfully added to the ${company.name} ledger.`
                    );
                  }}
                />
              );
              modal.show(`Add to ${company.name} ledger`);
            }}
          />
        );
        modal.setHasCloseButton(false);
        modal.show();
      },
      newRate: () => {
        modal.showForm(
          <ExchangeRate
            onSave={() => {
              modal.hide();
            }}
            afterSave={({ date }) => {
              notification.success(
                <>
                  You successfully added exchange rate for{" "}
                  <Label value={moment(date).format(DEFAULT_DATE_FORMAT)} />.
                </>
              );
            }}
          />,
          `Add Exchange Rates`
        );
      },
      newContent: () => {
        modal.setSize("large");
        modal.showForm(
          <ContentEntryForm
            onSave={() => {
              modal.hide();
              notification.quickConfirm("Saving...");
            }}
            afterSave={(data) => {
              notification.success(
                <>
                  You successfully added {data.title} {data.type} content.
                </>
              );
            }}
          />,
          `Add Content Entry`
        );
      },
      newCompany: () => {
        let saveTracker;
        modal.showForm(
          <NewCompanyForm
            beforeSubmit={() => {
              saveTracker = notification.startProgress("Creating company...");
              modal.hide();
            }}
            onStore={(data) => {
              notification.updateProgress(
                saveTracker,
                100,
                <>
                  You successfully created <Label value={data.company} />{" "}
                  company.
                </>
              );
              history.push(`/companies/edit/${data.id}`);
            }}
          />,
          "Create Company"
        );
      },
      newOrder: () => {
        modal.setBody(
          <CompanyFinderWidget
            isSystem={false}
            onSelect={(company) => {
              modal.hide();
              modal.showForm(
                <CreateOrderForm
                  data={{ company }}
                  onSave={() => {
                    modal.hide();
                    notification.quickConfirm("Creating...");
                  }}
                  afterSave={(data) => {
                    history.push(`/orders/${data.id}`);
                    notification.success("You successfully created an order.");
                  }}
                />,
                "Add new order"
              );
            }}
          />
        );
        modal.setHasCloseButton(false);
        modal.show();
      },
      newCompanyOrder: () => {
        modal.showForm(
          <CreateOrderForm
            data={{ company: organization }}
            onSave={() => {
              modal.hide();
              notification.quickConfirm("Creating...");
            }}
            afterSave={(data) => {
              history.push(`/orders/${data.id}`);
              notification.success(
                "You successfully created an order. Now you can add items to it."
              );
            }}
          />,
          "Add new order"
        );
      },
      newEmployee: () => {
        modal.setHasCloseButton(false);
        modal.setShouldCloseOnEsc(false);
        modal.setCloseOnBackdropClick(false);
        modal.setContent(<div>Add new employee form</div>);
        modal.addAction({
          label: "Cancel",
          buttonType: "secondaryBranded",
          callback: () => modal.hide(),
        });
        modal.addAction({
          label: "Save",
          callback: () => {
            modal.hide();
            notification.success("You successfully invited a new employee");
          },
        });
        modal.show("Add new employee");
      },
      newInquiry: () => {
        let saveTracker;
        modal.showForm(
          <NewInquiryForm
            onSave={() => {
              modal.hide();
              saveTracker = notification.startProgress(
                `Sending your inquiry...`
              );
            }}
            afterSave={(_inquiry) => {
              notification.updateProgress(
                saveTracker,
                100,
                `Your inquiry is send.`
              );
              history.push(`/inquiries/${_inquiry.id}`);
            }}
          />,
          "New Inquiry"
        );
      },
    }),
    [modal, history, notification, organization, currentUserRole]
  );

  const triggerCallbackHandler = useCallback(
    (trigger) => () => {
      if (trigger.widget && optionsWidgets[trigger.widget]) {
        optionsWidgets[trigger.widget]();
        return;
      }

      toggleOptions(trigger.key);
      setOptionsTitle(trigger.title || null);
      if (trigger.actions && trigger.actions.length) {
        const optionsList = [];
        trigger.actions.forEach((action) => {
          if (action.widget && actionWidgets[action.widget]) {
            optionsList.push(actionWidgets[action.widget](trigger));
            return;
          }
          if (action.modal && actionModals[action.modal]) {
            optionsList.push({
              callback: () => {
                toggleOptions(trigger.key);
                return actionModals[action.modal]();
              },
              ...action,
            });
            return;
          }

          return optionsList.push(action);
        });
        setOptionsList(optionsList);
        return;
      }
    },
    [toggleOptions, actionWidgets, optionsWidgets, actionModals]
  );

  return (
    <header className={styles.header}>
      <div className={styles.navToggleContainer}>
        <Button
          icon={faBars}
          callback={() => {
            navigator.toggle();
          }}
          className={cl(styles.navToggle)}
          type="primaryBranded"
          rounded={true}
        />
      </div>
      <div className={styles.actionsContainer}>
        <Action
          to="/settings"
          label={`${currentUser.given_name} ${currentUser.family_name} (${currentUser.email})`}
          descriptor={`${organization.name} ${
            currentUser.role ? `/  ${currentUser.role.value}` : ""
          }`}
          labelProps={{ uppercase: false }}
          containerClassName={cl(styles.profileTrigger, "not-mobile")}
          wrapperClassName={styles.profileTriggerWrapper}
        />
        <div className={styles.triggersContainer} ref={optionsRef}>
          <Options
            show={optionsOpened}
            onClose={() => toggleOptions(null)}
            title={optionsTitle}
            options={optionsList}
            containerRef={optionsRef}
          />
          {nav.appHeader.optionsTriggers.map((btn, key) => {
            return (
              <Button
                key={`option-trigger-${key}`}
                icon={btn.icon}
                iconCounter={
                  btn.widget === "notifications"
                    ? countUnreadNotifications
                    : null
                }
                callback={triggerCallbackHandler(btn)}
                type="primaryBranded"
                rounded={true}
              />
            );
          })}
        </div>
      </div>
    </header>
  );
}

export default Header;
