import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { API } from "aws-amplify";

import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { getContentByType } from "../../../graphql/queries";
import styles from "./Authentication.module.scss";
import Signin from "../../../screens/Signin";
import Signup from "../../../screens/Signup";
import ResetPassword from "../../../screens/ResetPassword";
import Page from "../../../screens/Page";
import { Link, InlineSeparator } from "../../PageElements";
import PageNotFound from "../../../screens/PageNotFound";

function Authentication() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const {
      data: {
        getContentByType: { items },
      },
    } = await API.graphql({
      query: getContentByType,
      authMode: "AWS_IAM",
      variables: {
        type: "PAGE",
        sortDirection: "DESC",
      },
    });
    setData(items);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  return (
    <section className={styles.mainContainer}>
      <section className={styles.introContainer}>
        <div className={styles.introMask}></div>
        <div className={styles.intro}>
          <h2>Welcome to</h2>
          <h1>Logistics China</h1>
          <h4>We Deliver</h4>
        </div>
      </section>
      <section className={styles.contentContainer}>
        <div className={styles.header}>
          <Link to="/">
            <Logo width="120" />
          </Link>
        </div>
        <div className={styles.content}>
          <Switch>
            <Route path="/" exact component={Signin} />
            <Route path="/signup/:code" component={Signup} />
            <Route path="/password-reset" component={ResetPassword} />
            <Route path="/page/:page" component={Page} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
        {data.length > 0 && (
          <div className={styles.footer}>
            {data.map((page, key) => (
              <span key={`page-${page.slug}`}>
                <Link
                  to={`/page/${page.slug}`}
                  labelSize="small"
                  label={page.title}
                />
                {key + 1 < data.length && (
                  <InlineSeparator className={styles.separator} />
                )}
              </span>
            ))}
          </div>
        )}
      </section>
    </section>
  );
}

export default Authentication;
