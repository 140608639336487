import React, { useContext } from "react";

import { NavigatorContext } from "../context";
import { Section, WidgetHub } from "../components/PageElements";

function DashboardScreen() {
  const navigator = useContext(NavigatorContext);
  const { dashboard } = navigator.getConfig();

  return (
    <Section type="screen">
      <WidgetHub widgets={dashboard.items} />
    </Section>
  );
}

export default DashboardScreen;
