import React, { useState } from "react";
import PropTypes from "prop-types";

import { Label } from "../../../PageElements";
import { Button } from "../../";
import formStyles from "../../FormElement.module.scss";
import { cl } from "../../../../helpers";

Input.propTypes = {
  nested: PropTypes.bool.isRequired,
};

Input.defaultProps = {
  nested: true,
};

function Input(props) {
  const required = props.required ? "*" : "";
  const [focused, setFocused] = useState(false);
  const [type, setType] = useState(props.type);
  const filled =
    props.value !== null && props.value !== undefined && props.value !== ""
      ? true
      : false;
  const placeholder = props.placeholder ?? props.label;
  const instruction = props.instruction ?? props.label;
  const hasErrors = props.errors && props.errors.length;
  const hasErrorClass =
    props.errors && props.errors.length ? formStyles.error : "";

  let labelValue =
    type === "currency" ? props.label : `${props.label}${required}`;

  if (focused) {
    labelValue = instruction;
  } else {
    if (!filled) {
      if (typeof placeholder === "object") {
        labelValue = placeholder;
      } else {
        labelValue = `${required} ${placeholder}`;
      }
    }
  }

  const errors = hasErrors
    ? props.errors.map((error, index) => {
        if (!error.error) {
          return null;
        }

        return (
          <span className={formStyles.error} key={`${error.field}_${index}`}>
            {error.error}
          </span>
        );
      })
    : null;

  let inputType = type ?? "text";
  if (type === "currency") {
    inputType = "number";
  }

  return (
    <div
      className={cl(
        formStyles.wrapper,
        props.nested ? formStyles.nested : null,
        props.wrapperClassName
      )}
    >
      <div
        className={cl(
          formStyles.container,
          formStyles[type],
          props.branded ? formStyles.branded : null,
          props.disabled ? formStyles.disabled : null,
          props.readonly ? formStyles.readonly : null,
          props.containerClassName,
          hasErrorClass
        )}
      >
        <Label
          value={labelValue}
          containerClassName={cl(
            formStyles.labelContainer,
            filled ? formStyles.filled : null,
            focused ? formStyles.focused : null
          )}
        />
        {(focused || filled) && props.type === "currency" && (
          <div className={formStyles.currencySymbol}>¥</div>
        )}
        {props.type === "password" && (
          <Button
            type="basic"
            icon={type === "password" ? "fas fa-eye-slash" : "fas fa-eye"}
            callback={(e) => {
              e.preventDefault();
              setType((type) => (type === "password" ? "text" : "password"));
            }}
            className={formStyles.inputIcon}
          />
        )}
        <input
          type={inputType}
          className={cl(
            formStyles.input,
            props.className,
            type === "currency" ? formStyles.currencyInput : null
          )}
          name={props.name}
          value={props.value}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          readOnly={props.readonly}
          onChange={props.onChange}
          tabIndex={props.readonly ? -1 : 1}
          autoComplete={props.autoComplete}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
        />
        <div className={formStyles.underline}></div>
      </div>
      <div className={formStyles.errorsContainer}>{errors}</div>
    </div>
  );
}

export default Input;
