import React, { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { getContentByType } from "../../../graphql/queries";
import { deleteContent } from "../../../graphql/mutations";
import {
  onCreateContent,
  onDeleteContent,
} from "../../../graphql/subscriptions";
import { Table } from "../../PageElements";

function ContentTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);

  const { type } = props;

  const fetchData = useCallback(
    async (next = null) => {
      setLoading(true);
      if (!next) {
        setData([]);
      }
      const {
        data: {
          getContentByType: { items, nextToken },
        },
      } = await API.graphql(
        graphqlOperation(getContentByType, {
          type: type,
          limit: 25,
          nextToken: next,
          sortDirection: "DESC",
        })
      );
      if (next) {
        setData((currentItems) => {
          const newItems = [...currentItems];
          newItems.push(...items);
          return newItems;
        });
      } else {
        setData(items);
      }
      setNext(nextToken);
      setLoading(false);
    },
    [type]
  );

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, [fetchData]);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateContent)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteContent)
    ).subscribe({
      next: () => fetchData(),
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, [fetchData]);

  const columns = [
    { key: "title", label: "Title", type: "label" },
    { key: "createdAt", label: "Created", type: "date" },
  ];

  const actions = [
    {
      label: "Edit",
      to: ({ slug }) => `/content/edit/${slug}`,
    },
    { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ slug }) => {
        await API.graphql(graphqlOperation(deleteContent, { input: { slug } }));
        return { success: true };
      },
    },
  ];

  return (
    <Table
      loading={loading}
      hasNext={!!next}
      onNext={{
        label: "Load More Content...",
        handler: () => fetchData(next),
      }}
      columns={columns}
      mainColumn="title"
      data={data}
      actions={actions}
    />
  );
}

export default ContentTable;
