import React, { useContext } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";

import { createMessage } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";
import { AppContext, OrganizationContext } from "../../../context";

function NewInquiryForm(props) {
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const { correspondence } = props;

  const data = props.data ?? [];
  const errors = [];

  const fields = {
    message: {
      value: data.message ?? "",
      component: "textarea",
      props: {
        label: "Message",
        instruction: "Enter Your Message",
      },
      validation: {
        required: true,
      },
    },
    attachments: {
      value: data.attachments ?? "",
      component: "upload",
      props: {
        label: "Attachments",
        instruction: "Attach files",
      },
      validation: {},
    },
  };

  const submitHandler = async (data) => {
    props.onSave(data);
    const hasFiles = !!data.attachments;
    const author = {
      id: currentUser.sub,
      name: currentUser.fullname,
      company: organization.name,
    };

    const {
      data: { createMessage: newMessage },
    } = await API.graphql(
      graphqlOperation(createMessage, {
        input: {
          group_id: correspondence.group_id,
          correspondence_id: correspondence.id,
          sender: author,
          text: data.message,
          vars: JSON.stringify({ hasFiles }),
        },
      })
    );

    if (hasFiles) {
      await Promise.all(
        Array.from(data.attachments).map(async (_file) => {
          try {
            await Storage.put(
              `/inquiries/${correspondence.id}/${newMessage.id}/${_file.name}`,
              _file
            );
          } catch (error) {
            console.error("Error uploading file: ", error);
          }
        })
      );
    }

    props.afterSave(newMessage);
  };

  return (
    <FormBuilder
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Reply to Inquiry",
      }}
      generalErrors={errors}
    />
  );
}

export default NewInquiryForm;
