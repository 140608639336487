/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendInvite = /* GraphQL */ `
  mutation SendInvite($id: ID!) {
    sendInvite(id: $id)
  }
`;
export const createCompanyGroup = /* GraphQL */ `
  mutation CreateCompanyGroup($company: ID!) {
    createCompanyGroup(company: $company)
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const createLedgerEntry = /* GraphQL */ `
  mutation CreateLedgerEntry(
    $input: CreateLedgerEntryInput!
    $condition: ModelLedgerEntryConditionInput
  ) {
    createLedgerEntry(input: $input, condition: $condition) {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateLedgerEntry = /* GraphQL */ `
  mutation UpdateLedgerEntry(
    $input: UpdateLedgerEntryInput!
    $condition: ModelLedgerEntryConditionInput
  ) {
    updateLedgerEntry(input: $input, condition: $condition) {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteLedgerEntry = /* GraphQL */ `
  mutation DeleteLedgerEntry(
    $input: DeleteLedgerEntryInput!
    $condition: ModelLedgerEntryConditionInput
  ) {
    deleteLedgerEntry(input: $input, condition: $condition) {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRate = /* GraphQL */ `
  mutation CreateRate(
    $input: CreateRateInput!
    $condition: ModelRateConditionInput
  ) {
    createRate(input: $input, condition: $condition) {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const updateRate = /* GraphQL */ `
  mutation UpdateRate(
    $input: UpdateRateInput!
    $condition: ModelRateConditionInput
  ) {
    updateRate(input: $input, condition: $condition) {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const deleteRate = /* GraphQL */ `
  mutation DeleteRate(
    $input: DeleteRateInput!
    $condition: ModelRateConditionInput
  ) {
    deleteRate(input: $input, condition: $condition) {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const createCorrespondence = /* GraphQL */ `
  mutation CreateCorrespondence(
    $input: CreateCorrespondenceInput!
    $condition: ModelCorrespondenceConditionInput
  ) {
    createCorrespondence(input: $input, condition: $condition) {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const updateCorrespondence = /* GraphQL */ `
  mutation UpdateCorrespondence(
    $input: UpdateCorrespondenceInput!
    $condition: ModelCorrespondenceConditionInput
  ) {
    updateCorrespondence(input: $input, condition: $condition) {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const deleteCorrespondence = /* GraphQL */ `
  mutation DeleteCorrespondence(
    $input: DeleteCorrespondenceInput!
    $condition: ModelCorrespondenceConditionInput
  ) {
    deleteCorrespondence(input: $input, condition: $condition) {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createOrderContainer = /* GraphQL */ `
  mutation CreateOrderContainer(
    $input: CreateOrderContainerInput!
    $condition: ModelOrderContainerConditionInput
  ) {
    createOrderContainer(input: $input, condition: $condition) {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrderContainer = /* GraphQL */ `
  mutation UpdateOrderContainer(
    $input: UpdateOrderContainerInput!
    $condition: ModelOrderContainerConditionInput
  ) {
    updateOrderContainer(input: $input, condition: $condition) {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrderContainer = /* GraphQL */ `
  mutation DeleteOrderContainer(
    $input: DeleteOrderContainerInput!
    $condition: ModelOrderContainerConditionInput
  ) {
    deleteOrderContainer(input: $input, condition: $condition) {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createContainerItem = /* GraphQL */ `
  mutation CreateContainerItem(
    $input: CreateContainerItemInput!
    $condition: ModelContainerItemConditionInput
  ) {
    createContainerItem(input: $input, condition: $condition) {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const updateContainerItem = /* GraphQL */ `
  mutation UpdateContainerItem(
    $input: UpdateContainerItemInput!
    $condition: ModelContainerItemConditionInput
  ) {
    updateContainerItem(input: $input, condition: $condition) {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const deleteContainerItem = /* GraphQL */ `
  mutation DeleteContainerItem(
    $input: DeleteContainerItemInput!
    $condition: ModelContainerItemConditionInput
  ) {
    deleteContainerItem(input: $input, condition: $condition) {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const createContainerAccessory = /* GraphQL */ `
  mutation CreateContainerAccessory(
    $input: CreateContainerAccessoryInput!
    $condition: ModelContainerAccessoryConditionInput
  ) {
    createContainerAccessory(input: $input, condition: $condition) {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const updateContainerAccessory = /* GraphQL */ `
  mutation UpdateContainerAccessory(
    $input: UpdateContainerAccessoryInput!
    $condition: ModelContainerAccessoryConditionInput
  ) {
    updateContainerAccessory(input: $input, condition: $condition) {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const deleteContainerAccessory = /* GraphQL */ `
  mutation DeleteContainerAccessory(
    $input: DeleteContainerAccessoryInput!
    $condition: ModelContainerAccessoryConditionInput
  ) {
    deleteContainerAccessory(input: $input, condition: $condition) {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
