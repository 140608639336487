import React, { useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Link as NativeLink } from "react-router-dom";
import { matchPath } from "react-router";

import {
  NavigatorContext,
  ModalContext,
  NotificationContext,
} from "../../../context";
import styles from "./Navigator.module.scss";
import { cl } from "../../../helpers";
import { Button } from "../../FormElements";
import { Link } from "../../PageElements";
import { NewInquiryForm } from "../../UI/Forms";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

function Navigator({ location }) {
  const history = useHistory();
  const notification = useContext(NotificationContext);
  const navigator = useContext(NavigatorContext);
  const nav = navigator.getConfig();
  const modal = useContext(ModalContext);

  const navItems = nav.appNavigator.items || [];

  const widgets = {
    newInquiry: () => (
      <Button
        label="New Inquiry"
        labelSize="large"
        type="primaryBranded"
        callback={() => {
          let saveTracker;
          navigator.close();
          modal.showForm(
            <NewInquiryForm
              onSave={() => {
                modal.hide();
                saveTracker = notification.startProgress(
                  `Sending your inquiry...`
                );
              }}
              afterSave={(_inquiry) => {
                notification.updateProgress(
                  saveTracker,
                  100,
                  `Your inquiry is send.`
                );
                history.push(`/inquiries/${_inquiry.id}`);
              }}
            />,
            "New Inquiry"
          );
        }}
        icon="fas fa-comments"
      />
    ),
  };

  const Widget = function () {
    if (nav.appNavigator.widget && widgets[nav.appNavigator.widget]) {
      return widgets[nav.appNavigator.widget]();
    }

    return null;
  };

  return (
    <div className={cl(styles.mainSidebar)}>
      <div className={styles.serviceLogoContainer}>
        <NativeLink to="/">
          <Logo />
        </NativeLink>
      </div>
      <nav className={styles.mainNav}>
        <ul>
          {navItems.map((item, key) => (
            <li
              key={`nav-item-${key}`}
              className={
                matchPath(location.pathname, {
                  path: item.to,
                  exact: item.to === "/",
                  strict: true,
                }) !== null
                  ? styles.active
                  : null
              }
            >
              <Link to={item.to} label={item.label} type="branded" />
              {item.items && (
                <ul className={styles.subNav}>
                  {item.items.map((item, subKey) => (
                    <li key={`nav-item-${key}-${subKey}`}>
                      <Link
                        to={item.to}
                        label={item.label}
                        labelSize="small"
                        type="branded"
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <Widget />
    </div>
  );
}

export default withRouter(Navigator);
