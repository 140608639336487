import { useState, useCallback, useEffect } from "react";

export function useKeydown(
  targetKey,
  shouldPreventDefault = true,
  shouldAutoListen = true,
) {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    (event) => {
      if (event.key === targetKey) {
        if (shouldPreventDefault) {
          event.preventDefault();
        }
        setKeyPressed(true);
      }
    },
    [targetKey, shouldPreventDefault],
  );

  useEffect(() => {
    if (shouldAutoListen) {
      window.addEventListener("keydown", downHandler);
    }
    return () => {
      if (shouldAutoListen) {
        setKeyPressed(false);
        window.removeEventListener("keydown", downHandler);
      }
    };
  });

  return keyPressed;
}

export function useKeyup(
  targetKey,
  shouldPreventDefault = true,
  shouldAutoListen = true,
) {
  const [keyPressed, setKeyPressed] = useState(false);

  const upHandler = useCallback(
    (event) => {
      if (event.key === targetKey) {
        if (shouldPreventDefault) {
          event.preventDefault();
        }
        setKeyPressed(true);
      }
    },
    [targetKey, shouldPreventDefault],
  );

  useEffect(() => {
    if (shouldAutoListen) {
      window.addEventListener("keyup", upHandler);
    }
    return () => {
      if (shouldAutoListen) {
        setKeyPressed(false);
        window.removeEventListener("keyup", upHandler);
      }
    };
  });

  return keyPressed;
}
