import React, { useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

import { FormBuilder } from "../../FormElements";
import { createOrder } from "../../../graphql/mutations";
import { orderByNumber } from "../../../graphql/queries";
import { NotificationContext } from "../../../context";
import { TRANSPORT_OPTIONS } from "../../../config";

function CreateOrderForm(props) {
  const notification = useContext(NotificationContext);
  const data = props.data ?? [];
  const errors = [];

  const fields = {
    company: {
      value: data.company ? data.company.name : "",
      component: "text",
      props: {
        label: "Client",
        readonly: "readonly",
      },
      validation: {
        required: true,
      },
    },
    number: {
      value: data.number ?? moment().format("YYYYMMDDHHmm"),
      component: "text",
      props: {
        label: "Order NO.",
        instruction: "Enter order number",
      },
      validation: {
        required: true,
      },
    },
    transport: {
      value: data.transport ?? "",
      component: "select",
      props: {
        label: "Transport",
        instruction: "Select transport method for the order",
        options: TRANSPORT_OPTIONS,
      },
      validation: {
        required: true,
      },
    },
  };

  return (
    <>
      <FormBuilder
        fields={fields}
        submit={{
          handler: async (input) => {
            const {
              data: { orderByNumber: sameNumberOrders },
            } = await API.graphql(
              graphqlOperation(orderByNumber, { number: input.number })
            );
            if (sameNumberOrders.items.length > 0) {
              notification.error(
                `Order with this number '${input.number}' already exists. Please, use another one.`
              );
              return;
            }

            if (props.onSave && typeof props.onSave === "function") {
              props.onSave(input);
            }

            const {
              data: { createOrder: output },
            } = await API.graphql(
              graphqlOperation(createOrder, {
                input: {
                  number: input.number,
                  transport: input.transport,
                  orderCompanyId: data.company.id,
                  status: "DRAFT",
                  type: "ORDER",
                },
              })
            );
            if (props.afterSave && typeof props.afterSave === "function") {
              props.afterSave(output);
            }
          },
          label: "Create order",
        }}
        disclaimer="Once you create the order you can add items and containers to it."
        generalErrors={errors}
      />
    </>
  );
}

export default CreateOrderForm;
