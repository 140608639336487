import React from "react";

import styles from "./Image.module.scss";
import { cl } from "../../../helpers";

function Image(props) {
  return (
    <div className={cl(styles.container, props.className)}>
      {props.children}
    </div>
  );
}

export default Image;
