import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";

import { getLedgerEntry } from "../graphql/queries";
import { NotificationContext } from "../context";
import { Heading, Section, Loader } from "../components/PageElements";
import { UpdateLedgerEntryForm } from "../components/UI/Forms";

function LedgerEntry() {
  const history = useHistory();
  const { ledger } = useParams();
  const notification = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { getLedgerEntry: data },
    } = await API.graphql(
      graphqlOperation(getLedgerEntry, {
        id: ledger,
      })
    );
    setData(data);
    setLoading(false);
  }, [ledger]);

  useEffect(() => fetchData(), [fetchData]);

  const successHandler = (data) => {
    notification.success(
      `You successfully updated ledger entry for ${data.description}.`
    );
    history.push("/ledger");
  };

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Update Ledger Entry" />
        <Section>
          <Loader size="240" label="Loading ledger entry..." />
        </Section>
      </Section>
    );
  }

  return (
    <Section type="screen">
      <Heading tag="h1" label="Update Ledger Entry" />
      <Section type="form">
        <UpdateLedgerEntryForm data={data} afterSave={successHandler} />
      </Section>
    </Section>
  );
}

export default LedgerEntry;
