import React from "react";

import styles from "./Block.module.scss";
import { cl } from "../../../../helpers";

function Block(props) {
  return (
    <div className={cl(styles.separatorContainer, props.containerClassName)}>
      <div className={cl(styles.separator, props.className)}></div>
    </div>
  );
}

export default Block;
