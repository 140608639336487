import { fonts } from "../DocumentGenerator";

const generateForCustomer = (ws, data) => {
  const exchangeRate = data.rates.usd_rate;

  ws.getColumn(5).width = 20;
  ws.getColumn(6).width = 25;
  ws.getColumn(5).numFmt = '"US$"#,##0.00';
  ws.getColumn(6).numFmt = '"US$"#,##0.00';

  const items = data.container.items.items;
  const itemList = ws.addTable({
    name: "ItemsInvoice",
    ref: "A18",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium1",
      showRowStripes: false,
    },
    columns: [
      { name: "ITEM NO", totalsRowLabel: "TOTAL AMOUNT:" },
      { name: "DESCRIPTION" },
      { name: "QTY" },
      { name: "UNIT" },
      { name: "PRICE" },
      { name: "AMOUNT", totalsRowFunction: "sum" },
    ],
    rows: items.map((item, key) => {
      const row = key + 19;
      const itemPrice = item.item.unit_price / exchangeRate;
      const cbmPerCarton = item.item.cbm / item.item.cartons;
      const cartonsInContainer = Math.round(item.cbm_in / cbmPerCarton);
      const itemsInContainer = Math.round(
        cartonsInContainer * item.item.quantity_carton
      );
      return [
        item.item.number,
        item.item.description,
        itemsInContainer,
        item.item.unit_name.toLowerCase(),
        {
          formula: `${item.item.unit_price}/${exchangeRate}`,
          result: itemPrice,
        },
        {
          formula: `C${row}*E${row}`,
          result: itemsInContainer * itemPrice,
        },
      ];
    }),
  });

  ws.getRow(itemList.table.tl.row).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(itemList.table.tl.row).height = 30;
  ws.getRow(itemList.table.tl.row).font = fonts.table;
};

const generateForAdmin = (ws, data) => {
  const exchangeRate = data.rates.usd_rate;

  ws.getColumn(3).width = 25;
  ws.getColumn(6).width = 20;
  ws.getColumn(7).width = 25;
  ws.getColumn(6).numFmt = '"US$"#,##0.00';
  ws.getColumn(7).numFmt = '"US$"#,##0.00';

  const items = data.container.items.items;
  const itemList = ws.addTable({
    name: "ItemsInvoice",
    ref: "A18",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium1",
      showRowStripes: false,
    },
    columns: [
      { name: "ITEM NO", totalsRowLabel: "TOTAL AMOUNT:" },
      { name: "DESCRIPTION" },
      { name: "MATERIAL" },
      { name: "QTY" },
      { name: "UNIT" },
      { name: "PRICE" },
      { name: "AMOUNT", totalsRowFunction: "sum" },
    ],
    rows: items.map((item, key) => {
      const row = key + 19;
      const itemPrice = item.item.unit_price / exchangeRate;
      const cbmPerCarton = item.item.cbm / item.item.cartons;
      const cartonsInContainer = Math.round(item.cbm_in / cbmPerCarton);
      const itemsInContainer = Math.round(
        cartonsInContainer * item.item.quantity_carton
      );
      return [
        item.item.number,
        item.item.description,
        item.item.material,
        itemsInContainer,
        item.item.unit_name.toLowerCase(),
        {
          formula: `${item.item.unit_price}/${exchangeRate}`,
          result: itemPrice,
        },
        {
          formula: `D${row}*F${row}`,
          result: itemsInContainer * itemPrice,
        },
      ];
    }),
  });

  ws.getRow(itemList.table.tl.row).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(itemList.table.tl.row).height = 30;
  ws.getRow(itemList.table.tl.row).font = fonts.table;
};

async function generate(wb, ws, data) {
  if (data.isSystemUser) {
    generateForAdmin(ws, data);
  } else {
    generateForCustomer(ws, data);
  }
}

export default generate;
