import React from "react";
import moment from "moment-timezone";

import { Label } from "../../../PageElements";
import styles from "./DateTimeWidget.module.scss";
import { cl } from "../../../../helpers";

function DateTimeWidget(props) {
  const time = moment.tz(props.timezone);
  return (
    <div className={cl(styles.container, props.className)}>
      <div className={styles.wrapper}>
        <Label value={props.city} size="large" />
        <div className={styles.time}>{time.format("HH:mm")}</div>
        <Label value={time.format("dddd")} size="small" />
        <Label value={time.format("D MMMM YYYY")} uppercase={false} />
      </div>
    </div>
  );
}

export default DateTimeWidget;
