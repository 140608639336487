import { fonts } from "../DocumentGenerator";

async function generate(wb, ws, data) {
  ws.getColumn(5).width = 15;

  const items = data.container.items.items;
  const itemList = ws.addTable({
    name: "PackingListTable",
    ref: "A18",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium1",
      showRowStripes: false,
    },
    columns: [
      { name: "ITEM NO", totalsRowLabel: "TOTAL AMOUNT:" },
      { name: "DESCRIPTION" },
      { name: "QTY" },
      { name: "UNIT" },
      { name: "QTY/CTN", totalsRowFunction: "sum" },
      { name: "CTNS", totalsRowFunction: "sum" },
      { name: "N.W", totalsRowFunction: "sum" },
      { name: "CBM", totalsRowFunction: "sum" },
      // { name: "G.W", totalsRowFunction: "sum" },
    ],
    rows: items.map((item) => {
      const cbmPerCarton = item.item.cbm / item.item.cartons;
      const cartonsInContainer = Math.round(item.cbm_in / cbmPerCarton);
      const itemsInContainer = Math.round(
        cartonsInContainer * item.item.quantity_carton
      );
      return [
        item.item.number,
        item.item.description,
        itemsInContainer,
        item.item.unit_name.toLowerCase(),
        item.item.quantity_carton,
        cartonsInContainer,
        item.item.weight * itemsInContainer,
        item.cbm_in,
        // 0,
      ];
    }),
  });

  ws.getRow(itemList.table.tl.row).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(itemList.table.tl.row).height = 30;
  ws.getRow(itemList.table.tl.row).font = fonts.table;
}

export default generate;
