import React, { useContext, useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { updateCompany } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";
import { AppContext } from "../../../context";
import { listCompanys } from "../../../graphql/queries";
import { Loader } from "../../PageElements";

function UpdateCompanyForm(props) {
  const { currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [managingCompanies, setManagingCompanies] = useState([]);
  const data = props.data ?? [];
  const errors = [];
  const fields = {};

  useEffect(() => {
    if (currentUser.is_system) {
      API.graphql({
        query: listCompanys,
        variables: {
          filter: {
            is_system: { eq: true },
          },
        },
      }).then(
        ({
          data: {
            listCompanys: { items },
          },
        }) => {
          setManagingCompanies(
            items.map((_item) => ({ value: _item.id, label: _item.name }))
          );
          setLoadingCompanies(false);
        }
      );
    } else {
      setLoadingCompanies(false);
    }
  }, [currentUser]);

  fields.name = {
    value: data.name ?? "",
    component: "text",
    props: {
      label: "Company Name",
      instruction: "Enter Company Name",
    },
    validation: {
      required: true,
    },
  };

  if (currentUser.is_system) {
    fields.number = {
      value: data.number ?? "",
      component: "text",
      props: {
        label: "Company Identifier",
        instruction: "Enter Unique Company Identifier",
      },
      validation: {
        required: true,
      },
    };
  }

  if (currentUser.is_system && !data.is_system) {
    fields.managing_company_id = {
      value:
        managingCompanies.find(
          (_company) => _company.value === data.managing_company_id
        ) ?? "",
      component: "select",
      props: {
        label: "Managing Company",
        instruction: "Select Managing Company",
        options: managingCompanies,
      },
      validation: {
        required: true,
      },
    };

    fields.commission = {
      value: data.commission ?? "",
      component: "text",
      props: {
        label: "Commission %",
        instruction: "Enter Commission %",
        type: "number",
        min: 0,
      },
      validation: {
        required: true,
        min: 0,
      },
    };
  }

  fields.address_line_1 = {
    value: data.address_line_1 ?? "",
    component: "text",
    props: {
      label: "Address Line 1",
      instruction: "Enter Address Line 1",
    },
    validation: {
      required: data.is_system,
    },
  };

  fields.address_line_2 = {
    value: data.address_line_2 ?? "",
    component: "text",
    props: {
      label: "Address Line 2",
      instruction: "Enter Address Line 2",
    },
  };

  fields.phone = {
    value: data.phone ?? "",
    component: "text",
    props: {
      label: "Phone",
      instruction: "Enter Phone",
    },
    validation: {
      required: data.is_system,
    },
  };

  fields.fax = {
    value: data.fax ?? "",
    component: "text",
    props: {
      label: "Fax",
      instruction: "Enter Fax",
    },
    validation: {
      required: data.is_system,
    },
  };

  fields.vat_id = {
    value: data.vat_id ?? "",
    component: "text",
    props: {
      label: "VAT NO.",
      instruction: "Enter VAT NO.",
    },
  };

  if (data.is_system) {
    fields.intermediary_bank = {
      value: data.intermediary_bank ?? "",
      component: "text",
      props: {
        label: "Intermediary Bank",
        instruction: "Enter Intermediary Bank Name",
      },
      validation: {
        required: true,
      },
    };

    fields.intermediary_bank_bic = {
      value: data.intermediary_bank_bic ?? "",
      component: "text",
      props: {
        label: "Intermediary Bank BIC",
        instruction: "Enter Intermediary Bank BIC (Swift)",
      },
      validation: {
        required: true,
      },
    };

    fields.beneficiary_bank = {
      value: data.beneficiary_bank ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank",
        instruction: "Enter Beneficiary's Bank Name",
      },
      validation: {
        required: true,
      },
    };

    fields.beneficiary_bank_bic = {
      value: data.beneficiary_bank_bic ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank BIC",
        instruction: "Enter Beneficiary's Bank BIC (Swift)",
      },
      validation: {
        required: true,
      },
    };

    fields.beneficiary_bank_address = {
      value: data.beneficiary_bank_address ?? "",
      component: "text",
      props: {
        label: "Beneficiary's Bank Address",
        instruction: "Enter Beneficiary's Bank Address",
      },
      validation: {
        required: true,
      },
    };

    fields.beneficiary_bank_number = {
      value: data.beneficiary_bank_number ?? "",
      component: "text",
      props: {
        label: "Account Number",
        instruction: "Enter Beneficiary's Bank Account Number",
      },
      validation: {
        required: true,
      },
    };
  }

  const saveHandler = async (input) => {
    setLoading(true);
    if (props.onSave && typeof props.onSave === "function") {
      props.onSave(input);
    }
    const {
      data: { updateCompany: response },
    } = await API.graphql(
      graphqlOperation(updateCompany, {
        input: {
          id: data.id,
          searchableName: input.name.toLowerCase(),
          ...input,
        },
      })
    );
    if (props.afterSave && typeof props.afterSave === "function") {
      props.afterSave(response);
    }
    setLoading(false);
  };

  if (loadingCompanies) {
    return <Loader size="140" label="Loading company details..." />;
  }

  return (
    <FormBuilder
      disabled={loading}
      fields={fields}
      submit={{
        handler: saveHandler,
        label: "Save",
      }}
      generalErrors={errors}
    />
  );
}

export default UpdateCompanyForm;
