import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Icon.module.scss";
import { cl } from "../../../helpers";

function Icon(props) {
  if (typeof props.icon === "string") {
    if (props.counter) {
      return (
        <span className="fa-layers fa-fw">
          <i
            className={cl(props.icon, styles[props.size], props.className)}
          ></i>
          <span className="fa-layers-counter">{props.counter}</span>
        </span>
      );
    }

    return (
      <i className={cl(props.icon, styles[props.size], props.className)}></i>
    );
  }

  if (typeof props.icon === "object") {
    return (
      <div className={cl(styles.container)}>
        <FontAwesomeIcon
          icon={props.icon}
          size={props.size}
          className={props.className}
        />
      </div>
    );
  }

  return null;
}

export default Icon;
