import React from "react";
import { basename } from "path";
import moment from "moment-timezone";

import { getOrderStatusByValue } from "../../../../config";
import { Label, Link, Icon, Heading, Paragraph } from "../../../PageElements";
import styles from "./MessageCard.module.scss";

const CDN_URL = process.env.REACT_APP_CDN;

const templates = {
  "ORDER-STATUS-CHANGE": (vars, { sender }) => (
    <div>
      <Heading
        tag="h6"
        label={getOrderStatusByValue(vars.new).label.toUpperCase()}
      />
      {vars.note && (
        <>
          <Label value="NOTE" />
          <div className={styles.quote}>
            {vars.note.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>
        </>
      )}
      <Paragraph>{sender.name} changed the status of the order.</Paragraph>
      <Paragraph>
        New Status: <Label value={getOrderStatusByValue(vars.new).label} />
      </Paragraph>
      <Paragraph>
        Old Status: <Label value={getOrderStatusByValue(vars.prev).label} />
      </Paragraph>
    </div>
  ),
  "ORDER-SHIP-CONTAINER": (vars, { sender }) => (
    <div>
      <Heading tag="h6" label={`CONTAINER #${vars.number} SHIPPED`} />
      <Paragraph>
        {sender.name} marked container {vars.numbers} as shipped.
      </Paragraph>
    </div>
  ),
  "ORDER-DELIVER-CONTAINER": (vars, { sender }) => (
    <div>
      <Heading tag="h6" label={`CONTAINER #${vars.number} DELIVERED`} />
      <Paragraph>
        {sender.name} marked container {vars.numbers} as delivered in{" "}
        {vars.end_destination}.
      </Paragraph>
    </div>
  ),
};

function MessageCard(props) {
  const message = props.data;
  return (
    <div className={styles.container}>
      <div className={styles.message}>
        {message.template &&
          templates[message.template] &&
          templates[message.template](JSON.parse(message.vars), message)}
        {message.text &&
          message.text.split("\n").map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
        {message.files && message.files.length > 0 && (
          <div className={styles.files}>
            <Label containerClassName={styles.label} value="Attached Files:" />
            <div className={styles.fileList}>
              {message.files.map((_file) => (
                <Link
                  key={_file.key}
                  label={basename(_file.key)}
                  labelUppercase={false}
                  download={true}
                  to={`${CDN_URL}/${_file.key}`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.details}>
        {message.sender && (
          <div>
            <Label
              value={`"${message.sender.company}"`}
              uppercase={false}
              size="small"
            />
            <Label value={message.sender.name} size="large" />
          </div>
        )}
        <span className={styles.time}>
          <Icon icon="fas fa-hourglass-half" className={styles.icon} />
          {moment(message.createdAt).fromNow()}
        </span>
      </div>
    </div>
  );
}

export default MessageCard;
