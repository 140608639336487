import React, { useState, useContext, useCallback } from "react";
import { Auth } from "aws-amplify";

import { OrganizationContext, NavigatorContext } from "./";
export const AppContext = React.createContext();

function AppContextProvider(props) {
  const { init: initNavigator } = useContext(NavigatorContext);
  const { init: initOrganization } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(true);
  const [hasSession, setHasSession] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  const reinit = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const currentUser = user.attributes;
      currentUser.fullname = `${user.attributes.given_name} ${user.attributes.family_name}`;
      const userGroups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      currentUser.groups = userGroups;
      currentUser.is_system = false;
      currentUser.role = {};
      if (userGroups) {
        if (userGroups.includes("Root")) {
          currentUser.is_system = true;
          currentUser.role = { key: "Root", value: "ROOT Access" };
        } else if (userGroups.includes("Admin")) {
          currentUser.is_system = true;
          currentUser.role = { key: "Admin", value: "Global Admin" };
        } else if (userGroups.includes("Cashier")) {
          currentUser.is_system = true;
          currentUser.role = { key: "Cashier", value: "Cashier" };
        } else if (userGroups.includes("CompanyAdmin")) {
          currentUser.is_system = false;
          currentUser.role = { key: "CompanyAdmin", value: "Company Manager" };
        } else if (userGroups.includes("CompanyMember")) {
          currentUser.is_system = false;
          currentUser.role = {
            key: "CompanyMember",
            value: "Company Employee",
          };
        }
      }
      initNavigator(currentUser.role.key);
      setCurrentUser(currentUser);
      initOrganization(user.signInUserSession.idToken.payload);
      setHasSession(true);
      setLoading(false);
    } catch (e) {
      setHasSession(false);
      setCurrentUser(null);
      setLoading(false);
    }
  }, [initOrganization, initNavigator]);

  return (
    <AppContext.Provider
      value={{
        version: "v1.0",
        reinit,
        hasSession,
        currentUser,
        loading,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
