import React, { useCallback, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { ORDER_STATUS_LIST, getOrderStatusByValue } from "../../../config";
import { getCorrespondence } from "../../../graphql/queries";
import {
  updateOrder,
  createCorrespondence,
  createMessage,
  createNotification,
} from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";
import { AppContext, OrganizationContext } from "../../../context";

function ChangeOrderStatusForm(props) {
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const newStatus = getOrderStatusByValue(props.newStatus);
  const oldStatus = getOrderStatusByValue(props.order.status);
  const orderId = props.order.id;

  const fetchCorrespondence = useCallback(async () => {
    const {
      data: { getCorrespondence: correspondenceData },
    } = await API.graphql(graphqlOperation(getCorrespondence, { id: orderId }));
    return correspondenceData;
  }, [orderId]);

  const submitHandler = async (input) => {
    props.onSave(input);
    const {
      data: { updateOrder: response },
    } = await API.graphql(
      graphqlOperation(updateOrder, {
        input: {
          id: props.order.id,
          status: input.status,
        },
      })
    );

    const author = {
      id: currentUser.sub,
      name: currentUser.fullname,
      company: organization.name,
    };

    let correspondence = await fetchCorrespondence();
    if (!correspondence) {
      const {
        data: { createCorrespondence: newCorrespondence },
      } = await API.graphql(
        graphqlOperation(createCorrespondence, {
          input: {
            id: props.order.id,
            author_id: author.id,
            sender: author,
            type: "ORDER",
            group_id: props.order.company.id,
            title: `Order #${props.order.number} Change Log`,
          },
        })
      );
      correspondence = newCorrespondence;
    }

    // change log
    API.graphql(
      graphqlOperation(createMessage, {
        input: {
          group_id: props.order.company.id,
          correspondence_id: correspondence.id,
          sender: author,
          template: "ORDER-STATUS-CHANGE",
          vars: JSON.stringify({
            prev: oldStatus.value,
            new: newStatus.value,
            note: input.note,
          }),
        },
      })
    );

    // notification
    if (organization.id !== props.order.company.id) {
      API.graphql(
        graphqlOperation(createNotification, {
          input: {
            receiver: props.order.company.id,
            title: `Order #${props.order.number} Update`,
            content: `${
              author.name
            } changed order status from "${oldStatus.label.toUpperCase()}" to "${newStatus.label.toUpperCase()}".`,
            icon: `fas fa-user-edit`,
            type: "CONFIRM",
            to: `/orders/${props.order.id}`,
          },
        })
      );
    }

    props.afterSave(response);
  };

  const fields = {
    status: {
      value: newStatus || "",
      component: "select",
      props: {
        label: "New Status",
        instruction: "Select new status",
        options: ORDER_STATUS_LIST,
        disabled: !!props.newStatus,
      },
      validation: {
        required: true,
      },
    },
    note: {
      value: "",
      component: "textarea",
      props: {
        label: "Note",
        instruction: "Describe the change",
        rows: 6,
      },
      validation: {
        max: 2000,
      },
    },
  };

  return (
    <FormBuilder
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Change Status",
      }}
    />
  );
}

export default ChangeOrderStatusForm;
