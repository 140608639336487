import { useEffect, useCallback, useState } from "react";

export function useClickAway(ref, isEnabled = true) {
  const [clickedAway, setClickedAway] = useState(false);

  const handleClickAway = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedAway(true);
      }
    },
    [ref],
  );

  useEffect(() => {
    if (isEnabled) {
      document.addEventListener("mousedown", handleClickAway);
    }
    return () => {
      if (isEnabled) {
        setClickedAway(false);
        document.removeEventListener("mousedown", handleClickAway);
      }
    };
  });

  return clickedAway;
}
