import React from "react";
import PropTypes from "prop-types";

import formStyles from "../../FormElement.module.scss";
import { Label } from "../../../PageElements";
import { Group } from "../../";
import { cl } from "../../../../helpers";

Radio.propTypes = {
  nested: PropTypes.bool.isRequired,
};

Radio.defaultProps = {
  nested: true,
};

function Radio(props) {
  const required = props.required ? "*" : "";
  const label = `${required} ${props.label}`;
  const hasErrors = props.errors && props.errors.length;

  const errors = hasErrors
    ? props.errors.map((error, index) => {
        if (!error.error) {
          return null;
        }

        return (
          <span className={formStyles.error} key={`${error.field}_${index}`}>
            {error.error}
          </span>
        );
      })
    : null;

  return (
    <div
      className={cl(
        formStyles.wrapper,
        props.nested ? formStyles.nested : null
      )}
    >
      <Group
        label={label}
        branded={props.branded}
        disabled={props.disabled}
        hasErrors={hasErrors}
      >
        {props.options.map((option) => (
          <Label
            value={option.label}
            key={`${props.name}_${option.value}`}
            uppercase={false}
            htmlFor={`${props.name}_${option.value}`}
          >
            <input
              type="radio"
              name={props.name}
              id={`${props.name}_${option.value}`}
              checked={props.value === option.value}
              onChange={props.onChange}
              value={option.value}
              disabled={props.disabled || option.disabled}
            />
          </Label>
        ))}
      </Group>
      <div className={formStyles.errorsContainer}>{errors}</div>
    </div>
  );
}

export default Radio;
