import React from "react";

import {
  Heading,
  Section,
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../components/PageElements";
import { ContentTable } from "../components/UI/Tables";

function ContentManager() {
  return (
    <>
      <Section type="first">
        <Heading tag="h1" label="Content Manager" />
      </Section>
      <Tabs>
        <TabList>
          <Tab>Pages</Tab>
          <Tab>Help Center</Tab>
        </TabList>
        <TabPanel>
          <Section type="first">
            <ContentTable type="PAGE" />
          </Section>
        </TabPanel>
        <TabPanel>
          <Section type="first">
            <ContentTable type="HELP" />
          </Section>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default ContentManager;
