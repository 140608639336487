import React, { useContext, useCallback, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { UnassignedUserOverlay } from "../components/UI/Overlays";
import { OverlayContext } from "./";

export const OrganizationContext = React.createContext();

const _schema = {};

function OrganizationContextProvider(props) {
  const history = useHistory();
  const { display: displayOverlay } = useContext(OverlayContext);
  const [organization, setOrganization] = useState(_schema);

  const init = useCallback(
    async (identity) => {
      if (!identity["custom:company_id"] || !identity["custom:company_name"]) {
        history.replace("/");
        Auth.signOut();
        displayOverlay(UnassignedUserOverlay);
      }

      setOrganization({
        id: identity["custom:company_id"],
        name: identity["custom:company_name"],
        is_system: identity["custom:is_system_company"] === "true",
        managing_company_id: identity["custom:managing_company_id"],
      });
    },
    [displayOverlay, history]
  );

  return (
    <OrganizationContext.Provider
      value={{
        init,
        organization,
        setOrganization,
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
}

export default OrganizationContextProvider;
