import React, { useState, useEffect, useCallback } from "react";

export const OverlayContext = React.createContext();

function OverlayContextProvider(props) {
  const [show, setShow] = useState(false);
  const [tts, setTTs] = useState(undefined);
  const [content, setContent] = useState(null);

  const reset = useCallback(() => {
    setTTs(undefined);
  }, []);

  const display = useCallback((content) => {
    setShow(true);
    setContent(content);
  }, []);

  const hide = useCallback(() => {
    setShow(false);
    setContent(null);
    reset();
  }, [reset]);

  const displayForDuration = useCallback((duration) => {
    setTTs(duration);
  }, []);

  const transition = useCallback(
    (transitionComponent, duration = null) => {
      if (duration) displayForDuration(duration);
      setContent(transitionComponent);
    },
    [displayForDuration]
  );

  useEffect(() => {
    if (tts !== undefined) {
      setShow(true);
      setTimeout(() => {
        hide();
      }, tts);
    }
  }, [tts, hide]);

  return (
    <OverlayContext.Provider
      value={{
        show,
        hide,
        setShow,
        displayForDuration,
        transition,
        content,
        setContent,
        display,
      }}
    >
      {props.children}
    </OverlayContext.Provider>
  );
}

export default OverlayContextProvider;
