import { cl } from "../../../../helpers";
import { CellGallery } from "../../../UI/Widgets";
import styles from "../Table.module.scss";

const editors = {
  text: (val, row, column, identifierKey, props) => {
    return (
      <div className={cl(styles.inputWrapper)}>
        <input
          value={val}
          readOnly={column.readOnly}
          placeholder={column.placeholder}
          onChange={(e) =>
            props.onDataChange(row[identifierKey], column.key, e.target.value)
          }
        />
      </div>
    );
  },
  number: (val, row, column, identifierKey, props) => (
    <div className={cl(styles.inputWrapper)}>
      <input
        value={val}
        readOnly={column.readOnly}
        placeholder={column.placeholder}
        onChange={(e) =>
          props.onDataChange(row[identifierKey], column.key, e.target.value)
        }
        type="number"
      />
    </div>
  ),
  price: (val, row, column, identifierKey, props) => (
    <div className={cl(styles.inputWrapper, styles.currency)}>
      <i>{column.symbol || "¥"}</i>
      <input
        value={val}
        readOnly={column.readOnly}
        placeholder={column.placeholder}
        className={styles.currencyVal}
        onChange={(e) =>
          props.onDataChange(row[identifierKey], column.key, e.target.value)
        }
        type="number"
      />
    </div>
  ),
  select: (val, row, column, identifierKey, props) => (
    <div className={cl(styles.inputWrapper)}>
      <select
        value={val}
        readOnly={column.readOnly}
        placeholder={column.placeholder}
        onChange={(e) =>
          props.onDataChange(row[identifierKey], column.key, e.target.value)
        }
      >
        {column.options.map((option) => (
          <option value={option.key} key={`option-${option.key}`}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  ),
  pictures: (_, row, column, identifierKey, props) => (
    <div className={cl(styles.inputWrapper, styles.pictures)}>
      <span
        className={styles.viewUploads}
        title="View"
        onClick={() =>
          column.onView(row[identifierKey], column.key, row, column)
        }
      ></span>
      <input
        type="file"
        title="Upload picture"
        readOnly={column.readOnly}
        onChange={(e) => {
          props.onFilesUpload(row[identifierKey], column.key, e.target.files);
          e.target.value = "";
        }}
        accept="image/*"
        multiple
      />
    </div>
  ),
  cellGallery: (_, row, column) => {
    return (
      <CellGallery
        path={`${row.order_id}/${row.id}/`}
        readOnly={column.readOnly}
        onUploadStart={column.onUploadStart}
        onUploadFinish={column.onUploadFinish}
      />
    );
  },
};

export default editors;
