import React, { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { listLedgerEntrys, listCompanys } from "../graphql/queries";
import { deleteLedgerEntry } from "../graphql/mutations";
import {
  onCreateLedgerEntry,
  onDeleteLedgerEntry,
} from "../graphql/subscriptions";
import { Section, Heading, Table } from "../components/PageElements";
import { DEFAULT_DATE_FORMAT } from "../config";
import { Select } from "../components/FormElements";

function Ledger() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, selectCompany] = useState(null);

  const getCompanies = async () => {
    const {
      data: {
        listCompanys: { items },
      },
    } = await API.graphql(graphqlOperation(listCompanys));
    setCompanies([{ id: null, name: "All Companies" }, ...items]);
  };

  const fetchData = useCallback(async (next = null, company = null) => {
    const filter = company
      ? { filter: { ledgerEntryCompanyId: { eq: company } } }
      : {};
    if (!next) {
      setData([]);
    }
    setLoading(true);
    const {
      data: {
        listLedgerEntrys: { items, nextToken },
      },
    } = await API.graphql(
      graphqlOperation(listLedgerEntrys, {
        ...filter,
        limit: 25,
        nextToken: next,
      })
    );
    setData((currentItems) => {
      const newItems = [...currentItems];
      newItems.push(...items);
      return newItems;
    });
    setNext(nextToken);
    setLoading(false);
  }, []);

  useEffect(() => {
    getCompanies();
    return () => {
      setCompanies([]);
    };
  }, []);

  useEffect(() => {
    fetchData(null, selectedCompany);
    return () => {
      setData([]);
    };
  }, [fetchData, selectedCompany]);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateLedgerEntry)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteLedgerEntry)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteLedgerEntry: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (ledgerEntry) => ledgerEntry.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, [fetchData]);

  const columns = [
    {
      key: "date",
      label: "Date",
      type: "date",
      format: DEFAULT_DATE_FORMAT,
      isSortable: true,
    },
    { key: "description", label: "Description", type: "text" },
    { key: "company.name", label: "Company", type: "label" },
    { key: "credit", label: "In", type: "price", isSortable: true },
    { key: "debit", label: "Out", type: "price", isSortable: true },
    {
      key: "balance",
      label: "Balance",
      type: "renderer",
      formula: (_, row) => row.credit - row.debit,
      renderer: (val) => {
        if (val < 0) return `(¥${Math.abs(val)})`;
        return `¥${val}`;
      },
    },
  ];

  const actions = [
    {
      label: "Edit",
      to: ({ id }) => `/ledger/edit/${id}`,
    },
    { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ id }) => {
        await API.graphql(
          graphqlOperation(deleteLedgerEntry, { input: { id } })
        );

        return { success: true };
      },
    },
  ];

  return (
    <Section type="screen">
      <Heading tag="h1" label="Ledger" />
      <Select
        label="Company Filter"
        instruction="Filter list by company"
        isClearable={false}
        options={companies
          .filter((company) => !company.is_system)
          .map((company) => ({
            value: company.id,
            label: company.name,
          }))}
        onChange={(company) => selectCompany(company ? company.value : null)}
      />
      <Table
        loading={loading}
        hasNext={!!next}
        onNext={{
          label: "Load More Ledger Entries...",
          handler: () => fetchData(next, selectCompany),
        }}
        columns={columns}
        mainColumn="description"
        data={data}
        actions={actions}
      />
    </Section>
  );
}

export default Ledger;
