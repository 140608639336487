import React, { useContext, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { NotificationContext } from "../../../context";
import { Table } from "../../PageElements";
import {
  createLedgerEntry,
  updateLedgerEntry,
  deleteLedgerEntry,
} from "../../../graphql/mutations";

function AddToCompanyLedgerForm(props) {
  const notification = useContext(NotificationContext);
  const { company } = props;
  const [data, setData] = useState([]);
  const actions = [
    {
      renderer: (row) => {
        return row.id.startsWith(`temp-`) ? "Save" : "Update";
      },
      callback: (input) => {
        if (input.id.startsWith(`temp-`)) {
          API.graphql(
            graphqlOperation(createLedgerEntry, {
              input: {
                debit: input.debit,
                credit: input.credit,
                description: input.description,
                ledgerEntryCompanyId: company.id,
              },
            })
          ).then(({ data: { createLedgerEntry: createdEntry } }) => {
            setData((oldData) => {
              const newData = [...oldData];
              const rowToEdit = newData.findIndex((row) => row.id === input.id);
              if (rowToEdit > -1) {
                newData[rowToEdit] = createdEntry;
              }
              return newData;
            });
            notification.quickConfirm(`Ledger entry created.`);
          });
        } else {
          API.graphql(
            graphqlOperation(updateLedgerEntry, {
              input: {
                id: input.id,
                debit: input.debit,
                credit: input.credit,
                description: input.description,
              },
            })
          ).then(({ data: { updateLedgerEntry: updatedEntry } }) => {
            setData((oldData) => {
              const newData = [...oldData];
              const rowToEdit = newData.findIndex((row) => row.id === input.id);
              if (rowToEdit > -1) {
                newData[rowToEdit] = updatedEntry;
              }
              return newData;
            });
            notification.quickConfirm(`Ledger entry updated.`);
          });
        }
      },
    },
    {
      type: "separator",
    },
    {
      renderer: (row) => {
        return row.id.startsWith(`temp-`) ? "Remove" : "Delete";
      },
      callback: ({ id }) => {
        if (!id.startsWith("temp-")) {
          API.graphql(
            graphqlOperation(deleteLedgerEntry, { input: { id } })
          ).then(() => {
            notification.quickConfirm(`Ledger entry deleted.`);
          });
        }
        setData((oldData) => {
          const newData = [...oldData];
          const rowToDelete = newData.findIndex((row) => row.id === id);
          newData.splice(rowToDelete, 1);
          return newData;
        });
      },
    },
  ];
  const columns = [
    {
      key: "description",
      label: "Description",
      placeholder: "Describe the entry",
      type: "text",
    },
    { key: "credit", label: "In", type: "price" },
    { key: "debit", label: "Out", type: "price" },
  ];

  return (
    <Table
      editable={true}
      mainColumn="description"
      columns={columns}
      data={data}
      onDataChange={(id, field, value) => {
        setData((oldData) => {
          const newData = [...oldData];
          const rowToEdit = newData.find((row) => row.id === id);
          rowToEdit[field] = value;
          return newData;
        });
      }}
      onAdd={{
        label: "Add New Entry (+)",
        handler: () => {
          setData((oldData) => {
            const newData = [...oldData];
            newData.push({
              id: `temp-${Date.now()}`,
              description: "",
              credit: 0,
              debit: 0,
            });
            return newData;
          });
        },
      }}
      actions={actions}
    />
  );
}

export default AddToCompanyLedgerForm;
