import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

import { getCompany } from "../graphql/queries";
import { onUpdateCompany } from "../graphql/subscriptions";
import {
  AppContext,
  ModalContext,
  NotificationContext,
  OrganizationContext,
} from "../context";
import {
  Heading,
  Section,
  Loader,
  Label,
  Link,
} from "../components/PageElements";
import { Button } from "../components/FormElements";
import { UpdateCompanyForm } from "../components/UI/Forms";
import { InvitationsTable, EmployeesTable } from "../components/UI/Tables";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  InlineSeparator,
} from "../components/PageElements";

function Company() {
  const modal = useContext(ModalContext);
  const { currentUser } = useContext(AppContext);
  const { pathname } = useLocation();
  let { company } = useParams();

  const { organization } = useContext(OrganizationContext);
  const notification = useContext(NotificationContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  if (pathname === "/profile" && !company) {
    company = organization.id;
  }

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { getCompany: data },
    } = await API.graphql(
      graphqlOperation(getCompany, {
        id: company,
      })
    );
    setData(data);
    setLoading(false);
  }, [company]);

  useEffect(() => fetchData(), [fetchData]);

  useEffect(() => {
    const updateSubscription = API.graphql(
      graphqlOperation(onUpdateCompany)
    ).subscribe({
      next: fetchData,
    });

    return () => {
      updateSubscription.unsubscribe();
    };
  }, [fetchData]);

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Update Company" />
        <Section>
          <Loader size="140" label="Loading company details..." />
        </Section>
      </Section>
    );
  }

  const isEditable =
    (data.is_system && currentUser.role.key.toUpperCase() === "ROOT") ||
    (!data.is_system && currentUser.role.key !== "CompanyMember");

  return (
    <>
      <Section type="first">
        <Heading tag="h1" label={data.name} />
        <div style={{ lineHeight: 2 }}>
          {isEditable && (
            <>
              <span>
                <Button
                  label="Update Company"
                  labelIcon="fas fa-edit"
                  rounded={true}
                  type="secondaryBranded"
                  labelSize="small"
                  callback={() => {
                    let saveTracker;
                    modal.showForm(
                      <UpdateCompanyForm
                        data={data}
                        onSave={() => {
                          saveTracker = notification.startProgress(
                            "Saving company details..."
                          );
                          modal.hide();
                        }}
                        afterSave={(data) => {
                          notification.updateProgress(
                            saveTracker,
                            100,
                            <>
                              You successfully updated company{" "}
                              <Label value={data.name} uppercase={false} />.
                            </>
                          );
                        }}
                      />
                    );
                  }}
                  style={{ marginBottom: "0.5rem" }}
                />
              </span>
              <br className="not-tablet not-desktop" />
              <InlineSeparator className="not-mobile" />
            </>
          )}
          {data.is_system && (
            <>
              <Label value="System Company" />
              <br className="not-tablet not-desktop" />
              <InlineSeparator className="not-mobile" />
            </>
          )}
          <span>
            Created:{" "}
            <Label value={moment(data.createdAt).fromNow()} uppercase={false} />
          </span>
          <br className="not-tablet not-desktop" />
          <InlineSeparator className="not-mobile" />
          <span>
            Unique Number: <Label value={`#${data.number}`} />
          </span>
          {data.vat_id && (
            <>
              <br className="not-tablet not-desktop" />
              <InlineSeparator className="not-mobile" />
              <span>
                VAT: <Label value={data.vat_id} />
              </span>
            </>
          )}
          {!!data.commission && (
            <>
              <br className="not-tablet not-desktop" />
              <InlineSeparator className="not-mobile" />
              <span>
                Commission: <Label value={`${data.commission}%`} />
              </span>
            </>
          )}
          {data.address_line_1 && (
            <>
              <br className="not-mobile" />
              <span className="not-mobile">
                <Label value={data.address_line_1} />
              </span>
            </>
          )}
          {data.address_line_2 && (
            <>
              <br className="not-mobile" />
              <span className="not-mobile">
                <Label value={data.address_line_2} />
              </span>
            </>
          )}
          {data.phone && (
            <>
              <br />
              <span>
                Phone:{" "}
                <Link
                  to={`tel:${data.phone}`}
                  label={data.phone}
                  type="branded"
                />
              </span>
            </>
          )}
          {data.fax && (
            <>
              <InlineSeparator className="not-mobile" />
              <span className="not-mobile">
                Fax: <Label value={data.fax} />
              </span>
            </>
          )}
        </div>
      </Section>
      {currentUser.role.key !== "CompanyMember" ? (
        <Tabs>
          <TabList>
            {/* @todo */}
            {/* <Tab>Comms</Tab> */}
            <Tab>Employees</Tab>
          </TabList>
          {/* <TabPanel>
          <Section type="first">Comms with the company...</Section>
        </TabPanel> */}
          <TabPanel>
            <Section type="first">
              <Heading tag="h6" label="Pending Invitations" />
              <InvitationsTable company={data} />
            </Section>
            <Section type="stack">
              <Heading tag="h6" label="Registered Employees" />
              <EmployeesTable company={data} />
            </Section>
          </TabPanel>
        </Tabs>
      ) : (
        <Tabs></Tabs>
      )}
    </>
  );
}

export default Company;
