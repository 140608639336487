import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";

import { AppContext } from "../../../context";
import { FormBuilder } from "../../FormElements";
import { ChangePasswordForm } from "./";

function SigninForm(props) {
  const app = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [newPasswordUser, setNewPasswordUser] = useState(false);
  const [errors, setErrors] = useState([]);
  const { onModeChange } = props;

  const signinFields = {
    email: {
      value: "",
      component: "text",
      props: {
        type: "email",
        label: "Email",
        instruction: "Enter Email",
      },
      validation: {
        required: true,
        email: true,
      },
    },
    password: {
      value: "",
      component: "text",
      props: {
        type: "password",
        label: "Password",
        instruction: "Enter Password",
      },
      validation: {
        required: true,
      },
    },
  };

  const signinHandler = async (data) => {
    props.beforeSignin();
    setErrors([]);
    setLoading(true);
    try {
      const user = await Auth.signIn(data.email, data.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewPasswordUser(user);
        props.afterSignin(data);
        return;
      }
      props.afterSignin(data);
      setLoading(false);
      await app.reinit();
    } catch (e) {
      setLoading(false);
      setErrors([e.message]);
      props.afterSignin(data);
    }
  };

  useEffect(() => {
    if (newPasswordUser) onModeChange("Change your Password");
  }, [onModeChange, newPasswordUser]);

  if (newPasswordUser) {
    return (
      <ChangePasswordForm
        beforePasswordReset={props.beforeSignin}
        afterPasswordReset={props.afterSignin}
        user={newPasswordUser}
      />
    );
  }

  return (
    <FormBuilder
      fields={signinFields}
      disabled={loading}
      submit={{
        handler: signinHandler,
        label: "Sign in",
      }}
      generalErrors={errors}
    />
  );
}

export default SigninForm;
