import React, { useState, useEffect, useContext, useCallback } from "react";
import Resizer from "react-image-file-resizer";
import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";

import { Icon, Label, Loader } from "../../../PageElements";
import { Gallery } from "..";
import { ModalContext, NotificationContext } from "../../../../context";
import {
  container,
  uploadButton,
  deleteButton,
} from "./CellGallery.module.scss";

const CDN_URL = process.env.REACT_APP_CDN;

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

function CellGallery(props) {
  const modal = useContext(ModalContext);
  const notification = useContext(NotificationContext);
  const { path } = props;
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState([]);
  const [uploading, setUploading] = useState(0);

  const open = () => {
    modal.setSize("large");
    modal.setBody(<Gallery path={path} />);
    modal.show(`Item Gallery`);
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    Storage.list(path).then((files) => {
      setPictures(files);
      setLoading(false);
    });
  }, [path]);

  const uploadPicture = (e) => {
    if (props.readOnly) {
      return;
    }

    props.onUploadStart(e);
    setLoading(true);
    Array.from(e.target.files).forEach(async (file) => {
      setUploading((uploading) => uploading + 1);
      const tracker = notification.startProgress(`Starting picture upload...`);
      const image = await resizeImage(file);
      Storage.put(`${path}${uuid()}.jpg`, image, {
        progressCallback(p) {
          const progress = ((p.loaded / p.total) * 100).toFixed();
          notification.updateProgress(
            tracker,
            progress,
            `Picture upload done!`
          );
        },
        contentType: "image/*",
      })
        .then(() => {
          e.target.value = "";
          setUploading((uploading) => uploading - 1);
          fetchData();
          props.onUploadFinish(e);
        })
        .catch((err) => {
          notification.error(`Error uploading file: ${err}`);
        });
    });
  };

  const deletePicture = () => {
    if (props.readOnly) {
      return;
    }

    modal.confirm({
      title: `Are you sure?`,
      onConfirm: () => {
        Storage.remove(pictures[0].key).then(() => {
          notification.quickConfirm(`Photo delete successfully`);
          fetchData();
        });
      },
    });
  };

  useEffect(fetchData, [fetchData]);

  if (loading || uploading) {
    return (
      <div className={container}>
        <Loader size="100" />
      </div>
    );
  }

  if (!pictures.length) {
    return (
      <div className={container}>
        {props.readOnly ? (
          <Label value="No Picture" />
        ) : (
          <input
            type="file"
            title="Upload picture"
            onChange={uploadPicture}
            className={uploadButton}
            accept="image/*"
            multiple
          />
        )}
      </div>
    );
  }

  return (
    <div className={container}>
      <img src={`${CDN_URL}/${pictures[0].key}`} alt="" onClick={open} />
      {!props.readOnly && (
        <button onClick={deletePicture} className={deleteButton}>
          <Icon icon="fa fa-times" size="lg" />
        </button>
      )}
    </div>
  );
}

export default CellGallery;
