import React from "react";

import styles from "./Section.module.scss";
import { cl } from "../../../helpers";

function Section(props) {
  return (
    <section
      className={cl(styles.section, styles[props.type], props.className)}
      style={props.style}
    >
      {props.children}
    </section>
  );
}

export default Section;
