import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

import { DEFAULT_DATE_FORMAT } from "../config";
import { getRate } from "../graphql/queries";
import { NotificationContext } from "../context";
import { Heading, Section, Loader, Label } from "../components/PageElements";
import { ExchangeRate } from "../components/UI/Forms";

function Company() {
  const history = useHistory();
  let { date } = useParams();

  const notification = useContext(NotificationContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const {
      data: { getRate: data },
    } = await API.graphql(graphqlOperation(getRate, { date }));
    setData(data);
    setLoading(false);
  };

  useEffect(
    () => fetchData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Update Exchange Rate" />
        <Section>
          <Loader size="140" label="Loading exchange rate..." />
        </Section>
      </Section>
    );
  }

  return (
    <Section type="screen">
      <Heading
        tag="h1"
        label={`Exchange Rate - ${moment(data.date).format(
          DEFAULT_DATE_FORMAT
        )}`}
      />
      <Section type="form">
        <ExchangeRate
          data={data}
          afterSave={({ date }) => {
            notification.success(
              <>
                You successfully added exchange rate for{" "}
                <Label value={moment(date).format(DEFAULT_DATE_FORMAT)} />.
              </>
            );
            history.push("/rates");
          }}
        />
      </Section>
    </Section>
  );
}

export default Company;
