import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Amplify from "aws-amplify";

import {
  AppContextProvider,
  NavigatorContextProvider,
  OrganizationContextProvider,
  ModalContextProvider,
  OverlayContextProvider,
  NotificationContextProvider,
} from "./context/";
import App from "./App";
import "./index.scss";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <OverlayContextProvider>
        <OrganizationContextProvider>
          <NavigatorContextProvider>
            <AppContextProvider>
              <ModalContextProvider>
                <NotificationContextProvider>
                  <App />
                </NotificationContextProvider>
              </ModalContextProvider>
            </AppContextProvider>
          </NavigatorContextProvider>
        </OrganizationContextProvider>
      </OverlayContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
