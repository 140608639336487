import React, { useContext, useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { NotificationContext } from "../../../context";
import { BASE_URL } from "../../../config";
import { companyInvitations } from "../../../graphql/queries";
import { deleteInvite, sendInvite } from "../../../graphql/mutations";
import { onCreateInvite, onDeleteInvite } from "../../../graphql/subscriptions";
import { Table, Label } from "../../PageElements";

const roleToGroup = {
  ROOT: "Root",
  ADMIN: "Admin",
  CASHIER: "Cashier",
  COMPANY_ADMIN: "Manager",
  COMPANY_MEMBER: "Employee",
};

function InvitationsTable(props) {
  const notification = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const {
    company: { id: company_id },
  } = props;

  const fetchData = useCallback(
    async (next = null) => {
      if (company_id) {
        setLoading(true);
        if (!next) {
          setData([]);
        }
        const {
          data: {
            companyInvitations: { items, nextToken },
          },
        } = await API.graphql(
          graphqlOperation(companyInvitations, {
            company_id,
            limit: 25,
            nextToken: next,
            sortDirection: "DESC",
          })
        );
        setData((currentItems) => {
          const newItems = [...currentItems];
          newItems.push(...items);
          return newItems;
        });
        setNext(nextToken);
        setLoading(false);
      }
    },
    [company_id]
  );

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, [fetchData]);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateInvite)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteInvite)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteInvite: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (invite) => invite.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, [fetchData]);

  const columns = [
    { key: "email", label: "Email", type: "email" },
    {
      key: "Name",
      label: "Name",
      type: "renderer",
      renderer: (_, row) => {
        if (!row.given_name && !row.family_name) {
          return "N/A";
        }

        return `${row.given_name} ${row.family_name}`;
      },
    },
    { key: "createdAt", label: "Pending Since", type: "date" },
    {
      key: "role",
      label: "Role",
      type: "renderer",
      renderer: (val) => <Label value={roleToGroup[val]} />,
    },
  ];

  const actions = [
    {
      label: "Resend",
      callback: async ({ id }) => {
        API.graphql({
          query: sendInvite,
          variables: {
            id,
          },
        });
        notification.quickConfirm(`Invitation re-sent.`);
      },
    },
    { type: "separator" },
    {
      label: "Copy Link",
      type: "copy",
      copyFormatter: ({ id }) => {
        return `${BASE_URL}/signup/${id}`;
      },
    },
    { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ id }) => {
        await API.graphql(graphqlOperation(deleteInvite, { input: { id } }));
        return { success: true };
      },
    },
  ];

  return (
    <Table
      loading={loading}
      hasNext={!!next}
      onNext={{
        label: "Load More Invitations...",
        handler: () => fetchData(next),
      }}
      columns={columns}
      mainColumn="email"
      data={data}
      actions={actions}
    />
  );
}

export default InvitationsTable;
