import { Storage } from "aws-amplify";

async function generate(wb, ws, data) {
  const exchangeRate = data.rates.usd_rate;
  const commission = data.order.company.commission / 100;

  ws.getColumn(1).width = 20;
  ws.getColumn(2).width = 25;
  ws.getColumn(3).width = 15;
  ws.getColumn(4).width = 30;
  ws.getColumn(5).width = 20;
  ws.getColumn("E").hidden = !data.isSystemUser;
  ws.getColumn(6).width = 20;
  ws.getColumn(10).width = 15;
  ws.getColumn(11).width = 15;
  ws.getColumn(13).width = 15;
  ws.getColumn(14).width = 15;
  ws.getColumn(15).width = 15;

  ws.getColumn(8).numFmt = '"￥"#,##0.00';
  ws.getColumn(10).numFmt = "0.00%";
  ws.getColumn(11).numFmt = '"￥"#,##0.00';
  ws.getColumn(12).numFmt = '"US$"#,##0.00';
  ws.getColumn(13).numFmt = '"US$"#,##0.00';
  ws.getColumn(14).numFmt = '"US$"#,##0.00';
  ws.getColumn(15).numFmt = '"US$"#,##0.00';

  ws.getCell("B6").numFmt = '"US$"#,##0.00';
  ws.getCell("F1").numFmt = '"US$"#,##0.00';
  ws.getCell("F2").numFmt = '"US$"#,##0.00';
  ws.getCell("F4").numFmt = "0.00%";

  ws.getCell("A1").value = "ID BUYER:";
  ws.getCell("A2").value = "Date:";
  ws.getCell("A3").value = "Shipping Date:";
  ws.getCell("A4").value = "Invoice No:";
  ws.getCell("A5").value = "Container No:";
  ws.getCell("A6").value = "Document fee:";
  ws.getCell("B1").value = data.order.company.number;
  ws.getCell("B2").value = data.container.invoice_date;
  ws.getCell("B3").value = data.container.shipping_date;
  ws.getCell("B4").value = data.container.invoice_number;
  ws.getCell("B5").value = data.container.provider_id;
  ws.getCell("B6").value = data.container.document_fee;

  ws.getCell("D1").value = "Freight Fee:";
  ws.getCell("D2").value = "TELEX/DHL:";
  ws.getCell("D3").value = "Rate:";
  ws.getCell("D4").value = "Commission Fee:";
  ws.getCell("F1").value = data.container.freight_fee;
  ws.getCell("F2").value = data.container.telex_dhl_fee;
  ws.getCell("F3").value = exchangeRate;
  ws.getCell("F4").value = commission;

  const items = data.container.items.items;
  const itemList = ws.addTable({
    name: "OverviewItemsTable",
    ref: "A7",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium1",
      showRowStripes: false,
    },
    columns: [
      { name: "ORDER NO", totalsRowLabel: "TOTAL:" },
      { name: "ITEM NO" },
      { name: "PICTURE" },
      { name: "DESCRIPTION" },
      { name: "MATERIAL" },
      { name: "QTY" },
      { name: "UNIT" },
      { name: "PRICE" },
      { name: "RATE" },
      { name: "COMMISSION" },
      { name: "AMOUNT", totalsRowFunction: "sum" },
      { name: "PRICE $" },
      { name: "AMOUNT $", totalsRowFunction: "sum" },
      { name: `PRICE $ + ${data.order.company.commission}%` },
      {
        name: `AMOUNT $ + ${data.order.company.commission}%`,
        totalsRowFunction: "sum",
      },
      { name: "QTY/CTN", totalsRowFunction: "sum" },
      { name: "CTNS", totalsRowFunction: "sum" },
      { name: "L" },
      { name: "W" },
      { name: "H" },
      { name: "N.W" },
      { name: "CBM" },
    ],
    rows: items.map((item, key) => {
      const row = key + 8;
      const cbmPerCarton = item.item.cbm / item.item.cartons;
      const cartonsInContainer = Number(Math.round(item.cbm_in / cbmPerCarton));
      const itemsInContainer = Math.round(
        cartonsInContainer * item.item.quantity_carton
      );

      return [
        item.item.shop_id,
        item.item.number,
        "",
        item.item.description,
        item.item.material,
        itemsInContainer,
        item.item.unit_name.toLowerCase(),
        item.item.unit_price,
        { formula: `F3`, result: exchangeRate },
        { formula: `F4`, result: commission },
        { formula: `F${row}*H${row}`, result: item.item.total_amount },
        {
          formula: `H${row}/I${row}`,
          result: item.item.unit_price / exchangeRate,
        },
        {
          formula: `F${row}*L${row}`,
          result: itemsInContainer * (item.item.unit_price / exchangeRate),
        },
        {
          formula: `L${row}+(L${row}*J${row})`,
          result:
            (item.item.unit_price / exchangeRate) * commission +
            item.item.unit_price / exchangeRate,
        },
        {
          formula: `N${row}*F${row}`,
          result:
            (item.item.unit_price / exchangeRate) *
              itemsInContainer *
              commission +
            (item.item.unit_price / exchangeRate) * itemsInContainer,
        },
        item.item.quantity_carton,
        cartonsInContainer,
        item.item.length,
        item.item.width,
        item.item.height,
        item.item.weight * itemsInContainer,
        item.cbm_in,
      ];
    }),
  });

  ws.getRow(itemList.table.tl.row).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(itemList.table.tl.row).height = 30;

  // add images
  await Promise.all(
    items.map(async (item, key) => {
      ws.getRow(key + 8).height = 50;
      ws.getRow(key + 8).alignment = {
        vertical: "middle",
      };
      const row = key + 7;
      const path = `${data.order.id}/${item.item.id}/`;
      const files = await Storage.list(path);
      if (files.length) {
        const file = await Storage.get(files[0].key, { download: true });
        const img = wb.addImage({
          buffer: file.Body,
          extension: "jpg",
        });
        ws.addImage(img, {
          tl: { col: 2, row },
          ext: { width: 66.5, height: 66.5 },
        });
      }
    })
  );
}

export default generate;
