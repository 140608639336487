import React, { useEffect, useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { AppContext, ModalContext, NotificationContext } from "../context";
import { listCompanys } from "../graphql/queries";
import { deleteCompany } from "../graphql/mutations";
import { onCreateCompany, onDeleteCompany } from "../graphql/subscriptions";
import { Section, Heading, Table, Label } from "../components/PageElements";
import { InviteUserForm } from "../components/UI/Forms";
import { DEFAULT_DATE_FORMAT } from "../config";

function Companies() {
  const modal = useContext(ModalContext);
  const { currentUser } = useContext(AppContext);
  const notification = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);

  const fetchData = async (next = null) => {
    setLoading(true);
    if (!next) {
      setData([]);
    }
    const {
      data: {
        listCompanys: { items, nextToken },
      },
    } = await API.graphql(
      graphqlOperation(listCompanys, {
        limit: 25,
        nextToken: next,
      })
    );
    setData((currentItems) => {
      const newItems = [...currentItems];
      newItems.push(...items);
      return newItems;
    });
    setNext(nextToken);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateCompany)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteCompany)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteCompany: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (company) => company.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, []);

  const columns = [
    {
      key: "number",
      label: "ID",
      type: "label",
      isSortable: true,
    },
    { key: "name", label: "Name", type: "label", isSortable: true },
    { key: "is_system", label: "System?", type: "boolean", isSortable: true },
    {
      key: "createdAt",
      label: "Since",
      type: "date",
      format: DEFAULT_DATE_FORMAT,
      isSortable: true,
    },
  ];

  const actions = [
    {
      label: "Invite User",
      callback: async (row) => {
        modal.showForm(
          <InviteUserForm
            data={{ company: row }}
            beforeSubmit={() => modal.hide()}
            onStore={(data) => {
              notification.success(
                <>
                  You successfully invited{" "}
                  <Label value={data.email} uppercase={false} /> to{" "}
                  <Label value={row.name} uppercase={false} /> company.
                </>
              );
            }}
          />,
          "Invite User"
        );

        return { success: true };
      },
      shouldRender: (row) =>
        !row.is_system || currentUser.role.key.toUpperCase() === "ROOT",
    },
    {
      type: "separator",
      shouldRender: (row) =>
        !row.is_system || currentUser.role.key.toUpperCase() === "ROOT",
    },
    { label: "View", to: ({ id }) => `/companies/edit/${id}` },
    {
      type: "separator",
      shouldRender: () => currentUser.role.key.toUpperCase() === "ROOT",
    },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async (row) => {
        await API.graphql(
          graphqlOperation(deleteCompany, { input: { id: row.id } })
        );
        return { success: true };
      },
      shouldRender: () => currentUser.role.key.toUpperCase() === "ROOT",
    },
  ];

  return (
    <Section type="screen">
      <Heading tag="h1" label="Company Listing" />
      <Table
        loading={loading}
        hasNext={!!next}
        onNext={{
          label: "Load More Companies...",
          handler: () => fetchData(next),
        }}
        columns={columns}
        mainColumn="number"
        data={data}
        actions={actions}
      />
    </Section>
  );
}

export default Companies;
