import React from "react";
import { Link as ReactLink } from "react-router-dom";

import styles from "./Link.module.scss";
import { cl } from "../../../helpers";
import { ConditionalWrapper } from "../../Utils";
import { Label } from "../";

function Link(props) {
  if (typeof props.to !== "function" && typeof props.to !== "string") {
    console.warn("Link to must be function or string.");
    return null;
  }
  if (typeof props.to === "function" && typeof props.to() !== "string") {
    console.warn("To function must return a string!");
    return null;
  }

  const animatedHover = !props.disableAnimation ? styles.animated : null;
  const reverseAnimation = props.reverseAnimation
    ? styles.reverseAnimation
    : null;
  const buttonType = props.buttonType ? styles.btn : null;
  const buttonRounded = props.buttonRounded ? styles.rounded : null;
  const to = typeof props.to === "function" ? props.to() : props.to;

  const useNativeLink =
    to.startsWith("http") || to.startsWith("tel:") || to.startsWith("mailto:");

  const isExternal = to.startsWith("http") || props.external;

  const getLabelIcon = () => {
    if (props.labelIcon) {
      return props.labelIcon;
    }

    if (isExternal && !props.download) {
      return "fas fa-external-link-square-alt";
    }

    if (to.startsWith("tel:")) {
      return "fas fa-phone-alt";
    }

    if (to.startsWith("mailto:")) {
      return "fas fa-mail-bulk";
    }

    if (props.download) {
      return "fas fa-cloud-download-alt";
    }

    return null;
  };

  return (
    <ConditionalWrapper
      condition={useNativeLink}
      wrapper={(children) => (
        <a
          href={to}
          className={cl(
            styles.link,
            styles[props.type],
            animatedHover,
            reverseAnimation,
            buttonType,
            styles[props.buttonType],
            buttonRounded,
            props.className
          )}
          target={isExternal ? "_blank" : null}
          rel="noreferrer"
        >
          {children}
        </a>
      )}
      alternative={(children) => (
        <ReactLink
          to={to}
          className={cl(
            styles.link,
            styles[props.type],
            animatedHover,
            reverseAnimation,
            buttonType,
            styles[props.buttonType],
            buttonRounded,
            props.className
          )}
          target={isExternal ? "_blank" : null}
        >
          {children}
        </ReactLink>
      )}
    >
      {props.label || props.labelIcon ? (
        <Label
          value={props.label}
          size={props.labelSize}
          type={props.labelType}
          uppercase={props.labelUppercase}
          icon={getLabelIcon()}
          className={props.labelClassName}
        />
      ) : null}
      {props.children}
    </ConditionalWrapper>
  );
}

export default Link;
