import React, { useState } from "react";

import styles from "./FormBuilder.module.scss";
import {
  TextInput,
  Textarea,
  Select,
  Checkbox,
  Button,
  Datepicker,
  Radio,
  Upload,
} from "../";
import { runFieldValidation } from "../../../libs/validator";

const FormBuilder = (props) => {
  const [fields, setFields] = useState(props.fields);
  const hasGeneralErrors = props.generalErrors && props.generalErrors.length;

  const generalError = hasGeneralErrors
    ? props.generalErrors.map((error, index) => {
        if (!error) {
          return null;
        }

        return (
          <span className={styles.error} key={`general_error_${index}`}>
            <span>{error}</span>
          </span>
        );
      })
    : null;

  /**
   * Runs when a form field value changes
   * @param {object} field
   * @param {string|number} value
   */
  const fieldChangeHandler = (field, value) => {
    setFields((currentFields) => {
      // sync field
      const updatedFields = { ...currentFields };
      const updatedField = { ...updatedFields[field] };
      updatedField.value = value;
      updatedField.errors = [];
      updatedFields[field] = updatedField;

      // call field listener
      const fieldListener = updatedFields[field].listener;
      if (fieldListener && typeof fieldListener === "function") {
        fieldListener(updatedField.value);
      }

      // call fields listener
      const fieldsListener = props.onFieldsChange;
      if (fieldsListener && typeof fieldsListener === "function") {
        fieldsListener(updatedFields);
      }

      return updatedFields;
    });
  };

  /**
   * Checks if field has errors and updates the state
   * @param {object} field
   */
  const updateFieldErrorState = (field) => {
    setFields((currentFields) => {
      const updatedFields = { ...currentFields };
      const updatedField = { ...updatedFields[field] };

      const fieldErrors = runFieldValidation(
        updatedField,
        updatedField.value,
        updatedFields
      );
      updatedField.errors = fieldErrors;
      updatedFields[field] = updatedField;

      return updatedFields;
    });
  };

  const checkFieldCondition = (_field) => {
    if (_field.condition) {
      const conditionField = _field.condition.when;
      const conditionEq = _field.condition.eq;
      if (fields[conditionField].value !== conditionEq) {
        return false;
      }
    }

    return true;
  };

  /**
   * Runs a validation on the whole form
   */
  const checkForm = () => {
    let isFormValid = true;
    for (let fieldKey in fields) {
      if (!checkFieldCondition(fields[fieldKey])) {
        continue;
      }

      updateFieldErrorState(fieldKey);
      const fieldErrors = runFieldValidation(
        fields[fieldKey],
        fields[fieldKey].value,
        fields
      );
      isFormValid = fieldErrors.length === 0 && isFormValid;
    }

    return isFormValid;
  };

  /**
   * When the submit button is pressed
   * @param {event} e
   */
  const submitHandler = (e) => {
    e.preventDefault();

    if (!checkForm()) {
      return;
    }

    const formData = {};
    for (let fieldKey in fields) {
      if (!checkFieldCondition(fields[fieldKey])) {
        continue;
      }

      switch (fields[fieldKey].component) {
        case "select":
          formData[fieldKey] = fields[fieldKey].value.value || "";
          break;
        default:
          formData[fieldKey] = fields[fieldKey].value;
      }
    }

    props.submit.handler(formData);
  };

  /**
   * Form Fields
   */
  const fieldsDataArray = [];
  for (let fieldKey in fields) {
    if (!checkFieldCondition(fields[fieldKey])) {
      continue;
    }

    fieldsDataArray.push({
      id: fieldKey,
      config: fields[fieldKey],
    });
  }

  const formFields = fieldsDataArray.map((field) => {
    const isRequiredField =
      field.config.validation && field.config.validation.required;
    let fieldComponent;

    switch (field.config.component) {
      case "text":
        fieldComponent = (
          <TextInput
            key={field.id}
            name={field.id}
            {...field.config.props}
            value={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(e) => fieldChangeHandler(field.id, e.target.value)}
            onBlur={() => updateFieldErrorState(field.id)}
          />
        );
        break;
      case "textarea":
        fieldComponent = (
          <Textarea
            key={field.id}
            name={field.id}
            {...field.config.props}
            value={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(e) => fieldChangeHandler(field.id, e.target.value)}
            onBlur={() => updateFieldErrorState(field.id)}
          />
        );
        break;
      case "select":
        fieldComponent = (
          <Select
            key={field.id}
            {...field.config.props}
            value={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(option) => fieldChangeHandler(field.id, option)}
            onBlur={() => updateFieldErrorState(field.id)}
          />
        );
        break;
      case "checkbox":
        fieldComponent = (
          <Checkbox
            key={field.id}
            name={field.id}
            {...field.config.props}
            checked={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(e) => fieldChangeHandler(field.id, e.target.checked)}
          />
        );
        break;
      case "datepicker":
        fieldComponent = (
          <Datepicker
            key={field.id}
            {...field.config.props}
            value={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(val) => fieldChangeHandler(field.id, val)}
          />
        );
        break;
      case "radio":
        fieldComponent = (
          <Radio
            key={field.id}
            name={field.id}
            {...field.config.props}
            value={field.config.value}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(e) => fieldChangeHandler(field.id, e.target.value)}
          />
        );
        break;
      case "upload":
        fieldComponent = (
          <Upload
            key={field.id}
            name={field.id}
            {...field.config.props}
            label={field.config.props.label}
            errors={field.config.errors}
            required={isRequiredField}
            onChange={(e) => fieldChangeHandler(field.id, e.target.files)}
            onBlur={() => updateFieldErrorState(field.id)}
          />
        );
        break;
      default:
        throw new Error(`Invalid field component: ${field.config.component}`);
    }

    return fieldComponent;
  });

  return (
    <>
      <p className={styles.requiredNote}>* Indicates required field</p>
      <form onSubmit={submitHandler} noValidate>
        {formFields}
        <div>
          <Button
            type="primaryBranded"
            fullWidth
            disabled={props.disabled}
            label={props.submit.label}
          />
        </div>
        {generalError}
        {props.disclaimer && (
          <div className={styles.disclaimer}>{props.disclaimer}</div>
        )}
      </form>
    </>
  );
};

export default FormBuilder;
