import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";

import { createRate } from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";

function ExchangeRate(props) {
  const [loading, setLoading] = useState(false);
  const data = props.data || {};

  const fields = {
    date: {
      value: "",
      component: "datepicker",
      props: {
        label: "Date",
        instruction: "Select Date",
      },
      validation: {
        required: true,
      },
    },
    usd_rate: {
      value: data.usd_rate ?? "",
      component: "text",
      props: {
        placeholder: "USD/CNY",
        label: "1 USD =",
        instruction: "1 USD =",
        type: "currency",
        min: 0,
      },
      validation: {
        required: true,
        min: 0,
      },
    },
    euro_rate: {
      value: data.euro_rate ?? "",
      component: "text",
      props: {
        placeholder: "EUR/CNY",
        label: "1 EUR =",
        instruction: "1 EUR =",
        type: "currency",
        min: 0,
      },
      validation: {
        required: true,
        min: 0,
      },
    },
  };

  if (data.date) {
    delete fields.date;
  }

  const submitHandler = async (input) => {
    setLoading(true);
    props.onSave && props.onSave();
    const {
      data: { createRate: response },
    } = await API.graphql(
      graphqlOperation(createRate, {
        input: {
          date: data.date ?? moment(input.date).format("YYYY-MM-DD"),
          usd_rate: input.usd_rate,
          euro_rate: input.euro_rate,
        },
      })
    );
    props.afterSave && props.afterSave(response);
    setLoading(false);
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: submitHandler,
        label: "Save",
      }}
    />
  );
}

export default ExchangeRate;
