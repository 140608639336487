import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";

import "pure-react-carousel/dist/react-carousel.es.css";
import { Loader, Section, Heading, Paragraph } from "../../../PageElements";

const CDN_URL = process.env.REACT_APP_CDN;

function Gallery(props) {
  const { path } = props;
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    setLoading(true);
    Storage.list(path).then((files) => {
      setPictures(files);
      setLoading(false);
    });
  }, [path]);

  if (loading) {
    return (
      <Section type="first">
        <Loader size="200" label="Loading gallery..." />
      </Section>
    );
  }

  if (!pictures.length) {
    return (
      <Section type="first">
        <Section type="center">
          <Heading tag="h1" label="No Pictures" />
          <Paragraph>
            There are no pictures uploaded for this item yet...
          </Paragraph>
        </Section>
      </Section>
    );
  }

  return (
    <CarouselProvider
      naturalSlideWidth={200}
      naturalSlideHeight={200}
      totalSlides={pictures.length}
    >
      <Slider>
        {pictures.map((file, key) => {
          return (
            <Slide index={key} key={key}>
              <img
                src={`${CDN_URL}/${file.key}`}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Slide>
          );
        })}
      </Slider>
      {pictures.length > 1 && (
        <>
          <ButtonBack>Prev</ButtonBack>
          <ButtonNext>Next</ButtonNext>
        </>
      )}
    </CarouselProvider>
  );
}

export default Gallery;
