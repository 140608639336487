import React, { useState, useEffect, useRef, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { debounce } from "lodash";

import { listCompanys } from "../../../../graphql/queries";
import { TextInput } from "../../../FormElements";
import { Table, Section } from "../../../PageElements";
import styles from "./CompanyFinderWidget.module.scss";

function CompanyFinderWidget(props) {
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const { isSystem } = props;

  const columns = [
    {
      key: "name",
      label: "Name",
      type: "renderer",
      renderer: (val, row) =>
        `${val} / #${row.number} [${
          row.is_system ? "System Company" : "Client Company"
        }]`,
    },
  ];

  const performSearch = async (query) => {
    if (!query) {
      getData();
      return;
    }

    setSearching(true);

    const systemFilter =
      isSystem !== undefined ? { is_system: { eq: isSystem } } : {};

    const {
      data: {
        listCompanys: { items },
      },
    } = await API.graphql({
      query: listCompanys,
      variables: {
        filter: {
          ...systemFilter,
          or: [
            { searchableName: { beginsWith: query } },
            { number: { eq: query } },
          ],
        },
      },
    });

    setData(items);
    setSearching(false);
  };

  const performDelayedSearch = useRef(debounce(performSearch, 500)).current;

  const searchHandler = async (userInput) => {
    setSearch(userInput);
    const query = userInput.trim().toLowerCase();
    await performDelayedSearch(query);
  };

  const getData = useCallback(async () => {
    setLoading(true);
    const filter =
      isSystem !== undefined ? { filter: { is_system: { eq: isSystem } } } : {};

    const {
      data: {
        listCompanys: { items },
      },
    } = await API.graphql(
      graphqlOperation(listCompanys, {
        limit: 5,
        ...filter,
      })
    );

    setData(items);
    setLoading(false);
  }, [isSystem]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <TextInput
        label="Find Company"
        value={search}
        placeholder={
          <>
            <i className="fa fa-search"></i> Search company by name or number...
          </>
        }
        branded={true}
        onChange={(e) => searchHandler(e.target.value)}
        wrapperClassName={styles.searchWrapper}
        containerClassName={styles.searchContainer}
        className={styles.searchField}
      />
      {searching && (
        <Section type="center" className={styles.loading}>
          Searching...
        </Section>
      )}
      {!searching && (
        <Table
          loading={loading}
          columns={columns}
          mainColumn="name"
          data={data}
          containerClassName={styles.tableContainer}
          showTableHead={false}
          onRowClick={(client) =>
            props.onSelect ? props.onSelect(client) : null
          }
        />
      )}
    </>
  );
}

export default CompanyFinderWidget;
