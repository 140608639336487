import { fonts } from "../DocumentGenerator";

async function generate(wb, ws, data) {
  ws.getColumn(4).width = 25;
  ws.getColumn(6).width = 40;

  const accessories = data.container.accessories.items;
  const accessoriesList = ws.addTable({
    name: "AccessoriesListTable",
    ref: "A18",
    headerRow: true,
    totalsRow: true,
    style: {
      theme: "TableStyleMedium1",
      showRowStripes: false,
    },
    columns: [
      { name: "ITEM NO", totalsRowLabel: "TOTAL INVOICE AMOUNT:" },
      { name: "DESCRIPTION" },
      { name: "QTY" },
      { name: "UNIT" },
      { name: "PRICE" },
      { name: "AMOUNT", totalsRowFunction: "sum" },
    ],
    rows: accessories.map((item, key) => {
      const row = key + 19;
      return [
        item.number,
        item.description,
        item.quantity,
        item.unit_name,
        item.unit_price,
        { formula: `C${row}*E${row}`, result: item.amount },
      ];
    }),
  });

  ws.getRow(accessoriesList.table.tl.row).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getRow(accessoriesList.table.tl.row).height = 30;
  ws.getRow(accessoriesList.table.tl.row).font = fonts.table;
}

export default generate;
