import React, { useContext } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";

import {
  createCorrespondence,
  createMessage,
  createNotification,
} from "../../../graphql/mutations";
import { FormBuilder } from "../../FormElements";
import { AppContext, OrganizationContext } from "../../../context";

function NewInquiryForm(props) {
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const data = props.data ?? [];
  const errors = [];

  const fields = {
    title: {
      value: data.title ?? "",
      component: "text",
      props: {
        label: "Title",
        instruction: "Enter Inquiry Title",
      },
      validation: {
        required: true,
      },
    },
    message: {
      value: data.message ?? "",
      component: "textarea",
      props: {
        label: "Message",
        instruction: "Enter Your Message",
      },
      validation: {
        required: true,
      },
    },
    attachments: {
      value: data.attachments ?? "",
      component: "upload",
      props: {
        label: "Attachments",
        instruction: "Attach files",
      },
      validation: {},
    },
  };

  const submitHandler = async (data) => {
    props.onSave(data);
    const hasFiles = !!data.attachments;
    const author = {
      id: currentUser.sub,
      name: currentUser.fullname,
      company: organization.name,
    };

    const {
      data: { createCorrespondence: newCorrespondence },
    } = await API.graphql(
      graphqlOperation(createCorrespondence, {
        input: {
          author_id: author.id,
          sender: author,
          type: "INQUIRY",
          group_id: organization.id,
          title: data.title,
        },
      })
    );

    const {
      data: { createMessage: newMessage },
    } = await API.graphql(
      graphqlOperation(createMessage, {
        input: {
          group_id: organization.id,
          correspondence_id: newCorrespondence.id,
          sender: author,
          text: data.message,
          vars: JSON.stringify({ hasFiles }),
        },
      })
    );

    if (hasFiles) {
      await Promise.all(
        Array.from(data.attachments).map(async (_file) => {
          try {
            await Storage.put(
              `/inquiries/${newCorrespondence.id}/${newMessage.id}/${_file.name}`,
              _file
            );
          } catch (error) {
            console.error("Error uploading file: ", error);
          }
        })
      );
    }

    API.graphql(
      graphqlOperation(createNotification, {
        input: {
          receiver: organization.managing_company_id,
          title: `New Client Inquiry`,
          content: `${currentUser.fullname} sent a new inquiry.`,
          icon: `fas fa-comment-medical`,
          type: "COMMS",
          to: `/inquiries/${newCorrespondence.id}`,
        },
      })
    );

    props.afterSave(newCorrespondence);
  };

  return (
    <FormBuilder
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Send Inquiry",
      }}
      disclaimer="The system operator is going to receiver your name and company."
      generalErrors={errors}
    />
  );
}

export default NewInquiryForm;
