import React, { useEffect, useState, useContext } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getInvite } from "../graphql/queries";
import { OverlayContext } from "../context";
import { SigninTransition } from "../components/UI/Transitions";
import { SignupForm } from "../components/UI/Forms";
import { Heading, Link, Paragraph, Loader } from "../components/PageElements";

function Signup() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const { code } = useParams();
  const history = useHistory();
  const overlay = useContext(OverlayContext);

  const fetchInvite = async () => {
    const {
      data: { getInvite: invite },
    } = await API.graphql({
      query: getInvite,
      authMode: "AWS_IAM",
      variables: {
        id: code,
      },
    });
    setData(invite);
    setLoading(false);
  };

  useEffect(
    () => fetchInvite(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return <Loader size="160" label="Checking your invitation..." />;
  }

  if (data) {
    return (
      <div style={{ width: "100%" }}>
        <Heading label={`Join ${data.company_name}`} tag="h1" />
        <SignupForm
          data={data}
          beforeSignup={() => {
            overlay.transition(<SigninTransition duration={10000} />);
            overlay.setShow(true);
          }}
          afterSignup={() => {
            history.replace("/");
            overlay.hide();
          }}
          onError={() => overlay.hide()}
        />
        <br />
        <Link to="/" label="Already have account?" labelUppercase={false} />
      </div>
    );
  }

  return (
    <>
      <Heading label="Whoops! Invalid Invitation :(" tag="h1" />
      <Paragraph>
        It looks like this invitation has expired or never has existed in the
        first place.
      </Paragraph>
      <Paragraph>
        If you already signed up via this link you can go directly to{" "}
        <Link to="/" label="login" />. If you are coping the link. make sure
        that you copy all the characters.
      </Paragraph>
    </>
  );
}

export default Signup;
