import React, { useState, useContext, useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";

import styles from "./Table.module.scss";
import linkStyles from "../Link/Link.module.scss";
import { Link, Label, InlineSeparator, Icon } from "../";
import { Button } from "../../FormElements";
import { ModalContext, NotificationContext } from "../../../context";
import { cl, accessPath } from "../../../helpers";
import cellViewers from "./Renderers/Viewers";
import cellEditors from "./Renderers/Editors";

Table.propTypes = {
  // general props
  identifierKey: PropTypes.string.isRequired, // default "id"
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        "text",
        "number",
        "price",
        "select",
        "pictures",
        "cellGallery",
        "boolean",
        "label",
        "phone",
        "price",
        "email",
        "date",
        "text",
        "renderer",
      ]).isRequired,
      placeholder: PropTypes.string,
      formula: PropTypes.func,
      readOnly: PropTypes.bool,

      // type specific
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.shape.isRequired,
          value: PropTypes.shape.isRequired,
        })
      ), // for select type
      symbol: PropTypes.string, // for price type
      renderer: PropTypes.func, // for renderer type
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["callback", "link", "separator", "copy"]),
      callback: PropTypes.func,
      callbackType: PropTypes.oneOf(["delete"]),
      renderer: PropTypes.func,
      label: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      shouldRender: PropTypes.func,
      copyFormatter: PropTypes.func, // for copy type
    })
  ),
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      formula: PropTypes.func.isRequired,
    })
  ),
  summaryActions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["callback", "separator"]),
      label: PropTypes.string,
      renderer: PropTypes.func,
      callback: PropTypes.func,
      shouldRender: PropTypes.func,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  mainColumn: PropTypes.string.isRequired,
  showTableHead: PropTypes.bool.isRequired, // default true
  onRowClick: PropTypes.func,
  containerClassName: PropTypes.string,

  // editable props
  editable: PropTypes.bool.isRequired, // default false
  onDataChange: PropTypes.func, // required if table is editable
  onFilesUpload: PropTypes.func,
  onAdd: PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
  }),

  // load more props
  hasNext: PropTypes.bool,
  onNext: PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
  }),
};

Table.defaultProps = {
  identifierKey: "id",
  showTableHead: true,
  editable: false,
};

function Table(props) {
  const modal = useContext(ModalContext);
  const notification = useContext(NotificationContext);

  const identifierKey = props.identifierKey;
  const mainColumn = props.columns.find(
    (column) => column.key === props.mainColumn
  );
  const slaveColumns = props.columns.filter(
    (column) => column.key !== props.mainColumn
  );
  const [expandedColumns, setExpandedColumns] = useState([]);

  const isRowExpanded = useCallback(
    (columnId) =>
      expandedColumns.findIndex(
        (expandedColumnId) => expandedColumnId === columnId
      ) > -1,
    [expandedColumns]
  );

  const toggleRow = useCallback(
    (columnId) => {
      setExpandedColumns((currentlyExpandedColumns) => {
        const columnExpandIndex = currentlyExpandedColumns.findIndex(
          (expandedColumnId) => expandedColumnId === columnId
        );

        if (columnExpandIndex > -1) {
          const expandedColumns = [...currentlyExpandedColumns];
          expandedColumns.splice(columnExpandIndex, 1);
          return expandedColumns;
        }

        return [...currentlyExpandedColumns, columnId];
      });
    },
    [setExpandedColumns]
  );

  const renderCell = (row, column, disableEdit = false) => {
    // get value
    let val = accessPath(row, column.key);
    if (column.formula && typeof column.formula === "function") {
      val = column.formula(val, row, column);
    }

    // custom renderer
    const renderer = column.renderer;
    if (renderer && typeof renderer === "function") {
      return renderer(val, row, column);
    }

    // editor or viewer renderer
    const cellRenderers =
      props.editable && !disableEdit ? cellEditors : cellViewers;
    if (column.type && cellRenderers[column.type]) {
      return cellRenderers[column.type](val, row, column, identifierKey, props);
    }

    return cellRenderers.text(val, row, column);
  };

  const renderAction = (action, actionKey, row, rowKey) => {
    if (
      action.shouldRender &&
      typeof action.shouldRender === "function" &&
      action.shouldRender(row) === false
    ) {
      return null;
    }

    // separator
    if (action.type === "separator") {
      return action.renderer && typeof action.renderer === "function" ? (
        action.renderer(row)
      ) : (
        <InlineSeparator key={`row-${rowKey}-separator-${actionKey}`} />
      );
    }

    // copy action
    if (
      action.type === "copy" &&
      action.copyFormatter &&
      typeof action.copyFormatter === "function"
    ) {
      return (
        <CopyToClipboard
          key={`row-${rowKey}-action-${actionKey}`}
          onCopy={() => notification.quickConfirm("Copied to clipboard.")}
          text={action.copyFormatter(row)}
        >
          <span
            className={cl(
              linkStyles.link,
              linkStyles.branded,
              linkStyles.animated,
              linkStyles.reverseAnimation
            )}
          >
            <label
              style={{
                textTransform: "uppercase",
                fontWeight: 200,
              }}
            >
              {action.renderer && typeof action.renderer === "function"
                ? action.renderer(row)
                : action.label}
            </label>
          </span>
        </CopyToClipboard>
      );
    }

    // callback
    if (
      action.callback ||
      (action.callbackType && actionCallbackHandlers[action.callbackType])
    ) {
      return (
        <Button
          key={`row-${rowKey}-action-${actionKey}`}
          label={
            action.renderer && typeof action.renderer === "function"
              ? action.renderer(row)
              : action.label
          }
          callback={() => {
            if (
              action.callbackType &&
              actionCallbackHandlers[action.callbackType] &&
              typeof actionCallbackHandlers[action.callbackType] === "function"
            ) {
              return actionCallbackHandlers[action.callbackType](action, row);
            }

            return action.callback(row);
          }}
          reverseAnimation={true}
          type="brandedLink"
        />
      );
    }

    // link
    if (action.to) {
      const to =
        typeof action.to === "function" ? () => action.to(row) : action.to;
      return (
        <Link
          key={`row-${rowKey}-action-${actionKey}`}
          to={to}
          label={
            action.renderer && typeof action.renderer === "function"
              ? action.renderer(row)
              : action.label
          }
          reverseAnimation={true}
          type="branded"
        />
      );
    }

    return null;
  };

  const renderSummaryCell = (column) => {
    const sumFml = props.summary?.find(
      ({ key }) => key === column.key
    )?.formula;
    if (!sumFml) {
      return null;
    }

    return sumFml(props.data, column);
  };

  const renderSummaryAction = (action, actionKey) => {
    if (
      action.shouldRender &&
      typeof action.shouldRender === "function" &&
      action.shouldRender() === false
    ) {
      return null;
    }

    // separator
    if (action.type === "separator") {
      return action.renderer && typeof action.renderer === "function" ? (
        action.renderer()
      ) : (
        <InlineSeparator key={`summary-row-separator-${actionKey}`} />
      );
    }

    // callback
    if (action.callback) {
      return (
        <Button
          key={`summary-row-action-${actionKey}`}
          label={
            action.renderer && typeof action.renderer === "function"
              ? action.renderer()
              : action.label
          }
          callback={() => {
            return action.callback(props.data, action);
          }}
          reverseAnimation={true}
          type="brandedLink"
        />
      );
    }

    return null;
  };

  const actionCallbackHandlers = {
    delete: (action, rowData) => {
      modal.reset();
      modal.confirm({
        title:
          action.getConfirmTitle && typeof action.getConfirmTitle === "function"
            ? action.getConfirmTitle(rowData)
            : `Delete ${rowData[mainColumn.key]}?`,
        onConfirm: () => {
          if (action.callback && typeof action.callback === "function") {
            action.callback(rowData).then((callbackStatus) => {
              if (callbackStatus.success) {
                notification.success(
                  action.getSuccessNotification &&
                    typeof action.getSuccessNotification === "function" ? (
                    action.getSuccessNotification(rowData)
                  ) : (
                    <>
                      You successfully deleted{" "}
                      <Label
                        value={rowData[mainColumn.key]}
                        uppercase={false}
                      />
                    </>
                  )
                );
              }
            });
          }
        },
      });
    },
  };

  const showTableHead = props.showTableHead;
  const showSummaryRow = props.summary && props.summary.length > 0;
  const showLoadingIndicator = props.loading;
  const showNoDataIndicator =
    !props.loading && (!props.data || props.data.length === 0);
  const showLoadMoreAction =
    !props.loading &&
    !props.editable &&
    props.hasNext &&
    props.onNext &&
    typeof props.onNext.handler === "function";
  const showAddMoreAction =
    props.editable &&
    props.onAdd &&
    props.onAdd.label &&
    props.onAdd.handler &&
    typeof props.onAdd.handler === "function";

  const hasRowClickAction =
    props.onRowClick && typeof props.onRowClick === "function";

  return (
    <div
      className={cl(
        styles.container,
        props.editable ? styles.editable : null,
        props.containerClassName
      )}
    >
      <div className={props.editable ? styles.editableArea : null}>
        <div
          className={cl(styles.table, props.editable ? styles.editable : null)}
        >
          {/* Table Header Row */}
          {showTableHead && (
            <div className={styles.head}>
              <div className={styles.row}>
                <div className={cl(styles.cell, styles.masterCell)}>
                  <Label
                    value={mainColumn.label}
                    // icon={mainColumn.isSortable ? "fas fa-sort" : null}
                  />
                </div>
                <div className={cl(styles.slaveCells, "not-mobile")}>
                  {slaveColumns.map((column, columnKey) => (
                    <div
                      className={styles.cell}
                      key={`head-column-${columnKey}`}
                    >
                      <Label
                        value={column.label}
                        // icon={column.isSortable ? "fas fa-sort" : null}
                      />
                    </div>
                  ))}
                  {props.actions && props.actions.length ? (
                    <div className={cl(styles.cell, styles.actionsCell)}>
                      <Label value="Manage" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {/* Table Rows */}
          {props.data &&
            props.data.map((row, rowKey) => (
              <div
                className={cl(
                  styles.row,
                  props.editable ? styles.editable : null,
                  hasRowClickAction ? styles.clickableRow : null
                )}
                key={`row-${rowKey}`}
                onClick={() => {
                  if (hasRowClickAction) {
                    props.onRowClick(row);
                  }
                }}
              >
                <div
                  className={cl(
                    styles.cell,
                    styles.masterCell,
                    props.editable ? "not-tablet" : null,
                    props.editable ? "not-desktop" : null
                  )}
                  key={`row-${rowKey}-main-column`}
                  onClick={() => toggleRow(row[identifierKey])}
                >
                  {renderCell(row, mainColumn, true)}
                  <Icon
                    className={cl("not-tablet", "not-desktop")}
                    icon={
                      isRowExpanded(row[identifierKey])
                        ? "fas fa-chevron-down"
                        : "fas fa-chevron-right"
                    }
                  />
                </div>
                <div
                  className={cl(
                    styles.slaveCells,
                    isRowExpanded(row[identifierKey]) ? null : "not-mobile"
                  )}
                >
                  {props.editable && (
                    <div
                      className={cl(
                        styles.cell,
                        mainColumn.type === "cellGallery"
                          ? styles.cellGallery
                          : null
                      )}
                      key={`row-${rowKey}-column-${mainColumn.key}-editable`}
                    >
                      <Label
                        value={mainColumn.label}
                        containerClassName={cl(
                          styles.fieldLabel,
                          "not-tablet",
                          "not-desktop"
                        )}
                      />
                      {renderCell(row, mainColumn)}
                    </div>
                  )}
                  {slaveColumns.map((column, columnKey) => (
                    <div
                      className={cl(
                        styles.cell,
                        column.type === "cellGallery"
                          ? styles.cellGallery
                          : null
                      )}
                      key={`row-${rowKey}-column-${columnKey}`}
                    >
                      <Label
                        value={column.label}
                        containerClassName={cl(
                          styles.fieldLabel,
                          "not-tablet",
                          "not-desktop"
                        )}
                      />
                      {renderCell(row, column)}
                    </div>
                  ))}
                  {props.actions && props.actions.length ? (
                    <div className={cl(styles.cell, styles.actionsCell)}>
                      {props.actions.map((action, actionKey) =>
                        renderAction(action, actionKey, row, rowKey)
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          {/* Summary Row */}
          {showSummaryRow && (
            <div className={cl(styles.row, styles.summaryRow)}>
              <div
                className={cl(styles.cell, styles.masterCell)}
                onClick={() => toggleRow("summary")}
              >
                SUMMARY
                <Icon
                  className={cl("not-tablet", "not-desktop")}
                  icon={
                    isRowExpanded("summary")
                      ? "fas fa-chevron-down"
                      : "fas fa-chevron-right"
                  }
                />
              </div>
              <div
                className={cl(
                  styles.slaveCells,
                  isRowExpanded("summary") ? null : "not-mobile"
                )}
              >
                {slaveColumns.map((column, columnKey) => (
                  <div
                    className={cl(
                      styles.cell,
                      column.type === "cellGallery" ? styles.cellGallery : null,
                      Boolean(renderSummaryCell(column)) ? "" : "not-mobile"
                    )}
                    key={`summary-row-column-${columnKey}`}
                  >
                    <Label
                      value={column.label}
                      containerClassName={cl(
                        styles.fieldLabel,
                        "not-tablet",
                        "not-desktop"
                      )}
                    />
                    {renderSummaryCell(column)}
                  </div>
                ))}
                {props.actions && props.actions.length && (
                  <div className={cl(styles.cell, styles.actionsCell)}>
                    {props.summaryActions && props.summaryActions.length
                      ? props.summaryActions.map(renderSummaryAction)
                      : null}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Loading Indicator */}
        {showLoadingIndicator && (
          <div className={styles.table}>
            <div className={cl(styles.row)}>
              <div className={cl(styles.cell, styles.centered)}>Loading...</div>
            </div>
          </div>
        )}
        {/* No Data Indicator */}
        {showNoDataIndicator && (
          <div className={styles.table}>
            <div className={cl(styles.row)}>
              <div className={cl(styles.cell, styles.centered)}>
                {props.noDataMsg || "No data :("}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Load More Action */}
      {showLoadMoreAction && (
        <div className={cl(styles.table, styles.editable)}>
          <div
            className={cl(styles.row, styles.clickableRow, styles.addMore)}
            onClick={() => props.onNext.handler()}
          >
            <div className={cl(styles.cell, styles.centered)}>
              <Label value={props.onNext.label ?? "Load More"} size="small" />
            </div>
          </div>
        </div>
      )}
      {/* Add Row Action */}
      {showAddMoreAction && (
        <div className={cl(styles.table, styles.editable)}>
          <div
            className={cl(styles.row, styles.clickableRow, styles.addMore)}
            onClick={() => props.onAdd.handler()}
          >
            <div className={cl(styles.cell, styles.centered)}>
              <Label value={props.onAdd.label} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Table;
