import React, { Suspense } from "react";

import styles from "./WidgetHub.module.scss";
import { cl } from "../../../helpers";
import { Loader } from "../";

function WidgetHub(props) {
  return (
    <div className={cl(styles.container, props.className)}>
      {props.widgets &&
        props.widgets.map((row, rowIndex) => (
          <div className={styles.row} key={`row-${rowIndex}`}>
            {row.map((column, columnIndex) => {
              if (!column.widget) {
                return (
                  <div
                    key={`row-${rowIndex}-${columnIndex}`}
                    className={cl(styles.widget, styles.center)}
                  >
                    Widget Not Found
                  </div>
                );
              }
              const Widget = React.lazy(() =>
                import(`../../UI/Widgets/${column.widget}`)
              );
              return (
                <Suspense
                  key={`row-${rowIndex}-${columnIndex}`}
                  fallback={
                    <div className={cl(styles.widget, styles.center)}>
                      <Loader size="120" />
                    </div>
                  }
                >
                  <div className={styles.widget}>
                    <Widget {...column.props} />
                  </div>
                </Suspense>
              );
            })}
          </div>
        ))}
    </div>
  );
}

export default WidgetHub;
