import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSkullCrossbones,
  faReceipt,
  faThumbsUp,
  faTasks,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Notification.module.scss";
import { cl } from "../../../helpers";
import { Label } from "../../PageElements";
import { NotificationContext } from "../../../context";

function Notification(props) {
  const context = useContext(NotificationContext);
  const typeIcons = {
    error: faSkullCrossbones,
    progress: faSpinner,
    confirm: faTasks,
    success: faThumbsUp,
    info: faReceipt,
  };

  return (
    <div
      className={cl(
        styles.container,
        styles.bottomRight,
        props.containerClassName
      )}
    >
      {props.notifications.map((notification, key) => (
        <div
          className={cl(styles.notification, styles[notification.type])}
          style={{
            animationPlayState: notification.destroy ? "running" : "paused",
            animationDuration: String(
              notification.destroyDuration / 1000
            ).concat("s"),
          }}
          key={`notification-${key}`}
        >
          <>
            <div className={styles.iconContainer}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={typeIcons[notification.type]} />
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.titleContainer}>
                <Label value={notification.title} />
              </div>
              <div>{notification.content}</div>
            </div>
          </>
          {notification.closable && (
            <div className={styles.closeContainer}>
              <div
                className={styles.close}
                onClick={() => {
                  context.close(notification);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          )}
          <div
            style={{
              animationDuration: String(
                notification.selfDestructTimerDuration / 1000
              ).concat("s"),
            }}
            className={cl(styles.progressContainer)}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default Notification;
