import React from "react";

import styles from "./Loader.module.scss";
import { cl } from "../../../helpers";
import { Label } from "../";

function Loader(props) {
  const size = props.size || 80;

  return (
    <div className={cl(styles.container, props.containerClassName)}>
      <div
        className={cl(styles.loader, props.className)}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {props.label && <Label value={props.label} />}
    </div>
  );
}

export default Loader;
