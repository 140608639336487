import React from "react";

import styles from "./Paragraph.module.scss";
import { cl } from "../../../helpers";

function Paragraph(props) {
  return (
    <p className={cl(styles.paragraph, props.className)}>{props.children}</p>
  );
}

export default Paragraph;
