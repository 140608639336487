import React, { useEffect, useState, useContext, useCallback } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";
import moment from "moment";

import {
  ModalContext,
  NotificationContext,
  AppContext,
  OrganizationContext,
} from "../../../context";
import {
  createOrderContainer,
  updateOrderContainer,
  deleteOrderContainer,
  createContainerItem,
  updateContainerItem,
  deleteContainerItem,
  deleteContainerAccessory,
  updateOrder,
  createCorrespondence,
  createMessage,
  createNotification,
} from "../../../graphql/mutations";
import { InlineSeparator, Table } from "../../PageElements";
import {
  getContainersByOrder,
  getCorrespondence,
  getRate,
} from "../../../graphql/queries";
import { ContainerFileWidget, ContainerItemsWidget } from "../Widgets";
import { Button } from "../../FormElements";
import { EditableAccessoriesTable } from "./";
import { getOrderStatusByValue } from "../../../config";

const UNSAVED_MSG = `You have unsaved container changes. You are going to lose them if you continue.`;

function EditableOrderContainersTable(props) {
  const { order } = props;
  const { currentUser } = useContext(AppContext);
  const { organization } = useContext(OrganizationContext);
  const modal = useContext(ModalContext);
  const notification = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [containers, setContainers] = useState([]);
  const [uploading, setUploading] = useState(0);

  const defaultContainer = {
    isNew: true,
    hasAmendments: false,
    id: uuid(),
    number: 1,
    provider: "",
    provider_id: "",
    invoice_number: "",
    delivery_time: 14,
    end_destination: "",
    document_fee: 0,
    freight_fee: 0,
    telex_dhl_fee: 0,
  };

  const columns = [
    { key: "number", label: "#", placeholder: "123", type: "number" },
    {
      key: "status",
      label: "Status",
      type: "renderer",
      renderer: (_, row) => {
        if (row.isNew) {
          return "NEW";
        } else if (row.delivered_at) {
          return "DELIVERED";
        } else if (row.shipping_date) {
          return "IN TRANSIT";
        } else {
          return "AWAITING TRANSIT";
        }
      },
    },
    { key: "provider", label: "Provider", placeholder: "Maersk", type: "text" },
    {
      key: "provider_id",
      label: "Provider ID",
      placeholder: "XYZZYX",
      type: "text",
    },
    {
      key: "invoice_number",
      label: "Invoice NO.",
      placeholder: "XYZZYX",
      type: "text",
    },
    { key: "delivery_time", label: "Delivery Time (Days)", type: "number" },
    {
      key: "end_destination",
      label: "Port End Destination",
      type: "text",
      placeholder: "Varna, Bulgaria",
    },
    { key: "document_fee", label: "Document Fee", type: "price", symbol: "$" },
    { key: "freight_fee", label: "Freight Fee", type: "price", symbol: "$" },
    {
      key: "telex_dhl_fee",
      label: "Telex/DHL Fee",
      type: "price",
      symbol: "$",
    },
    {
      key: "attachments",
      label: "Contents",
      type: "renderer",
      renderer: (_, row) => {
        if (row.isNew) {
          return "N/A";
        }

        return (
          <div style={{ padding: "0 1rem" }}>
            <Button
              callback={() => manageContainerItems(row)}
              label="Items"
              title="View Container Items"
              reverseAnimation={true}
              type="brandedLink"
            />
            <InlineSeparator />
            <Button
              callback={() => manageContainerFiles(row)}
              label="Files"
              title="View Container Files"
              reverseAnimation={true}
              type="brandedLink"
            />
            <InlineSeparator />
            <Button
              callback={() => manageContainerAccessories(row)}
              label="Accessories"
              title="View Accessories Items"
              reverseAnimation={true}
              type="brandedLink"
            />
          </div>
        );
      },
    },
  ];
  const actions = [
    {
      type: "callback",
      shouldRender: (row) =>
        !row.isNew && (!row.delivered_at || !row.shipping_date),
      renderer: (row) => {
        return row.shipping_date ? "Delivered" : "Ship";
      },
      callback: (input) => {
        if (input.shipping_date) {
          deliverContainer(input);
        } else {
          shipContainer(input);
        }
      },
    },
    {
      shouldRender: (row) =>
        !row.isNew && (!row.delivered_at || !row.shipping_date),
      type: "separator",
    },
    {
      type: "callback",
      renderer: (row) => {
        return row.isNew ? "Save" : "Update";
      },
      callback: (input) => saveContainer(input),
    },
    {
      type: "separator",
    },
    {
      type: "callback",
      renderer: (row) => {
        return row.isNew ? "Remove" : "Delete";
      },
      callback: ({ isNew, id, number, items, accessories }) => {
        modal.confirm({
          title: `Delete container "${number}"?`,
          onConfirm: async () => {
            if (!isNew) {
              // delete container items
              if (items && items.items && items.items.length > 0) {
                await Promise.all(
                  items.items.map(({ id }) =>
                    API.graphql(
                      graphqlOperation(deleteContainerItem, {
                        input: { id },
                      })
                    )
                  )
                );
              }

              // delete container accessories
              if (
                accessories &&
                accessories.items &&
                accessories.items.length > 0
              ) {
                await Promise.all(
                  accessories.items.map(({ id }) =>
                    API.graphql(
                      graphqlOperation(deleteContainerAccessory, {
                        input: { id },
                      })
                    )
                  )
                );
              }

              API.graphql(
                graphqlOperation(deleteOrderContainer, { input: { id } })
              );
            }

            const path = `${props.order.id}/${id}/`;
            Storage.list(path).then(async (files) => {
              if (files.length) {
                await Promise.all(
                  files.map(async (file) => {
                    await Storage.remove(file.key);
                  })
                );
                notification.quickConfirm("Container Files Deleted!");
              }
            });

            setContainers((oldData) => {
              const newData = [...oldData];
              const rowToDelete = newData.findIndex((row) => row.id === id);
              if (rowToDelete > -1) {
                newData.splice(rowToDelete, 1);
              }

              if (newData.length === 0) {
                updateStatus("MANUFACTURING");
              }

              return newData;
            });

            notification.quickConfirm("Container Deleted!");
          },
        });
      },
    },
  ];

  const manageContainerItems = (container) => {
    const containerIdx = container.id
      ? containers.findIndex(({ id }) => id === container.id)
      : 0;

    if (containerIdx === -1) {
      console.warn(
        `Container #${container.id} cannot be found in the current order.`
      );
    }

    modal.reset();
    modal.setSize("large");
    modal.setShouldCloseOnEsc(false);
    modal.setCloseOnBackdropClick(false);

    modal.setBody(
      <ContainerItemsWidget
        containerItems={containers[containerIdx].items.items || []}
        unassignedItems={getUnassignedItems()}
        onUpdate={(containerItems) => {
          containers[containerIdx].items.items = containerItems;
          setContainers(containers);
          manageContainerItems(container);
        }}
        onItemRemove={(itemId) => {
          API.graphql(
            graphqlOperation(deleteContainerItem, {
              input: { id: itemId },
            })
          );
        }}
        readOnly={
          order.status === "ARCHIVE" ||
          currentUser.role.key === "CompanyMember" ||
          currentUser.role.key === "CompanyAdmin"
        }
      />
    );

    if (
      order.status !== "ARCHIVE" &&
      currentUser.role.key !== "CompanyMember" &&
      currentUser.role.key !== "CompanyAdmin"
    ) {
      modal.addAction({
        label: `Save Container #${container.number} Items`,
        callback: () => {
          saveContainerItems(containers[containerIdx]);
          modal.hide();
        },
      });
    }

    modal.show(`Container #${container.number} Items`);
  };

  const manageContainerAccessories = (container) => {
    const containerIdx = container.id
      ? containers.findIndex(({ id }) => id === container.id)
      : 0;

    if (containerIdx === -1) {
      console.warn(
        `Container #${container.id} cannot be found in the current order.`
      );
    }

    modal.reset();
    modal.setSize("large");
    modal.setShouldCloseOnEsc(false);
    modal.setCloseOnBackdropClick(false);
    modal.setBody(
      <EditableAccessoriesTable
        order={order}
        container={container}
        readOnly={
          order.status === "ARCHIVE" ||
          currentUser.role.key === "CompanyMember" ||
          currentUser.role.key === "CompanyAdmin"
        }
      />
    );
    modal.show(`Container #${container.number} Accessories`);
  };

  const getUnassignedItems = () => {
    const unassignedItems = cloneDeep(order.items.items);

    containers.forEach((container) => {
      const containerItems =
        container.items && container.items.items ? container.items.items : [];
      containerItems.forEach((containerItem) => {
        const orderItemKey = unassignedItems.findIndex((orderItem) => {
          return orderItem.id === containerItem.item.id;
        });
        if (orderItemKey > -1) {
          if (unassignedItems[orderItemKey].cbm > containerItem.cbm_in) {
            unassignedItems[orderItemKey].cbm -= containerItem.cbm_in;
          } else {
            unassignedItems.splice(orderItemKey, 1);
          }
        }
      });
    });

    return unassignedItems;
  };

  const saveContainerItems = async (container) => {
    const containerItems = container.items.items || [];
    await Promise.all(
      containerItems.map(async (containerItem) => {
        const operation = containerItem.createdAt
          ? updateContainerItem
          : createContainerItem;
        await API.graphql(
          graphqlOperation(operation, {
            input: {
              id: containerItem.id,
              company_id: order.company.id,
              container_id: container.id,
              cbm_in: containerItem.cbm_in,
              containerItemItemId: containerItem.item.id,
            },
          })
        );
      })
    );
    notification.quickConfirm(
      `Container #${container.number} item list saved.`
    );
  };

  const saveContainer = (container) => {
    const saveTracker = notification.startProgress(
      "Saving container in progress..."
    );
    const operation = container.isNew
      ? createOrderContainer
      : updateOrderContainer;
    API.graphql(
      graphqlOperation(operation, {
        input: {
          id: container.id,
          company_id: order.company.id,
          order_id: order.id,
          number: container.number,
          provider: container.provider,
          provider_id: container.provider_id,
          invoice_number: container.invoice_number,
          delivery_time: container.delivery_time,
          end_destination: container.end_destination,
          document_fee: container.document_fee,
          freight_fee: container.freight_fee,
          telex_dhl_fee: container.telex_dhl_fee,
        },
      })
    )
      .then(({ data }) => {
        const key = container.isNew
          ? "createOrderContainer"
          : "updateOrderContainer";
        const response = data[key];
        setContainers((oldData) => {
          const newData = [...oldData];
          const rowToEdit = newData.findIndex((row) => row.id === container.id);
          if (rowToEdit > -1) {
            newData[rowToEdit] = response;
          }
          return newData;
        });
        notification.updateProgress(
          saveTracker,
          100,
          container.isNew ? `Container created.` : `Container updated.`
        );

        if (order.status === "MANUFACTURING" && containers.length > 0) {
          updateStatus("AWAITING_TRANSIT");
        }
      })
      .catch(({ errors }) => {
        notification.cancelProgress(saveTracker);
        errors.forEach((err) =>
          notification.error(`Error saving container: ${err.message}`)
        );
      });
  };

  const shipContainer = async (container) => {
    const today = moment().format("YYYY-MM-DD");

    const {
      data: { getRate: todayRate },
    } = await API.graphql(
      graphqlOperation(getRate, {
        date: today,
      })
    );

    if (!todayRate) {
      notification.error(
        `There is no exchange rate for today "${today}". Please, add the exchange rates for the day before shipping the container!`
      );
      return;
    }

    if (!container.provider) {
      notification.error(`Please, select container provider!`);
      return;
    }

    if (!container.provider_id) {
      notification.error(`Please, enter the container provider ID!`);
      return;
    }

    if (!container.invoice_number) {
      notification.error(`Please, enter the invoice number!`);
      return;
    }

    if (!container.end_destination) {
      notification.error(
        `Please, enter the end destination for this container!`
      );
      return;
    }

    if (!container.freight_fee) {
      notification.error(`Please, enter the freight fee!`);
      return;
    }

    if (container.items.items.length === 0) {
      notification.error(
        `This container is empty! Please, add items before shipping.`
      );
      return;
    }

    saveContainer(container);

    API.graphql(
      graphqlOperation(updateOrderContainer, {
        input: {
          id: container.id,
          invoice_date: today,
          shipping_date: today,
        },
      })
    ).then(({ data }) => {
      setContainers((oldData) => {
        const newData = [...oldData];
        const rowToEdit = newData.findIndex((row) => row.id === container.id);
        if (rowToEdit > -1) {
          newData[rowToEdit] = data.updateOrderContainer;
        }

        return newData;
      });
      const hasAwaiting =
        containers.filter((c) => c.id !== container.id && !c.shipping_date)
          .length > 0;
      const unassignedCount = getUnassignedItems().length;

      if (order.status === "AWAITING_TRANSIT") {
        if (hasAwaiting) {
          // has other containers waiting to be in transit
          updateStatus("PARTIALLY_IN_TRANSIT");
        } else {
          if (!unassignedCount) {
            // all containers and items are in transit (for 1 container)
            updateStatus("IN_TRANSIT");
          }
        }
      }

      if (order.status === "PARTIALLY_IN_TRANSIT") {
        if (!hasAwaiting && !unassignedCount) {
          // all containers and items are in transit (for many containers)
          updateStatus("IN_TRANSIT");
        }
      }

      notification.quickConfirm(
        `Container #${container.number} marked as shipped.`
      );
    });

    const [correspondence, author] = await fetchCorrespondence();
    API.graphql(
      graphqlOperation(createMessage, {
        input: {
          group_id: order.company.id,
          correspondence_id: correspondence.id,
          sender: author,
          template: "ORDER-SHIP-CONTAINER",
          vars: JSON.stringify({
            number: container.number,
          }),
        },
      })
    );
  };

  const deliverContainer = async (container) => {
    API.graphql(
      graphqlOperation(updateOrderContainer, {
        input: {
          id: container.id,
          delivered_at: moment().format("YYYY-MM-DD"),
        },
      })
    ).then(({ data }) => {
      setContainers((oldData) => {
        const newData = [...oldData];
        const rowToEdit = newData.findIndex((row) => row.id === container.id);
        if (rowToEdit > -1) {
          newData[rowToEdit] = data.updateOrderContainer;
        }

        return newData;
      });
      const hasNotDelivered =
        containers.filter((c) => c.id !== container.id && !c.delivered_at)
          .length > 0;
      const unassignedCount = getUnassignedItems().length;

      if (
        order.status === "PARTIALLY_IN_TRANSIT" ||
        order.status === "IN_TRANSIT"
      ) {
        if (hasNotDelivered) {
          // has other containers waiting to be delivered
          updateStatus("PARTIALLY_DELIVERED");
        } else {
          if (!unassignedCount) {
            // all containers and items are in delivered (for 1 container)
            updateStatus("DELIVERED");
          }
        }
      }

      if (order.status === "PARTIALLY_DELIVERED") {
        if (!hasNotDelivered && !unassignedCount) {
          // all containers and items are delivered (for many containers)
          updateStatus("DELIVERED");
        }
      }

      notification.quickConfirm(
        `Container #${container.number} marked as delivered.`
      );
    });
    const [correspondence, author] = await fetchCorrespondence();
    API.graphql(
      graphqlOperation(createMessage, {
        input: {
          group_id: order.company.id,
          correspondence_id: correspondence.id,
          sender: author,
          template: "ORDER-DELIVER-CONTAINER",
          vars: JSON.stringify({
            number: container.number,
            end_destination: container.end_destination,
          }),
        },
      })
    );
  };

  const uploadFile = (e, container) => {
    Array.from(e.target.files).forEach(async (file) => {
      const tracker = notification.startProgress(`Starting file upload...`);
      setUploading((uploading) => uploading + 1);
      Storage.put(`files/${container.id}/${file.name}`, file, {
        progressCallback(p) {
          const progress = ((p.loaded / p.total) * 100).toFixed();
          notification.updateProgress(tracker, progress, `File upload done!`);
        },
      })
        .then(async () => {
          e.target.value = "";
          setUploading((uploading) => uploading - 1);
          manageContainerFiles(container);
        })
        .catch((err) => {
          notification.error(`Error uploading file: ${err}`);
          setUploading((uploading) => uploading - 1);
        });
    });
  };

  const manageContainerFiles = (container) => {
    modal.reset();
    modal.setBody(<ContainerFileWidget order={order} container={container} />);
    modal.show(`Container #${container.number} Files`);
    if (order.status !== "ARCHIVE") {
      modal.addAction({
        label: `Upload File`,
        type: "upload",
        callback: (e) => uploadFile(e, container),
      });
    }
  };

  const onDataChange = (id, field, value) => {
    setContainers((oldData) => {
      const newData = [...oldData];
      const rowToEdit = newData.find((row) => row.id === id);
      if (rowToEdit) {
        rowToEdit.hasAmendments = true;
        rowToEdit[field] = value;
      }

      return newData;
    });
  };

  const onAdd = () => {
    const unassignedCount = getUnassignedItems().length;
    if (!unassignedCount) {
      notification.error(
        `All items in this order are already assigned into containers.`
      );
      return;
    }
    setContainers((oldData) => {
      const newData = [...oldData];
      const newItem = { ...defaultContainer };
      if (oldData.length > 0 && oldData[oldData.length - 1]) {
        newItem.number = parseInt(oldData[oldData.length - 1].number) + 1;
      }
      newData.push(newItem);

      return newData;
    });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: {
        getContainersByOrder: { items: containersData },
      },
    } = await API.graphql(
      graphqlOperation(getContainersByOrder, { order_id: order.id })
    );
    containersData.sort(({ number: n1 }, { number: n2 }) => {
      return n1 > n2 ? 1 : -1;
    });
    setContainers(containersData);
    setLoading(false);
  }, [order]);

  const fetchCorrespondence = useCallback(async () => {
    let {
      data: { getCorrespondence: correspondence },
    } = await API.graphql(
      graphqlOperation(getCorrespondence, { id: order.id })
    );
    const author = {
      id: currentUser.sub,
      name: currentUser.fullname,
      company: organization.name,
    };

    if (!correspondence) {
      const {
        data: { createCorrespondence: newCorrespondence },
      } = await API.graphql(
        graphqlOperation(createCorrespondence, {
          input: {
            id: order.id,
            author_id: author.id,
            sender: author,
            type: "ORDER",
            group_id: order.company.id,
            title: `Order #${order.number} Change Log`,
          },
        })
      );
      correspondence = newCorrespondence;
    }
    return [correspondence, author];
  }, [order, currentUser, organization]);

  const updateStatus = useCallback(
    async (newStatusValue) => {
      const oldStatus = getOrderStatusByValue(order.status);
      const newStatus = getOrderStatusByValue(newStatusValue);

      API.graphql(
        graphqlOperation(updateOrder, {
          input: {
            id: order.id,
            status: newStatus.value,
          },
        })
      );
      const [correspondence, author] = await fetchCorrespondence();
      API.graphql(
        graphqlOperation(createMessage, {
          input: {
            group_id: order.company.id,
            correspondence_id: correspondence.id,
            sender: author,
            template: "ORDER-STATUS-CHANGE",
            vars: JSON.stringify({
              prev: oldStatus.value,
              new: newStatus.value,
            }),
          },
        })
      );

      if (organization.id !== order.company.id) {
        API.graphql(
          graphqlOperation(createNotification, {
            input: {
              receiver: order.company.id,
              title: `Order #${order.number} Update`,
              content: `${
                author.name
              } changed order status from "${oldStatus.label.toUpperCase()}" to "${newStatus.label.toUpperCase()}".`,
              icon: `fas fa-user-edit`,
              type: "CONFIRM",
              to: `/orders/${order.id}`,
            },
          })
        );
      }
    },
    [fetchCorrespondence, order, organization]
  );

  useEffect(fetchData, [fetchData]);

  const hasChanges = useCallback(() => {
    if (containers.findIndex((container) => container.isNew) > -1) {
      return true; // has new containers
    }

    if (containers.findIndex((container) => container.hasAmendments) > -1) {
      return true; // has updated containers(s)
    }

    if (uploading) {
      return true; // upload in progress
    }

    return false;
  }, [containers, uploading]);

  useBeforeunload(() => (hasChanges() ? UNSAVED_MSG : false));

  const editable =
    order.status !== "ARCHIVE" &&
    (currentUser.role.key.toUpperCase() === "ADMIN" ||
      currentUser.role.key.toUpperCase() === "ROOT");

  return (
    <>
      <Prompt when={hasChanges()} message={UNSAVED_MSG} />
      <Table
        loading={loading}
        editable={editable}
        mainColumn="number"
        columns={columns}
        actions={editable ? actions : []}
        data={containers}
        onDataChange={onDataChange}
        onAdd={{
          label: "Add New Container (+)",
          handler: onAdd,
        }}
        noDataMsg="No containers added to this order, yet..."
      />
    </>
  );
}

export default EditableOrderContainersTable;
