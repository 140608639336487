import React from "react";

import formStyles from "../FormElement.module.scss";
import { cl } from "../../../helpers";
import { Label } from "../../PageElements";

function Group(props) {
  return (
    <div
      className={cl(
        formStyles.container,
        formStyles[props.type],
        props.branded ? formStyles.branded : null,
        props.disabled ? formStyles.disabled : null,
        props.containerClassName
      )}
    >
      <div className={cl(formStyles.labelContainer, formStyles.filled)}>
        <Label value={props.label} />
      </div>
      <div
        className={cl(
          formStyles.childrenContainer,
          props.hasErrors ? formStyles.error : null
        )}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Group;
