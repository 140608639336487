import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { FormBuilder } from "../../FormElements";
import { updateLedgerEntry } from "../../../graphql/mutations";

function UpdateLedgerEntryForm(props) {
  const [loading, setLoading] = useState(false);
  const data = props.data ?? [];
  const errors = [];

  const fields = {
    company: {
      value: data.company ? data.company.name : "",
      component: "text",
      props: {
        label: "Company",
        readonly: "readonly",
      },
      validation: {
        required: true,
      },
    },
    credit: {
      value: data.credit ?? "",
      component: "text",
      props: {
        type: "currency",
        label: "In =",
        placeholder: "In",
        instruction: "Enter incoming amount",
        min: 0,
      },
      validation: {
        required: true,
        min: 0,
      },
    },
    debit: {
      value: data.debit ?? "",
      component: "text",
      props: {
        type: "currency",
        label: "Out =",
        placeholder: "Out",
        instruction: "Enter outgoing amount",
        min: 0,
      },
      validation: {
        required: true,
        min: 0,
      },
    },
    description: {
      value: data.description ?? "",
      component: "textarea",
      props: {
        type: "text",
        label: "Description",
        instruction: "Describe the entry",
      },
      validation: {
        required: true,
      },
    },
  };

  const submitHandler = async (input) => {
    setLoading(true);
    if (props.onSave && typeof props.onSave === "function") {
      props.onSave(input);
    }
    const {
      data: { updateLedgerEntry: response },
    } = await API.graphql(
      graphqlOperation(updateLedgerEntry, {
        input: {
          id: data.id,
          debit: input.debit,
          credit: input.credit,
          description: input.description,
        },
      })
    );
    setLoading(false);
    if (props.afterSave && typeof props.afterSave === "function") {
      props.afterSave(response);
    }
  };

  return (
    <FormBuilder
      disabled={loading}
      fields={fields}
      submit={{
        handler: submitHandler,
        label: "Save",
      }}
      disclaimer="Please, make sure credit and debit values are correct before you press save."
      generalErrors={errors}
    />
  );
}

export default UpdateLedgerEntryForm;
