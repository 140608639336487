import React, { useContext, useState, useEffect, useCallback } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";

import {
  ModalContext,
  AppContext,
  NotificationContext,
} from "../../../context";
import { SystemUserCompaniesForm } from "../Forms";
import { Label, Loader, Table } from "../../PageElements";
import { getCompanyUsers } from "../../../graphql/queries";
import { CompanyFinderWidget } from "../Widgets";

const roleToGroup = {
  ROOT: "Root",
  ADMIN: "Admin",
  CASHIER: "Cashier",
  COMPANY_ADMIN: "Manager",
  COMPANY_MEMBER: "Employee",
};

function EmployeesTable(props) {
  const notification = useContext(NotificationContext);
  const modal = useContext(ModalContext);
  const { currentUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {
    company: { id: companyId },
  } = props;

  const fetchData = useCallback(async () => {
    if (companyId) {
      setLoading(true);
      try {
        const { data } = await API.graphql(
          graphqlOperation(getCompanyUsers, { companyId })
        );
        setData(data.getCompanyUsers);
        setLoading(false);
      } catch (e) {
        setData([]);
        setLoading(false);
      }
    }
  }, [companyId]);

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, [fetchData]);

  async function addToGroup(username, groupname) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: { username, groupname },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  async function removeUserFromGroup(username, groupname) {
    let apiName = "AdminQueries";
    let path = "/removeUserFromGroup";
    let myInit = {
      body: { username, groupname },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  async function enableUser(username) {
    let apiName = "AdminQueries";
    let path = "/enableUser";
    let myInit = {
      body: { username },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  async function disableUser(username) {
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let myInit = {
      body: { username },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  const handleCompanyAttach = (company, user) => {
    addToGroup(user.id, company.id)
      .then(() => {
        notification.quickConfirm(
          `${user.full_name} is now admin of ${company.name} company.`
        );
        systemUserCompanies(user);
      })
      .catch(() => {
        notification.error(
          `${company.name} company cannot be attached to user ${user.full_name}. Please, try again or contact support.`
        );
        systemUserCompanies(user);
      });

    modal.reset();
    modal.setHasCloseButton(false);
    modal.setCloseOnBackdropClick(false);
    modal.setShouldCloseOnEsc(false);
    modal.setBody(<Loader size="100" />);
    modal.show(`Please wait...`);
  };

  const handleCompanyDetach = (company, user) => {
    modal.reset();
    modal.confirm({
      title: `Detach ${company.name} from ${user.full_name}?`,
      onConfirm: () => {
        removeUserFromGroup(user.id, company.id).then(() => {
          notification.quickConfirm(
            `${user.full_name} is removed from ${company.name} company.`
          );
          systemUserCompanies(user);
        });
        setTimeout(() => {
          modal.reset();
          modal.setHasCloseButton(false);
          modal.setCloseOnBackdropClick(false);
          modal.setShouldCloseOnEsc(false);
          modal.setBody(<Loader size="100" />);
          modal.show(`Please wait...`);
        }, 200);
      },
      onCancel: () => {
        setTimeout(() => {
          systemUserCompanies(user);
        }, 500);
      },
      cancelLabel: "Back",
    });
  };

  const systemUserCompanies = (user) => {
    modal.reset();
    modal.setBody(
      <SystemUserCompaniesForm
        user={user}
        onDetach={(company) => handleCompanyDetach(company, user)}
      />
    );
    modal.addAction({
      label: "Attach Company",
      callback: () => {
        modal.reset();
        modal.setHasCloseButton(false);
        modal.setBody(
          <CompanyFinderWidget
            isSystem={false}
            onSelect={(company) => handleCompanyAttach(company, user)}
          />
        );
      },
    });
    modal.show(`${user.full_name}'s Companies`);
  };

  const columns = [
    { key: "email", label: "Email", type: "email" },
    { key: "full_name", label: "Name", type: "label" },
    {
      key: "enabled",
      label: "Enabled",
      type: "boolean",
    },
    { key: "createdAt", label: "Member Since", type: "date" },
    {
      key: "role",
      label: "Role",
      type: "renderer",
      renderer: (val) => <Label value={roleToGroup[val]} />,
    },
  ];

  const actions = [
    {
      label: "Companies",
      callback: systemUserCompanies,
      shouldRender: (row) =>
        props.company.is_system &&
        currentUser.role.key.toUpperCase() === "ROOT" &&
        row.role.toUpperCase() === "ADMIN" &&
        row.enabled,
    },
    {
      type: "separator",
      shouldRender: (row) =>
        props.company.is_system &&
        currentUser.role.key.toUpperCase() === "ROOT" &&
        row.role.toUpperCase() === "ADMIN" &&
        row.enabled,
    },
    {
      label: "Pass Reset",
      callback: (row) => {
        modal.confirm({
          title: `Send reset password code to ${row.email}?`,
          onConfirm: () => {
            Auth.forgotPassword(row.id)
              .then(() =>
                notification.quickConfirm(
                  `Reset password code successfully sent to ${row.email}`
                )
              )
              .catch((err) => {
                console.warn(err);
                notification.error(
                  `Something went wrong. Please, try again or contact support.`
                );
              });
          },
        });
      },
      shouldRender: (row) => {
        if (currentUser.role.key === "Root" && row.enabled) {
          return true;
        }
        if (currentUser.role.key === "Admin" && row.enabled) {
          return true;
        }

        if (
          currentUser.role.key === "CompanyAdmin" &&
          (row.role === "COMPANY_ADMIN" || row.role === "COMPANY_MEMBER") &&
          row.enabled
        ) {
          return true;
        }

        return false;
      },
    },
    {
      type: "separator",
      shouldRender: (row) => {
        if (row.id === currentUser.sub) {
          return false;
        }

        if (currentUser.role.key === "Root" && row.enabled) {
          return true;
        }

        if (
          !props.company.is_system &&
          currentUser.role.key === "Admin" &&
          row.enabled
        ) {
          return true;
        }

        if (
          currentUser.role.key === "CompanyAdmin" &&
          (row.role === "COMPANY_ADMIN" || row.role === "COMPANY_MEMBER") &&
          row.enabled
        ) {
          return true;
        }

        return false;
      },
    },
    {
      label: "Disable",
      callbackType: "delete",
      getConfirmTitle: (row) => `Disable "${row.full_name}" account?`,
      getSuccessNotification: (row) => (
        <>
          You successfully disabled{" "}
          <Label value={row.full_name} uppercase={false} />
          's account
        </>
      ),
      callback: async (row) => {
        await disableUser(row.id);
        fetchData();
        return { success: true };
      },
      shouldRender: (row) => {
        if (row.id === currentUser.sub) {
          return false;
        }

        if (currentUser.role.key === "Root" && row.enabled) {
          return true;
        }

        if (
          !props.company.is_system &&
          currentUser.role.key === "Admin" &&
          row.enabled
        ) {
          return true;
        }

        if (
          currentUser.role.key === "CompanyAdmin" &&
          (row.role === "COMPANY_ADMIN" || row.role === "COMPANY_MEMBER") &&
          row.enabled
        ) {
          return true;
        }

        return false;
      },
    },
    {
      label: "Enable",
      callback: async (row) => {
        const saveTracker = notification.startProgress("Saving in progress...");
        await enableUser(row.id);
        fetchData();
        notification.updateProgress(
          saveTracker,
          100,
          `Account of "${row.full_name}" is enabled.`
        );
      },
      shouldRender: (row) => {
        if (row.id === currentUser.sub) {
          return false;
        }

        if (currentUser.role.key === "Root" && !row.enabled) {
          return true;
        }

        if (
          !props.company.is_system &&
          currentUser.role.key === "Admin" &&
          !row.enabled
        ) {
          return true;
        }

        if (
          currentUser.role.key === "CompanyAdmin" &&
          (row.role === "COMPANY_ADMIN" || row.role === "COMPANY_MEMBER") &&
          !row.enabled
        ) {
          return true;
        }

        return false;
      },
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      mainColumn="email"
      data={data}
      actions={actions}
    />
  );
}

export default EmployeesTable;
