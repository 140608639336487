import React, { useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import styles from "./Modal.module.scss";
import { cl } from "../../../helpers";
import { Label } from "../../PageElements";
import { Button } from "../../FormElements";
import { useKeydown } from "../../../hooks";

function Modal(props) {
  const shouldCloseOnEsc = props.keyboard ?? true;
  const shouldCloseOnBackdropClick = props.backdrop ?? true;
  const hasCloseButton = props.closable ?? true;
  const { onClose } = props;
  const escPressed = useKeydown("Escape", true, props.show && shouldCloseOnEsc);

  useEffect(() => {
    if (shouldCloseOnEsc && escPressed) {
      onClose();
    }
  }, [shouldCloseOnEsc, escPressed, onClose]);

  if (!props.show) {
    return null;
  }

  return (
    <>
      <div
        className={cl(
          styles.backdrop,
          styles[props.backdropClassName] || props.backdropClassName
        )}
        onClick={() => {
          if (shouldCloseOnBackdropClick) {
            onClose();
          }
        }}
      ></div>
      <div
        className={cl(styles.container, props.containerClassName)}
        role="dialog"
        aria-modal="true"
        tabIndex="-1"
      >
        <div
          className={cl(styles.dialog, props.dialogClassName)}
          role="document"
        >
          <div
            className={cl(
              styles.content,
              styles[props.size],
              props.contentClassName
            )}
          >
            {props.title || hasCloseButton ? (
              <div className={cl(styles.header, props.headerClassName)}>
                <div className={cl(styles.title)}>
                  <Label value={props.title} />
                </div>
                {hasCloseButton ? (
                  <div className={cl(styles.close)}>
                    <Button
                      icon={faTimes}
                      callback={() => onClose()}
                      type="secondaryBranded"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {props.content ? (
              <div
                className={cl(
                  styles.body,
                  styles.hasPadding,
                  props.bodyClassName
                )}
              >
                {props.content}
              </div>
            ) : null}
            <div className={cl(styles.body, props.bodyClassName)}>
              {props.body}
            </div>
            {props.actions ? (
              <div className={cl(styles.actions, props.actionsClassName)}>
                {props.actions.map((action, key) => {
                  var button;
                  switch (action.type) {
                    case "upload":
                      button = (
                        <div
                          key={`modal-actions-${key}`}
                          className={styles.uploadFile}
                        >
                          <span>{action.label}</span>
                          <input
                            type="file"
                            title={action.label}
                            onChange={action.callback}
                            accept=".pdf,.xls,.xlsx,.numbers,.zip,.txt"
                            multiple
                          />
                        </div>
                      );
                      break;
                    default:
                      button = (
                        <Button
                          key={`modal-actions-${key}`}
                          label={action.label}
                          labelIcon={action.labelIcon}
                          icon={action.icon}
                          type={action.buttonType || "primaryBranded"}
                          callback={action.callback}
                        />
                      );
                  }

                  return button;
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
