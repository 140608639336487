import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { listRates } from "../graphql/queries";
import { onCreateRate, onDeleteRate } from "../graphql/subscriptions";
import { Section, Heading, Table } from "../components/PageElements";
import { DEFAULT_DATE_FORMAT } from "../config";
import { deleteRate } from "../graphql/mutations";

function Rates() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);

  const fetchData = async (next = null) => {
    setLoading(true);
    if (!next) {
      setData([]);
    }
    const {
      data: {
        listRates: { items, nextToken },
      },
    } = await API.graphql(
      graphqlOperation(listRates, {
        limit: 25,
        nextToken: next,
      })
    );
    setData((currentItems) => {
      const newItems = [...currentItems];
      newItems.push(...items);
      return newItems;
    });
    setNext(nextToken);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const creationSubscription = API.graphql(
      graphqlOperation(onCreateRate)
    ).subscribe({
      next: () => fetchData(),
    });

    const deletionSubscription = API.graphql(
      graphqlOperation(onDeleteRate)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteRate: deleted },
        },
      }) => {
        setData((currentData) => {
          const newData = [...currentData];
          const deletedIndex = newData.findIndex(
            (rate) => rate.id === deleted.id
          );
          if (deletedIndex > -1) {
            newData.splice(deletedIndex, 1);
          }

          return newData;
        });
      },
    });

    return () => {
      creationSubscription.unsubscribe();
      deletionSubscription.unsubscribe();
    };
  }, []);

  const columns = [
    {
      key: "date",
      label: "Date",
      type: "date",
      format: DEFAULT_DATE_FORMAT,
      isSortable: true,
    },
    {
      key: "usd_rate",
      label: "USD/CNY",
      type: "renderer",
      renderer: (_, row) => `$ 1 = ${row.usd_rate} ¥`,
    },
    {
      key: "euro_rate",
      label: "EUR/CNY",
      type: "renderer",
      renderer: (_, row) => `1 € = ${row.euro_rate} ¥`,
    },
  ];

  const actions = [
    {
      label: "Edit",
      to: ({ date }) => `/rates/edit/${date}`,
    },
    { type: "separator" },
    {
      label: "Delete",
      callbackType: "delete",
      callback: async ({ date }) => {
        await API.graphql(graphqlOperation(deleteRate, { input: { date } }));

        return { success: true };
      },
    },
  ];

  return (
    <Section type="screen">
      <Heading tag="h1" label="Exchange Rates" />
      <Table
        loading={loading}
        hasNext={!!next}
        onNext={{
          label: "Load More Rates...",
          handler: () => fetchData(next),
        }}
        columns={columns}
        mainColumn="date"
        data={data}
        actions={actions}
      />
    </Section>
  );
}

export default Rates;
