import isEmail from "validator/es/lib/isEmail";
import isEmpty from "validator/es/lib/isEmpty";

/**
 * Run validation of a field
 * @param {object} field
 * @param {string|number} value
 */
export const runFieldValidation = (field, value, fields) => {
  const rules = field.validation || {};
  if (rules.required) {
    let isMissing;
    switch (field.component) {
      case "select":
        isMissing = !value;
        break;
      default:
        isMissing =
          typeof value === "string"
            ? isEmpty(value)
            : value === undefined || value === null;
    }
    if (isMissing) {
      return [
        {
          error: `${field.props.label} is required.`,
        },
      ];
    }
  }

  if (rules.email && !isEmail(value)) {
    return [
      {
        error: `Please, enter valid email.`,
      },
    ];
  }

  if (
    field.props.type === "text" &&
    typeof rules.min !== "undefined" &&
    value.trim().length < rules.min
  ) {
    return [
      {
        error: `${field.props.label} is not long enough.`,
      },
    ];
  }

  if (
    field.props.type === "text" &&
    typeof rules.max !== "undefined" &&
    value.trim().length >= rules.max
  ) {
    return [
      {
        error: `${field.props.label} is too long.`,
      },
    ];
  }

  if (
    (field.props.type === "number" || field.props.type === "currency") &&
    typeof rules.min !== "undefined" &&
    value < rules.min
  ) {
    return [
      {
        error: `${field.props.label} must be bigger or equal to ${rules.min}.`,
      },
    ];
  }

  if (
    (field.props.type === "number" || field.props.type === "currency") &&
    typeof rules.max !== "undefined" &&
    value >= rules.max
  ) {
    return [
      {
        error: `${field.props.label} must be smaller or equal to ${rules.max}.`,
      },
    ];
  }

  if (rules.disallowSpecialChars && value !== value.replace(/[^\w\s]/gi, "")) {
    return [
      {
        error: `${field.props.label} cannot contain special characters.`,
      },
    ];
  }

  if (
    rules.password &&
    !new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/).test(
      value
    )
  ) {
    return [
      {
        error: `${field.props.label} must have be at least 8 chars long and contain a special char (!@#$%^&*), a capital letter, a small letter and a number.`,
      },
    ];
  }

  if (rules.same && value !== fields[rules.same].value) {
    return [
      {
        error: `${field.props.label} must have the same value as ${
          fields[rules.same].props.label
        }.`,
      },
    ];
  }

  return [];
};
