import React from "react";

import { SettingsForm } from "../components/UI/Forms";
import { Heading, Section } from "../components/PageElements";

function Settings() {
  return (
    <Section type="screen">
      <Heading tag="h1" label="Personal Settings" />
      <Section type="form">
        <SettingsForm />
      </Section>
    </Section>
  );
}

export default Settings;
