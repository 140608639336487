/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      receiver
      title
      content
      type
      to
      icon
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvite = /* GraphQL */ `
  subscription OnCreateInvite {
    onCreateInvite {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvite = /* GraphQL */ `
  subscription OnUpdateInvite {
    onUpdateInvite {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvite = /* GraphQL */ `
  subscription OnDeleteInvite {
    onDeleteInvite {
      id
      email
      role
      given_name
      family_name
      company_id
      company_name
      note
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      searchableName
      is_system
      managing_company_id
      number
      commission
      address_line_1
      address_line_2
      phone
      fax
      vat_id
      intermediary_bank
      intermediary_bank_bic
      beneficiary_bank
      beneficiary_bank_bic
      beneficiary_bank_address
      beneficiary_bank_number
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLedgerEntry = /* GraphQL */ `
  subscription OnCreateLedgerEntry {
    onCreateLedgerEntry {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateLedgerEntry = /* GraphQL */ `
  subscription OnUpdateLedgerEntry {
    onUpdateLedgerEntry {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteLedgerEntry = /* GraphQL */ `
  subscription OnDeleteLedgerEntry {
    onDeleteLedgerEntry {
      id
      description
      ledgerEntryCompanyId
      debit
      credit
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateRate = /* GraphQL */ `
  subscription OnCreateRate {
    onCreateRate {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRate = /* GraphQL */ `
  subscription OnUpdateRate {
    onUpdateRate {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRate = /* GraphQL */ `
  subscription OnDeleteRate {
    onDeleteRate {
      date
      usd_rate
      euro_rate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCorrespondence = /* GraphQL */ `
  subscription OnCreateCorrespondence {
    onCreateCorrespondence {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCorrespondence = /* GraphQL */ `
  subscription OnUpdateCorrespondence {
    onUpdateCorrespondence {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCorrespondence = /* GraphQL */ `
  subscription OnDeleteCorrespondence {
    onDeleteCorrespondence {
      id
      author_id
      sender {
        id
        name
        company
      }
      group_id
      type
      title
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      group_id
      correspondence_id
      sender {
        id
        name
        company
      }
      text
      template
      vars
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      number
      orderCompanyId
      transport
      items {
        items {
          id
          shop_id
          order_id
          number
          description
          material
          quantity
          unit_name
          unit_price
          total_amount
          quantity_carton
          cartons
          length
          height
          width
          cbm
          containers
          weight
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      type
      createdAt
      updatedAt
      company {
        id
        name
        searchableName
        is_system
        managing_company_id
        number
        commission
        address_line_1
        address_line_2
        phone
        fax
        vat_id
        intermediary_bank
        intermediary_bank_bic
        beneficiary_bank
        beneficiary_bank_bic
        beneficiary_bank_address
        beneficiary_bank_number
        createdAt
        updatedAt
      }
      containers {
        items {
          id
          company_id
          order_id
          number
          provider
          provider_id
          invoice_number
          invoice_date
          shipping_date
          delivery_time
          end_destination
          document_fee
          freight_fee
          telex_dhl_fee
          delivered_at
          createdAt
          updatedAt
          items {
            items {
              id
              company_id
              container_id
              item {
                id
                shop_id
                order_id
                number
                description
                material
                quantity
                unit_name
                unit_price
                total_amount
                quantity_carton
                cartons
                length
                height
                width
                cbm
                containers
                weight
                createdAt
                updatedAt
              }
              cbm_in
              createdAt
              updatedAt
            }
            nextToken
          }
          accessories {
            items {
              id
              company_id
              container_id
              number
              description
              quantity
              unit_name
              unit_price
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateOrderItem = /* GraphQL */ `
  subscription OnCreateOrderItem {
    onCreateOrderItem {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderItem = /* GraphQL */ `
  subscription OnUpdateOrderItem {
    onUpdateOrderItem {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderItem = /* GraphQL */ `
  subscription OnDeleteOrderItem {
    onDeleteOrderItem {
      id
      shop_id
      order_id
      number
      description
      material
      quantity
      unit_name
      unit_price
      total_amount
      quantity_carton
      cartons
      length
      height
      width
      cbm
      containers
      weight
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderContainer = /* GraphQL */ `
  subscription OnCreateOrderContainer {
    onCreateOrderContainer {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOrderContainer = /* GraphQL */ `
  subscription OnUpdateOrderContainer {
    onUpdateOrderContainer {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOrderContainer = /* GraphQL */ `
  subscription OnDeleteOrderContainer {
    onDeleteOrderContainer {
      id
      company_id
      order_id
      number
      provider
      provider_id
      invoice_number
      invoice_date
      shipping_date
      delivery_time
      end_destination
      document_fee
      freight_fee
      telex_dhl_fee
      delivered_at
      createdAt
      updatedAt
      items {
        items {
          id
          company_id
          container_id
          item {
            id
            shop_id
            order_id
            number
            description
            material
            quantity
            unit_name
            unit_price
            total_amount
            quantity_carton
            cartons
            length
            height
            width
            cbm
            containers
            weight
            createdAt
            updatedAt
          }
          cbm_in
          createdAt
          updatedAt
        }
        nextToken
      }
      accessories {
        items {
          id
          company_id
          container_id
          number
          description
          quantity
          unit_name
          unit_price
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateContainerItem = /* GraphQL */ `
  subscription OnCreateContainerItem {
    onCreateContainerItem {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContainerItem = /* GraphQL */ `
  subscription OnUpdateContainerItem {
    onUpdateContainerItem {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContainerItem = /* GraphQL */ `
  subscription OnDeleteContainerItem {
    onDeleteContainerItem {
      id
      company_id
      container_id
      item {
        id
        shop_id
        order_id
        number
        description
        material
        quantity
        unit_name
        unit_price
        total_amount
        quantity_carton
        cartons
        length
        height
        width
        cbm
        containers
        weight
        createdAt
        updatedAt
      }
      cbm_in
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContainerAccessory = /* GraphQL */ `
  subscription OnCreateContainerAccessory {
    onCreateContainerAccessory {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContainerAccessory = /* GraphQL */ `
  subscription OnUpdateContainerAccessory {
    onUpdateContainerAccessory {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContainerAccessory = /* GraphQL */ `
  subscription OnDeleteContainerAccessory {
    onDeleteContainerAccessory {
      id
      company_id
      container_id
      number
      description
      quantity
      unit_name
      unit_price
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContent = /* GraphQL */ `
  subscription OnCreateContent {
    onCreateContent {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContent = /* GraphQL */ `
  subscription OnUpdateContent {
    onUpdateContent {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContent = /* GraphQL */ `
  subscription OnDeleteContent {
    onDeleteContent {
      slug
      type
      title
      body
      createdAt
      updatedAt
    }
  }
`;
