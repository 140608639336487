import React from "react";

import styles from "./Inline.module.scss";
import { cl } from "../../../../helpers";

function Inline(props) {
  return <span className={cl(styles.separator, props.className)}></span>;
}

export default Inline;
