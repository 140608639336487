import React, { useContext } from "react";
import { sumBy, cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";

import {
  container,
  headerRow,
  rowsContainer,
  itemsListing,
  noItemsLabel,
  footerRow,
} from "./ContainerItemsWidget.module.scss";
import { Action, InlineSeparator, Label } from "../../../PageElements";
import { NotificationContext } from "../../../../context";

const calcCBM = (items, cbmKey = `cbm`) =>
  sumBy(items, (item) => item[cbmKey]).toFixed(2);

function ContainerItemsWidget(props) {
  const notification = useContext(NotificationContext);
  const totalCBM = calcCBM(props.unassignedItems);

  /**
   * Add Item To Container
   * @param {*} item
   * @returns
   */
  const containerAdd = (item) => {
    if (props.readOnly) {
      return;
    }
    const volumeLeft = 68 - calcCBM(props.containerItems, `cbm_in`);
    if (!volumeLeft) {
      notification.error(`This container is full. Please, add new container.`);
      return;
    }

    const updatedCntItems = cloneDeep(props.containerItems);
    const cntIdx = updatedCntItems.findIndex(({ id }) => id === item.id);
    if (cntIdx === -1) {
      const cntItem = {
        id: uuid(),
        cbm_in: item.cbm,
        item: item,
      };
      if (cntItem.cbm_in >= volumeLeft) {
        cntItem.cbm_in = volumeLeft;
      }
      updatedCntItems.push(cntItem);
    } else {
      updatedCntItems[cntIdx].cbm_in +=
        item.cbm >= volumeLeft ? volumeLeft : item.cbm;
    }
    props.onUpdate(updatedCntItems);
  };

  /**
   * Remove Item From Container
   * @param {*} item
   */
  const containerRemove = (item) => {
    if (props.readOnly) {
      return;
    }
    const updatedCntItems = cloneDeep(props.containerItems);
    const cntIdx = updatedCntItems.findIndex(({ id }) => id === item.id);
    updatedCntItems.splice(cntIdx, 1);
    props.onUpdate(updatedCntItems);

    if (item.createdAt) {
      props.onItemRemove(item.id);
    }
  };

  return (
    <div className={container}>
      <div className={rowsContainer}>
        <div className={headerRow}>
          <Label value="Container Items" />
        </div>
        <div className={itemsListing}>
          {(!props.containerItems || !props.containerItems.length) && (
            <Label
              containerClassName={noItemsLabel}
              value="No items in container"
            />
          )}
          {props.containerItems.map((item) => (
            <Action
              key={item.id}
              icon="fas fa-arrow-circle-right"
              label={
                <>
                  {item.item.shop_id} / {item.item.number}
                </>
              }
              descriptor={
                <>
                  {item.item.description}
                  <InlineSeparator />
                  CBM: {item.cbm_in.toFixed(2)}m<sup>3</sup>
                  {/* <InlineSeparator />
                  Weight: {(item.item.quantity * item.item.weight).toFixed()}KG */}
                </>
              }
              callback={() => containerRemove(item)}
            />
          ))}
        </div>
      </div>
      <div className={rowsContainer}>
        <div className={headerRow}>
          <Label value="Unassigned Items" />
        </div>
        <div className={itemsListing}>
          {(!props.unassignedItems || !props.unassignedItems.length) && (
            <Label
              containerClassName={noItemsLabel}
              value="No unassigned items"
            />
          )}
          {props.unassignedItems.map((item) => (
            <Action
              key={item.id}
              icon="fas fa-arrow-circle-left"
              label={
                <>
                  {item.shop_id} / {item.number}
                </>
              }
              descriptor={
                <>
                  {item.description}
                  <InlineSeparator />
                  CBM: {item.cbm.toFixed(2)}m<sup>3</sup>
                  {/* <InlineSeparator />
                  Qty: {item.quantity} */}
                  {/* <InlineSeparator />
                  Weight: {(item.quantity * item.weight).toFixed()}KG */}
                </>
              }
              callback={() => containerAdd(item)}
            />
          ))}
        </div>
      </div>
      <div className={footerRow}>
        <Label
          value={
            <>
              {calcCBM(props.containerItems, `cbm_in`)}m<sup>3</sup> of 68m
              <sup>3</sup>
            </>
          }
          uppercase={false}
        />
      </div>
      <div className={footerRow}>
        <Label
          value={
            <>
              Left: {totalCBM}m<sup>3</sup>
            </>
          }
          uppercase={false}
        />
      </div>
    </div>
  );
}

export default ContainerItemsWidget;
