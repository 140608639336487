export const cl = (...classes) =>
  classes
    .filter((cl) => !!cl)
    .map((cl) => cl.trim())
    .filter((cl) => cl !== "")
    .join(" ");

export const accessPath = (obj, path) => {
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  const a = path.split(".");

  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (obj && k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }

  return obj;
};

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();

  return a;
}
