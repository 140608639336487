import moment from "moment";

import styles from "../Table.module.scss";
import { CellGallery } from "../../../UI/Widgets";
import { Label, Link } from "../..";

/**
 * Date Viewer
 * @param {string} val
 * @param {*} _
 * @param {object} column
 * @returns {string}
 */
export const dateViewer = (val, _, column) => {
  const date = column.format
    ? moment(val).format(column.format)
    : moment(val).fromNow();

  return (
    <Label
      value={date}
      icon={`fas ${column.format ? "fa-calendar-alt" : "fa-hourglass-half"}`}
      uppercase={false}
      type="branded"
    />
  );
};

/**
 * Email Viewer
 * @param {string} val
 * @returns {string}
 */
export const emailViewer = (val) => (
  <Link
    to={`mailto:${val}`}
    label={val}
    labelUppercase={false}
    type="branded"
  />
);

/**
 * Phone Viewer
 * @param {string} val
 * @returns {string}
 */
export const phoneViewer = (val) => (
  <Link to={`tel:${val}`} label={val} type="branded" />
);

/**
 * Label Viewer
 * @param {string} val
 * @returns {string}
 */
export const labelViewer = (val) => <Label value={val} type="branded" />;

/**
 * Boolean Viewer
 * @param {bool} val
 * @returns {string}
 */
export const booleanViewer = (val) => (
  <Label value={val ? `Yes` : `No`} type="branded" />
);

/**
 * Price Viewer
 * @param {float} val
 * @returns {string}
 */
export const priceViewer = (val) => (
  <div className={styles.currencyWrapper}>
    <i>¥</i>
    <span className={styles.currencyVal}>{parseInt(val).toFixed(2)}</span>
  </div>
);

export const cellGalleryViewer = (_, row) => (
  <CellGallery
    path={`${row.order_id}/${row.id}/`}
    readOnly={true}
    onUploadStart={() => {}}
    onUploadFinish={() => {}}
  />
);

/**
 * Text Viewer
 * @param {string} val
 * @returns {string}
 */
export const textViewer = (val) => val;

const viewers = {
  boolean: booleanViewer,
  label: labelViewer,
  phone: phoneViewer,
  price: priceViewer,
  email: emailViewer,
  date: dateViewer,
  text: textViewer,
  cellGallery: cellGalleryViewer,
};

export default viewers;
