import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { getContentByType } from "../graphql/queries";
import {
  Section,
  Heading,
  Accordion,
  Loader,
} from "../components/PageElements";

function Help() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const {
      data: {
        getContentByType: { items },
      },
    } = await API.graphql(
      graphqlOperation(getContentByType, {
        type: "HELP",
        sortDirection: "DESC",
      })
    );
    setData(items);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setData([]);
    };
  }, []);

  if (loading) {
    return (
      <Section type="screen">
        <Heading tag="h1" label="Help Center" />
        <Loader size="140" label="Loading help center..." />
      </Section>
    );
  }

  const sections = [];
  data.forEach((item) =>
    sections.push({ id: item.slug, heading: item.title, content: item.body })
  );

  return (
    <Section type="screen">
      <Heading tag="h1" label="Help Center" />
      <Accordion sections={sections} />
    </Section>
  );
}

export default Help;
