import React, { useCallback, useContext, useState } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import { v4 as uuid } from "uuid";

import {
  AppContext,
  ModalContext,
  NotificationContext,
} from "../../../context";
import {
  createOrderItem,
  updateOrderItem,
  deleteOrderItem,
} from "../../../graphql/mutations";
import { Table } from "../../PageElements";
import { ORDER_STATUS_LIST } from "../../../config";

const CONTAINER_CBM = 68;
const UNSAVED_MSG = `You have unsaved item changes. You are going to lose them if you continue.`;

function EditableOrderItemsTable(props) {
  const modal = useContext(ModalContext);
  const { currentUser } = useContext(AppContext);
  const notification = useContext(NotificationContext);
  const { order, items } = props;
  const orderStatus = ORDER_STATUS_LIST.find(
    (status) => status.value === order.status
  );

  const defaultItem = {
    isNew: true,
    hasAmendments: false,
    id: uuid(),
    number: `${order.number}-${items.length + 1}`,
    shop_id: "",
    description: "",
    material: "",
    quantity: "1",
    unit_name: "PCS",
    unit_price: "1",
    quantity_carton: "1",
    length: 1,
    height: 1,
    width: 1,
    weight: 0,
    cbm: 0,
  };

  const [data, setData] = useState(items.length ? items : [defaultItem]);
  const [uploading, setUploading] = useState(0);

  const calcAmount = (row) => (row.unit_price * row.quantity).toFixed(2);
  const calcCartons = (row) => Math.ceil(row.quantity / row.quantity_carton);
  // const calcCBM = (row) =>
  //   (calcCartons(row) * row.length * row.height * row.width).toFixed(2);
  const calcContainers = (row) => (row.cbm / CONTAINER_CBM).toFixed(3);

  const columns = [
    { key: "shop_id", label: "Order NO.", placeholder: "XYZZYX", type: "text" },
    {
      key: "number",
      label: "Item NO.",
      placeholder: "XYZZYX-123",
      type: "text",
    },
    {
      key: "gallery",
      label: "Photo",
      type: "cellGallery",
      onUploadStart: () => setUploading((uploading) => uploading + 1),
      onUploadFinish: () => setUploading((uploading) => uploading - 1),
    },
    {
      key: "description",
      label: "Description",
      type: "text",
      placeholder: "Item Description",
    },
    {
      key: "material",
      label: "Material",
      type: "text",
      placeholder: "Material",
    },
    { key: "quantity", label: "Quantity", type: "number" },
    {
      key: "unit_name",
      label: "Unit",
      type: "select",
      options: [
        { key: "0", label: "Select Type" },
        { key: "PAIRS", label: "Pairs" },
        { key: "SETS", label: "Sets" },
        { key: "PCS", label: "Pcs" },
      ],
    },
    { key: "unit_price", label: "Price", type: "price" },
    {
      key: "amount",
      label: "Amount",
      type: "price",
      readOnly: true,
      formula: (_, row) => calcAmount(row),
    },
    { key: "quantity_carton", label: "QTY/Carton", type: "number" },
    {
      key: "cnts",
      label: "Cartons",
      type: "number",
      readOnly: true,
      formula: (_, row) => calcCartons(row),
    },
    { key: "weight", label: "Weight (KG)", type: "number" },
    { key: "length", label: "Length", type: "number" },
    { key: "width", label: "Width", type: "number" },
    { key: "height", label: "Height", type: "number" },
    {
      key: "cbm",
      label: "CBM",
      type: "number",
      // formula: (_, row) => calcCBM(row),
    },
    // {
    //   key: "containers",
    //   label: "Containers",
    //   type: "number",
    //   readOnly: true,
    //   formula: (_, row) => calcContainers(row),
    // },
  ];

  const saveHandler = async (input) => {
    const operation = input.isNew ? createOrderItem : updateOrderItem;
    const { data } = await API.graphql(
      graphqlOperation(operation, {
        input: {
          id: input.id,
          order_id: props.order.id,
          shop_id: input.shop_id,
          number: input.number,
          description: input.description,
          material: input.material,
          quantity: input.quantity,
          unit_name: input.unit_name,
          unit_price: input.unit_price,
          total_amount: calcAmount(input),
          quantity_carton: input.quantity_carton,
          cartons: calcCartons(input),
          weight: input.weight,
          length: input.length,
          height: input.height,
          width: input.width,
          cbm: input.cbm,
          containers: calcContainers(input),
        },
      })
    );
    const key = input.isNew ? "createOrderItem" : "updateOrderItem";
    const response = data[key];
    setData((oldData) => {
      const newData = [...oldData];
      const rowToEdit = newData.findIndex((row) => row.id === input.id);
      if (rowToEdit > -1) {
        newData[rowToEdit] = response;
      }
      return newData;
    });
  };

  const actions = [
    {
      renderer: (row) => {
        return row.isNew ? "Save" : "Update";
      },
      callback: async (input) => {
        const saveTracker = notification.startProgress(
          "Saving item in progress..."
        );
        try {
          await saveHandler(input);
          notification.updateProgress(
            saveTracker,
            100,
            input.isNew ? `Order item created.` : `Order item updated.`
          );
        } catch ({ errors }) {
          notification.cancelProgress(saveTracker);
          errors.forEach((err) =>
            notification.error(`Error saving item: ${err.message}`)
          );
        }
      },
    },
    {
      type: "separator",
    },
    {
      renderer: (row) => {
        return row.isNew ? "Remove" : "Delete";
      },
      callback: ({ isNew, id, number }) => {
        modal.confirm({
          title: `Delete item "${number}"?`,
          onConfirm: () => {
            if (!isNew) {
              API.graphql(
                graphqlOperation(deleteOrderItem, { input: { id } })
              ).then(() => {
                notification.quickConfirm(`Order item deleted.`);
              });
            }

            const path = `${props.order.id}/${id}/`;
            Storage.list(path).then(async (files) => {
              if (files.length) {
                await Promise.all(
                  files.map(async (file) => {
                    await Storage.remove(file.key);
                  })
                );
                notification.quickConfirm("Item Pictures Deleted!");
              }
            });

            setData((oldData) => {
              const newData = [...oldData];
              const rowToDelete = newData.findIndex((row) => row.id === id);
              if (rowToDelete > -1) {
                newData.splice(rowToDelete, 1);
              }

              return newData;
            });
            notification.quickConfirm("Item Deleted!");
          },
        });
      },
    },
  ];

  const summaryActions = [
    {
      label: "Save All",
      callback: (_rows) => {
        const saveTracker = notification.startProgress(
          `Saving ${_rows.length} items...`
        );
        try {
          let counter = 1;
          _rows.forEach(async (input) => {
            await saveHandler(input);
            counter++;
            const progress = Number((counter / _rows.length) * 100).toFixed();
            notification.updateProgress(
              saveTracker,
              progress,
              `All Items Saved`
            );
          });
        } catch ({ errors }) {
          notification.cancelProgress(saveTracker);
          errors.forEach((err) =>
            notification.error(`Error saving item: ${err.message}`)
          );
        }
      },
    },
  ];

  const hasChanges = useCallback(() => {
    if (data.findIndex((item) => item.isNew) > -1) {
      return true; // has new items
    }

    if (data.findIndex((item) => item.hasAmendments) > -1) {
      return true; // has updated item(s)
    }

    if (uploading) {
      return true; // upload in progress
    }

    return false;
  }, [data, uploading]);

  const addHandler = () => {
    setData((oldData) => {
      const newData = [...oldData];
      const newItem = { ...defaultItem };
      if (oldData.length > 0 && oldData[oldData.length - 1]) {
        newItem.shop_id = oldData[oldData.length - 1].shop_id;
      }
      newData.push(newItem);

      return newData;
    });
  };

  const dataChangeHandler = (id, field, value) => {
    setData((oldData) => {
      const newData = [...oldData];
      const rowToEdit = newData.find((row) => row.id === id);
      if (rowToEdit) {
        rowToEdit.hasAmendments = true;
        rowToEdit[field] = value;
      }

      return newData;
    });
  };

  const summary = [
    {
      key: "quantity",
      formula: (data, { key }) => {
        let sum = 0;
        data.forEach((row) => {
          sum += Number(row[key]);
        });
        return sum.toFixed(2);
      },
    },
    {
      key: "cnts",
      formula: (data) => {
        let sum = 0;
        data.forEach((row) => {
          sum += calcCartons(row);
        });
        return sum.toFixed(2);
      },
    },
    {
      key: "weight",
      formula: (data, { key }) => {
        let sum = 0;
        data.forEach((row) => {
          sum += Number(row[key]);
        });
        return sum.toFixed(2);
      },
    },
    {
      key: "cbm",
      formula: (data, { key }) => {
        let sum = 0;
        data.forEach((row) => {
          sum += Number(row[key]);
        });
        return sum.toFixed(2);
      },
    },
    {
      key: "amount",
      formula: (data, { key }) => {
        let sum = 0;
        data.forEach((row) => {
          sum += Number(calcAmount(row));
        });
        return sum.toFixed(2);
      },
    },
  ];

  useBeforeunload(() => (hasChanges() ? UNSAVED_MSG : false));

  const editable =
    order.status !== "ARCHIVE" &&
    ((currentUser.role.key === "CompanyAdmin" && orderStatus.key < 3) ||
      (currentUser.role.key === "CompanyMember" && orderStatus.key < 3) ||
      currentUser.role.key.toUpperCase() === "ADMIN" ||
      currentUser.role.key.toUpperCase() === "ROOT");

  return (
    <>
      <Prompt when={hasChanges()} message={UNSAVED_MSG} />
      <Table
        editable={editable}
        mainColumn="shop_id"
        columns={columns}
        actions={!editable ? [] : actions}
        data={data}
        summary={summary}
        summaryActions={summaryActions}
        onDataChange={dataChangeHandler}
        onAdd={{
          label: "Add New Item (+)",
          handler: addHandler,
        }}
      />
    </>
  );
}

export default EditableOrderItemsTable;
