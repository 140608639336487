import React from "react";

import { Heading, Section, Paragraph, Link } from "../components/PageElements";

function PageNotFound() {
  return (
    <Section type="screen">
      <Section type="center">
        <Heading tag="h1" label="Whoops!" />
        <Paragraph>
          The page you are looking for was moved, removed, renamed or never
          existed.
        </Paragraph>
        <Link
          to="/"
          buttonType="primaryBranded"
          label="Go to Homepage"
          labelIcon="fas fa-home"
        />
      </Section>
    </Section>
  );
}

export default PageNotFound;
