import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { FormBuilder } from "../../FormElements";

function ForgotPasswordForm(props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const fields = {
    email: {
      value: "",
      component: "text",
      props: {
        type: "email",
        label: "Your Email",
        instruction: "Enter Email",
      },
      validation: {
        required: true,
        email: true,
      },
    },
  };

  const submitHandler = async (data) => {
    setErrors([]);
    setLoading(true);
    try {
      await Auth.forgotPassword(data.email);
      props.afterSubmit(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors([e.message]);
    }
  };

  return (
    <FormBuilder
      fields={fields}
      disabled={loading}
      submit={{
        handler: submitHandler,
        label: "Send Me Reset Code",
      }}
      generalErrors={errors}
    />
  );
}

export default ForgotPasswordForm;
