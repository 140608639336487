import React from "react";
import { Link } from "react-router-dom";

import styles from "./Action.module.scss";
import { cl } from "../../../helpers";
import { Label, Icon } from "../";
import { ConditionalWrapper } from "../../Utils";

function Action(props) {
  return (
    <div className={cl(styles.container, props.containerClassName)}>
      <ConditionalWrapper
        condition={!!props.to}
        wrapper={(children) => (
          <Link
            to={props.to}
            className={cl(styles.wrapper, props.wrapperClassName)}
          >
            {children}
          </Link>
        )}
        alternative={(children) => (
          <div
            onClick={props.callback}
            className={cl(styles.wrapper, props.wrapperClassName)}
          >
            {children}
          </div>
        )}
      >
        {props.icon || props.image ? (
          <div className={styles.media}>
            {props.icon ? <Icon icon={props.icon} /> : null}
            {props.image ? (
              <img
                src={props.image}
                alt={props.label}
                className={styles[props.imageType]}
              />
            ) : null}
          </div>
        ) : null}
        <div className={styles.content}>
          {props.label ? (
            <Label
              value={props.label}
              {...props.labelProps}
              containerClassName={styles.label}
            />
          ) : null}
          {props.descriptor ? (
            <span className={styles.descriptor}>{props.descriptor}</span>
          ) : null}
        </div>
      </ConditionalWrapper>
    </div>
  );
}

export default Action;
