import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import PropTypes from "prop-types";

import styles from "./Datepicker.module.scss";
import formStyles from "../../FormElement.module.scss";
import { cl } from "../../../../helpers";
import { Label } from "../../../PageElements";
import "react-datepicker/dist/react-datepicker.css";

Datepicker.propTypes = {
  nested: PropTypes.bool.isRequired,
};

Datepicker.defaultProps = {
  nested: true,
};

function Datepicker(props) {
  const [focused, setFocused] = useState(false);
  const format = props.showTime ? "dd.MM.yy hh:mm" : "dd.MM.yy";
  const required = props.required ? "*" : "";
  const filled =
    props.value !== null && props.value !== undefined && props.value !== ""
      ? true
      : false;
  const placeholder = props.placeholder ?? props.label;
  const instruction = props.instruction ?? props.label;

  let labelValue = `${props.label}${required}`;

  if (focused) {
    labelValue = instruction;
  } else {
    if (!filled) {
      if (typeof placeholder === "object") {
        labelValue = placeholder;
      } else {
        labelValue = `${required} ${placeholder}`;
      }
    }
  }

  return (
    <div
      className={cl(
        formStyles.wrapper,
        props.nested ? formStyles.nested : null,
        props.wrapperClassName
      )}
    >
      <div
        className={cl(
          formStyles.container,
          formStyles[props.type],
          props.branded ? formStyles.branded : null,
          props.disabled ? formStyles.disabled : null,
          props.containerClassName
        )}
      >
        <Label
          value={labelValue}
          containerClassName={cl(
            formStyles.labelContainer,
            props.value ? formStyles.filled : null,
            focused ? formStyles.focused : null
          )}
        />
        <ReactDatePicker
          wrapperClassName={cl(styles.wrapper)}
          className={cl(formStyles.input, props.className)}
          calendarClassName={cl(styles.calendar)}
          disabled={props.disabled}
          showTimeSelect={props.showTime}
          dateFormat={format}
          selected={props.value}
          onChange={props.onChange}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
        />
      </div>
    </div>
  );
}

export default Datepicker;
